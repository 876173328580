import React, { useState, useEffect, useContext } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CustomCheckBox from '../../../../common/checkbox/CustomCheckBox';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography, Grid, Popover } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { useHistory } from 'react-router-dom';
import rsToCr from '../../../../../utils/rsToCr';
import unit from '../../../../../utils/unitConfig';

// import rooftopRunoff from '../../../../../assets/rooftopRunoff.svg';
// import surfaceRunoff from '../../../../../assets/surfaceRunoff.svg';
// import solarwaterheater from '../../../../../assets/solarwaterheater.svg';
import solarPV from '../../../../../assets/solarPV.svg';
import smartWaterMeter from '../../../../../assets/smartwatermeter.svg';
// import medicalWaste from '../../../../../assets/medical.png';
// import eWastehandling from '../../../../../assets/eWastehandling.svg';
import EfficientFixtures from '../../../../../assets/efficientFixtures.svg';
import dualflush from '../../../../../assets/dualflush.svg';
// import CentralisedRO from '../../../../../assets/CentralisedRO.svg';
// import blackwaterReuse from '../../../../../assets/blackwaterReuse.svg';
import biogasPlant from '../../../../../assets/icons/waste/biogas.svg';
import greywaterReuse from '../../../../../assets/greywater.svg';
// import incinaretor from '../../../../../assets/icons/waste/snitary.svg';
// import lightManagementSystem from '../../../../../assets/lightManagementSystem.svg';
// import composter from '../../../../../assets/composter.svg';
import toxicNew from '../../../../../assets/icons/waste/segregation.svg';
import EnergyCustom from '../../../../../assets/icons/energy/custom-icon.png';
import WaterCustom from '../../../../../assets/icons/water/custom-icon.png';
import WasteCustom from '../../../../../assets/icons/waste/custom-icon.png';
import MaterialsCustom from '../../../../../assets/interventions/materials/custom.svg';
import ExteriorPaints from '../../../../../assets/interventions/materials/exterior-paints.svg';
// import concrete from '../../../../../assets/interventions/materials/concrete.svg';
// import structure from '../../../../../assets/interventions/materials/structure.svg';
// import balconyandutility from '../../../../../assets/interventions/materials/balconyandutility.svg';
// import livinganddining from '../../../../../assets/interventions/materials/livinganddining.svg';
// import bedroom from '../../../../../assets/interventions/materials/bedroom.svg';
// import bathroom from '../../../../../assets/interventions/materials/bathroom.svg';
// import kitchen from '../../../../../assets/interventions/materials/kitchen.svg';
import hwmvillicon from '../../../../../assets/interventions/HWM Villa Icon.svg';
import nstpIcon from '../../../../../assets/interventions/NSTPicon.svg';

import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import axios from '../../../../../config/Axios';
import numberWithCommas from '../../../../../utils/numberToComma';
// import { useParams } from 'react-router';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TooltipData, ParentPage, ChildPage } from '../../../../../staticData/TooltipStaticData';
import CustomTooltipTitle from '../../../../common/tooltip/customTooltipTitle';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';

const interventionToIconMap = {
  efficientFixtures: EfficientFixtures,
  swm: smartWaterMeter,
  wwm: biogasPlant,
  csp: toxicNew,
  exteriorPaintsSunny: ExteriorPaints,
  exteriorPaintsNonSunny: ExteriorPaints,
  hwmVilla: hwmvillicon,
  hwmApartment: hwmvillicon,
  hwmClubhouse: hwmvillicon,
  hwmSwimmingPool: hwmvillicon,
  hwmPenthouse: hwmvillicon,
  spvApartment: solarPV,
  spvClubhouse: solarPV,
  spvVilla: solarPV,
  spvPenthouse: solarPV,
  efficientFixturesHealthFaucet: EfficientFixtures,
  efficientFixturesFaucet: EfficientFixtures,
  efficientFixturesKitchenSink: EfficientFixtures,
  efficientFixturesShower: EfficientFixtures,
  efficientFixturesDualFlush: dualflush,
  twr: greywaterReuse,
  nstp: nstpIcon,
  Energy: EnergyCustom,
  Water: WaterCustom,
  Waste: WasteCustom,
  Materials: MaterialsCustom,
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    background: 'rgba(0, 0, 0, 0.03)',
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledTableSubRow = withStyles((theme) => ({
  root: {
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledRowCell = withStyles((theme) => ({
  body: {
    paddingTop: 4,
    paddingBottom: 4,

    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '19px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  linkfont: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#00C58A',
  },
  decoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  decoration2: {
    cursor: 'pointer',
  },
  grid: {
    marginTop: '3%',
  },
  typography: {
    padding: theme.spacing(2),
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function CategoryRow(props) {
  const { row, StyledTableCell } = props;
  const { getValue, getUnit } = useAppMetafireUtilities();

  const category = props.category;
  const state = props.state;
  const [spinner, setSpinner] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [custominterventions, setCustomintervention] = useState([]);
  // const handleClick = (event) => setAnchorEl(event.currentTarget);
  let history = useHistory();
  let iid = props.projectID;

  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // const handleState = () => setToggle(!toggle);

  const handleRefresh = async (id, state) => {
    setSpinner(true);
    const body = {
      enabled: state,
    };
    axios
      .put(`/ProjectInterventionMapping/${id}`, body)
      .then((res) => {
        if (res) {
          props.handleRefresh();
          setSpinner(false);
        }
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`/custom-intervention?project=${iid}`)
      .then((respo) => {
        setCustomintervention(respo.data?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const findTooltipdataforcustomIntervention = (name) => {
    const gettooltipdata = custominterventions && custominterventions.find((ele) => ele.name === name);
    const tooltipdata = gettooltipdata?.description === '' ? 'NA' : gettooltipdata?.description;
    return tooltipdata;
  };

  // if (props.data === 'Energy') sortedData = row?.interventions?.length !== 0 && row.interventions.sort((a, b) => (a.name === 'Solar PV' ? -1 : 0));
  // if (props.data === 'Water') {
  //   sortedData =
  //     row?.interventions?.length !== 0 &&
  //     row.interventions
  //       .sort((a, b) =>
  //         a.name === 'Centralized RO' ||
  //         (a.name === 'Grey Water Re-use' && b.name === 'Black Water Re-use') ||
  //         (a.name === 'Rooftop RWH' && b.name === 'Surface RWH')
  //           ? -1
  //           : b.name === 'Dual Flush'
  //           ? -1
  //           : 0
  //       )
  //       .sort((a, b) => (b.name === 'Smart Water Meters' ? -1 : 0));
  // }
  // if (props.data === 'Waste')
  //   sortedData = row?.interventions?.length !== 0 && row.interventions.sort((a, b) => (a.name === 'Collection & Segregation Point' ? -1 : 0));

  // if (props.data === 'Materials') sortedData = row?.interventions?.length !== 0 && row.interventions;

  const energyCustomIcon = interventionToIconMap.Energy;
  const waterCustomIcon = interventionToIconMap.Water;
  const wasteCustomIcon = interventionToIconMap.Waste;
  const materialCustomIcon = interventionToIconMap.Materials;

  const findInterventionFromStaticData = (data, interventionName) => {
    let FinalResult;
    let result =
      data &&
      data.find((d) => d.parentPage === ParentPage.INTERVENTIONS && d.childPage === ChildPage.INTERVENTIONS_SUMMARY && d.componentName === interventionName);
    if (result && result) {
      FinalResult = result.content;
    } else {
      FinalResult = null;
    }
    return FinalResult;
  };

  const sortedDataWithActiveInterventions = row?.interventions?.filter((data) => getValue(data?.quantities?.activated) === true) || [];
  const financeSavings = row?.quantities?.finance;
  const resourceSavings = row?.quantities?.resource;

  const capex = financeSavings?.capex;
  const capexConverted = financeSavings?.capexConverted;
  const totalCapexConverted = financeSavings?.totalCapexConverted;
  const totalCapex = financeSavings?.totalCapex;
  const opexSavings = financeSavings?.opex;
  const resourceCategory = resourceSavings?.category;

  return (
    <>
      <Backdrop className={classes.backdrop} open={spinner}>
        <CircularProgress style={{ color: '#28DEAA' }} />
      </Backdrop>
      {sortedDataWithActiveInterventions?.length !== 0 && (
        <StyledTableRow
          // onClick={handleState}
          style={{ height: '8vh' }}
        >
          <StyledTableCell component="th" scope="row">
            {row.name.charAt(0).toUpperCase() + row.name.slice(1)}
          </StyledTableCell>

          {/* Capex Increase */}
          {!state ? (
            <StyledTableCell>{numberWithCommas(getValue(capexConverted)) + ' ' + getUnit(capexConverted)}</StyledTableCell>
          ) : (
            <StyledTableCell>{numberWithCommas(rsToCr('Rs', getValue(capex))) + ' ' + unit('cr')}</StyledTableCell>
          )}

          {/* Total Capex */}
          {!state ? (
            <StyledTableCell>{numberWithCommas(getValue(totalCapexConverted)) + ' ' + getUnit(totalCapexConverted)}</StyledTableCell>
          ) : (
            <StyledTableCell>{numberWithCommas(rsToCr('Rs', getValue(totalCapex))) + ' ' + unit('cr')}</StyledTableCell>
          )}

          {/* Opex Savings */}
          <StyledTableCell>{numberWithCommas(rsToCr('Rs', getValue(opexSavings))) + ' ' + unit('cr')}</StyledTableCell>

          {/* Energy Savings */}
          <StyledTableCell>{numberWithCommas(getValue(resourceCategory)) + ' ' + unit(getUnit(resourceCategory))}</StyledTableCell>
        </StyledTableRow>
      )}

      {sortedDataWithActiveInterventions &&
        sortedDataWithActiveInterventions?.map((row, index) => {
          return (
            <StyledTableSubRow key={index}>
              <StyledRowCell component="th" scope="row">
                <>
                  <Grid container xs>
                    <Grid xs={1} style={{ paddingTop: 5 }}>
                      <CustomCheckBox
                        color="rgba(0, 0, 0, 0.38)"
                        checkState={getValue(row?.quantities?.enabled)}
                        uncheckicon={<RadioButtonUncheckedIcon style={{ fontSize: 20 }} />}
                        checkicon={<CheckCircleIcon style={{ fontSize: 20 }} />}
                        handleChange={() => handleRefresh(row.interventionProjectMappingId, !getValue(row?.quantities?.enabled))}
                        disabled={false}
                      />
                    </Grid>
                    <Grid xs={2}>
                      {props.name !== 'Custom Interventions' ? (
                        <img style={{ borderRadius: '10px' }} src={interventionToIconMap[row.feature]} alt="..." />
                      ) : category === 'Energy' ? (
                        <img style={{ borderRadius: '10px' }} src={energyCustomIcon && energyCustomIcon} alt="..." />
                      ) : category === 'Water' ? (
                        <img style={{ borderRadius: '10px' }} src={waterCustomIcon && waterCustomIcon} alt="..." />
                      ) : category === 'Waste' ? (
                        category === 'Waste' && <img style={{ borderRadius: '10px' }} src={wasteCustomIcon && wasteCustomIcon} alt="..." />
                      ) : (
                        category === 'Materials' && <img style={{ borderRadius: '10px' }} src={materialCustomIcon && materialCustomIcon} alt="..." />
                      )}
                    </Grid>
                    <Grid
                      xs={9}
                      className={classes.grid}
                      onClick={() => {
                        props.handleOpenBackdrop(true);
                        props.handleEditCustomIntervention(true);
                        props.handleEditCustominterventionID(row.interventionProjectMappingId);
                        history.push(`/projects/${iid}/interventions/${row.interventionProjectMappingId}`);
                      }}
                      style={{ paddingTop: '3px', paddingLeft: '2%', cursor: 'pointer' }}
                      item
                      container
                    >
                      <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>{row.name.charAt(0).toUpperCase() + row.name.slice(1)}</span>
                      <CustomizedTooltips
                        marginTop="1%"
                        icon="info"
                        placement="right"
                        title={
                          findInterventionFromStaticData(TooltipData, row.name.charAt(0).toUpperCase() + row.name.slice(1)) !== null ? (
                            <CustomTooltipTitle data={findInterventionFromStaticData(TooltipData, row.name.charAt(0).toUpperCase() + row.name.slice(1))} />
                          ) : (
                            <Typography variant="h6">{findTooltipdataforcustomIntervention(row.name)}</Typography>
                          )
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              </StyledRowCell>

              {/* Capex row wise */}
              {!state ? (
                <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                  {numberWithCommas(getValue(row?.quantities?.finance?.capexConverted)) + ' ' + getUnit(capexConverted)}
                </StyledRowCell>
              ) : (
                <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                  {rsToCr('Rs', getValue(row?.quantities?.finance?.capex)) + ' ' + unit('cr')}
                </StyledRowCell>
              )}

              {/* Total Capex row wise */}
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                {!state
                  ? `${getValue(row?.quantities?.finance?.totalCapexConverted)} ${getUnit(row?.quantities?.finance?.totalCapexConverted)}`
                  : `${rsToCr('Rs', getValue(row?.quantities?.finance?.totalCapex))} ${unit('cr')}`}
              </StyledRowCell>

              {/* Opex Savings wise */}
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                {`${rsToCr('Rs', getValue(row?.quantities?.finance?.opex))} 
              ${unit('cr')}`}
              </StyledRowCell>

              {/* Energy Savings wise */}
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                {`${numberWithCommas(getValue(row?.quantities?.resource?.category))} 
                ${unit(getUnit(row?.quantities?.resource?.category))}`}
              </StyledRowCell>
            </StyledTableSubRow>
          );
        })}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>{row?.name}</Typography>
      </Popover>
    </>
  );
}

{
  /* <StyledTableCell align="right">
          {!toggle && <ExpandMoreIcon onClick={handleState} />}
          {toggle && <ExpandLessIcon onClick={handleState} />}
        </StyledTableCell> */
}
// style={Math.sign(row && row?.quantities?.finance?.totalCapex?.value) === -1 ? { color: '#26E0A3' } : { color: 'black' }}
