import wasteIcon from '../../../assets/GOALS/goalcategoryimages/WasteGoalIcon.svg';
import waterIcon from '../../../assets/Fill 688.svg';
import powerIcon from '../../../assets/Fill 1452.svg';
import wasteicon from '../../../assets/interventions/Group 1365.svg';
import powerFilled from '../../../assets/GOALS/goalcategoryimages/energyFilledGoalIcon.svg';
import waterFilled from '../../../assets/GOALS/goalcategoryimages/waterFIlledGoalIcon.svg';
import wasteFilled from '../../../assets/GOALS/goalcategoryimages/wasteFilledGoalIcon.svg';
import materialNonFilled from '../../../assets/GOALS/goalcategoryimages/materialNonFilled.svg';

export const GOALS_CATEGORIES = {
  goals_energy: {
    background: '#FFF4D5',
    border: '#F28D14',
    bar: '#F28D14',
    icon: powerFilled,
    targets: {
      title: 'Energy Targets',
      border: '#F6AA4F',
      bar: '#FFCE93',
      icon: powerIcon,
    },
  },
  goals_water: {
    background: '#C9F5FF',
    border: '#3BC7EE',
    bar: '#3BC7EE',
    icon: waterFilled,
    targets: {
      title: 'Water Targets',
      border: '#3BC7EE',
      bar: '#74DEFB',
      icon: waterIcon,
    },
  },
  goals_waste: {
    background: '#EEE1D7',
    border: '#BC8A5F',
    bar: '#BC8A5F',
    icon: wasteFilled,
    targets: {
      title: 'Waste Targets',
      border: '#BC8A5F',
      bar: '#F0D4BF',
      icon: wasteicon,
    },
  },
  goals_embodiedCarbon: {
    background: '#EDD5FA',
    border: '#BC8A5F',
    bar: '#916AAC',
    icon: wasteIcon,
    targets: {
      title: 'Material Targets',
      border: '#BC8A5F',
      bar: '#EDCDFF',
      icon: materialNonFilled,
    },
  },
};
