import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography, withStyles } from '@material-ui/core';

const TreatedWaterReuseSelection = ({ fixturesData, appendChanged, myArray, disable }) => {
  const GreenCheckbox = withStyles({
    root: {
      color: disable && disable === true ? 'grey' : '#2EE1AC',
      '&$checked': {
        color: disable && disable === true ? 'grey' : '#2EE1AC',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const flushingPIP = fixturesData && fixturesData?.find((data) => data?.name === 'project__pi_trFlushing');
  const landscapePIP = fixturesData && fixturesData?.find((data) => data?.name === 'project__pi_trLandScape');
  const carWashPIP = fixturesData && fixturesData?.find((data) => data?.name === 'project__pi_trCarWash');
  const commonAreaCleaningPIP = fixturesData && fixturesData?.find((data) => data?.name === 'project__pi_trCommonAreaCleaning');
  const domesticConsumptionPIP = fixturesData && fixturesData?.find((data) => data?.name === 'project__pi_trDomestic');
  const poolPIP = fixturesData && fixturesData?.find((data) => data?.name === 'project__pi_trPool');

  return (
    <div>
      <FormControl component="fieldset">
        <Typography variant="h5" style={{ color: disable ? 'rgba(0, 0, 0, 0.38)' : 'black' }}>
          Treated Water Reused for
        </Typography>
        <Grid container xs={12}>
          <Grid xs={6}>
            <FormGroup>
              <Typography variant="h6" style={{ marginBottom: '2%', color: disable ? 'rgba(0, 0, 0, 0.38)' : 'black' }}>
                Non-Potable Use
              </Typography>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    disabled={disable}
                    checked={myArray && myArray.find((d) => d?.PIPScheme === flushingPIP?._id)?.value === true ? true : false}
                    name="flushing"
                    onChange={(e) => appendChanged(flushingPIP?._id, e.target.checked)}
                  />
                }
                label="Flushing"
              />
              <FormControlLabel
                control={
                  <GreenCheckbox
                    disabled={disable}
                    checked={myArray && myArray.find((d) => d?.PIPScheme === landscapePIP?._id)?.value === true ? true : false}
                    onChange={(e) => appendChanged(landscapePIP?._id, e.target.checked)}
                    name="landscape"
                  />
                }
                label="Landscape"
              />
              <FormControlLabel
                control={
                  <GreenCheckbox
                    disabled={disable}
                    checked={myArray && myArray.find((d) => d?.PIPScheme === carWashPIP?._id)?.value === true ? true : false}
                    onChange={(e) => appendChanged(carWashPIP?._id, e.target.checked)}
                    name="Car Wash"
                  />
                }
                label="Car Wash"
              />
              <FormControlLabel
                control={
                  <GreenCheckbox
                    disabled={disable}
                    checked={myArray && myArray.find((d) => d?.PIPScheme === commonAreaCleaningPIP?._id)?.value === true ? true : false}
                    onChange={(e) => appendChanged(commonAreaCleaningPIP?._id, e.target.checked)}
                    name="commonAreaCleaning"
                  />
                }
                label="Common Area Cleaning"
              />
            </FormGroup>
          </Grid>
          <Grid xs={6}>
            <FormGroup>
              <Typography variant="h6" style={{ marginBottom: '2%', color: disable ? 'rgba(0, 0, 0, 0.38)' : 'black' }}>
                Potable Use
              </Typography>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    disabled={disable}
                    checked={myArray && myArray.find((d) => d?.PIPScheme === domesticConsumptionPIP?._id)?.value === true ? true : false}
                    onChange={(e) => appendChanged(domesticConsumptionPIP?._id, e.target.checked)}
                    name="domesticConsumption"
                  />
                }
                label="Domestic Consumption"
              />

              <FormControlLabel
                control={
                  <GreenCheckbox
                    disabled={disable}
                    checked={myArray && myArray.find((d) => d?.PIPScheme === poolPIP?._id)?.value === true ? true : false}
                    onChange={(e) => appendChanged(poolPIP?._id, e.target.checked)}
                    name="pool"
                  />
                }
                label="Pool"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );
};

export default TreatedWaterReuseSelection;
