import React from 'react';
import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
}));

const CustomDropdown = ({ value, onChange, options, disabled, placeholder }) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        style={{
          border: '1px solid #2E2E2E',
          height: 43,
        }}
        className={classes.select}
        value={value}
        onChange={onChange}
        fullWidth
        disabled={disabled}
        displayEmpty
      >
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {options.map((option, index) => (
          <MenuItem value={option.hasOwnProperty('option') ? option.option : option} key={index} disabled={option.hasOwnProperty('enabled') && !option.enabled}>
            {option.hasOwnProperty('option') ? option.option : option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDropdown;
