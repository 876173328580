export default function numberWithCommas(x) {
  if (x == null || isNaN(x)) return '0';

  x = x.toString();
  let [integerPart, decimalPart] = x.split('.');

  let isNegative = integerPart.startsWith('-');
  if (isNegative) {
    integerPart = integerPart.slice(1);
  }

  // Do not format if the integer part is less than 4 digits
  if (integerPart.length > 3) {
    let lastThree = integerPart.slice(-3);
    let otherNumbers = integerPart.slice(0, -3);
    if (otherNumbers !== '') lastThree = ',' + lastThree;
    integerPart = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
  }

  // Rejoin integer and decimal parts without rounding
  let result = decimalPart ? integerPart + '.' + decimalPart : integerPart;

  // Add back the negative sign if the original number was negative
  return isNegative ? '-' + result : result;
}
