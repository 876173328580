import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
// import { Link } from 'react-router-dom';
// import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const HWMApartmentCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const optionforswhApartment = getQuantityValue(schema.hwmApartment__pi_option);
  const demandbybath = getQuantityValue(schema.hwmApartment__pi_demandByBath);
  const demandassumptiontype = getQuantityValue(schema.hwmApartment__pi_demandAssumptionType);
  const vendorcapexmultiplier = getQuantityValue(schema.hwmApartment_hwmApartmentVendorCapex);
  const demandbyarea = getQuantityValue(schema.hwmApartment__pi_demandByArea);

  const handleDemandArea = (demandarea) => {
    updateQuantityValue(schema.hwmApartment__pi_demandByArea, demandarea);
  };

  const handleDemandByBath = (demandbath) => {
    updateQuantityValue(schema.hwmApartment__pi_demandByBath, demandbath);
  };

  const handleDemandByAssumption = (assumption) => {
    updateQuantityValue(schema.hwmApartment__pi_demandAssumptionType, assumption);
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12} direction="column" style={{ paddingTop: '3%', paddingBottom: '3%' }}>
        <Typography className={classes.textHeader1}>Demand by Area</Typography>
        <Grid item xs={6} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandbyarea === 'total demand' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandArea('total demand')} />
              )}
              {demandbyarea !== 'total demand' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandArea('total demand')} />
              )}
              Total Demand (All floors excl Penthouses)
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandbyarea === 'number of floors' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandArea('number of floors')} />
              )}
              {demandbyarea !== 'number of floors' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandArea('number of floors')} />
              )}
              {getQuantityLabel(schema.hwmApartment__pi_userHotWaterFloors)}
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmApartment__pi_userHotWaterFloors)}
                placeholder=""
                onChange={(e) => updateQuantityValue(schema.hwmApartment__pi_userHotWaterFloors, e.target.value)}
                fullWidth
                required
                onClick={() =>
                  updateQuantityValue(schema.hwmApartment__pi_userHotWaterFloors, getQuantityDefaultValue(schema.hwmApartment__pi_userHotWaterFloors))
                }
                disabled={demandbyarea !== 'number of floors' ? true : false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userHotWaterFloors)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1}>Demand by Bath</Typography>
      <Typography className={demandassumptiontype === 'lpd per apartment' ? classes.textHeader3disabled : classes.textHeader3}>
        {demandbybath === 'all baths' && demandassumptiontype !== 'lpd per apartment' && (
          <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('all baths')} />
        )}
        {demandbybath !== 'all baths' && demandassumptiontype !== 'lpd per apartment' && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('all baths')} />
        )}
        {demandbybath !== 'all baths' && demandassumptiontype === 'lpd per apartment' && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />
        )}
        All baths
      </Typography>
      <Typography className={demandassumptiontype === 'lpd per apartment' ? classes.textHeader3disabled : classes.textHeader3}>
        {demandbybath === 'single bath' && demandassumptiontype !== 'lpd per apartment' && (
          <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('single bath')} />
        )}
        {demandbybath !== 'single bath' && demandassumptiontype !== 'lpd per apartment' && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('single bath')} />
        )}
        {demandbybath !== 'single bath' && demandassumptiontype === 'lpd per apartment' && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />
        )}
        Master bathroom
      </Typography>
      <Typography className={demandassumptiontype === 'lpd per apartment' ? classes.textHeader3disabled : classes.textHeader3}>
        {demandbybath === 'remaining baths' && demandassumptiontype !== 'lpd per apartment' && (
          <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('remaining baths')} />
        )}
        {demandbybath !== 'remaining baths' && demandassumptiontype !== 'lpd per apartment' && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('remaining baths')} />
        )}
        {demandbybath !== 'remaining baths' && demandassumptiontype === 'lpd per apartment' && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />
        )}
        All baths except master bathroom
      </Typography>
      <Grid container item xs={12} direction="column" style={{ paddingTop: '3%' }}>
        <Typography className={classes.textHeader1}>Demand Assumption</Typography>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandassumptiontype === 'lpcd per occupant' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByAssumption('lpcd per occupant')} />
              )}
              {demandassumptiontype !== 'lpcd per occupant' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByAssumption('lpcd per occupant')} />
              )}
              {getQuantityLabel(schema.hwmApartment__pi_userLpcdPerOccupant)}
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmApartment__pi_userLpcdPerOccupant)}
                placeholder=""
                onChange={(e) => updateQuantityValue(schema.hwmApartment__pi_userLpcdPerOccupant, e.target.value)}
                fullWidth
                required
                onClick={() =>
                  updateQuantityValue(schema.hwmApartment__pi_userLpcdPerOccupant, getQuantityDefaultValue(schema.hwmApartment__pi_userLpcdPerOccupant))
                }
                disabled={demandassumptiontype !== 'lpcd per occupant' ? true : false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userLpcdPerOccupant)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandassumptiontype === 'lpd per apartment' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByAssumption('lpd per apartment')} />
              )}
              {demandassumptiontype !== 'lpd per apartment' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByAssumption('lpd per apartment')} />
              )}
              {getQuantityLabel(schema.hwmApartment__pi_userLpdPerApartment)}
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmApartment__pi_userLpdPerApartment)}
                placeholder=""
                onChange={(e) => updateQuantityValue(schema.hwmApartment__pi_userLpdPerApartment, e.target.value)}
                fullWidth
                required
                onClick={(e) =>
                  updateQuantityValue(schema.hwmApartment__pi_userLpdPerApartment, getQuantityDefaultValue(schema.hwmApartment__pi_userLpdPerApartment))
                }
                disabled={demandassumptiontype !== 'lpd per apartment' ? true : false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userLpdPerApartment)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={classes.textHeader3}>
          {demandassumptiontype === 'link to installed efficient fixturess' && (
            <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByAssumption('link to installed efficient fixtures')} />
          )}
          {demandassumptiontype !== 'link to installed efficient fixtures' && (
            <RadioButtonUncheckedIcon
              className={classes.nonselectedRadiobox}
              onClick={() => handleDemandByAssumption('link to installed efficient fixtures')}
            />
          )}
          As per selected Efficient Fixtures
        </Typography>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>CAPEX(per unit installed)</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.hwmApartment__pi_capexSourceType) === 'vendor' ? (
                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => updateQuantityValue(schema.hwmApartment__pi_capexSourceType, '')} />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.hwmApartment__pi_capexSourceType, 'vendor');
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>
          {optionforswhApartment !== 'hybrid' && (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <Typography className={classes.textconstants}>{numberWithCommas(getQuantityValue(schema.hwmApartment_hwmApartmentVendorCapex))} RS</Typography>
              </Grid>
            </Grid>
          )}
          {optionforswhApartment === 'hybrid' && (
            <>
              <Typography className={classes.textHeader4} style={{ marginBottom: '-15px' }}>
                Solar Water Heater
              </Typography>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
                <Grid xs={7}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.hwmApartment_hybridVendorSwhCapex)}
                    placeholder=""
                    onChange={(e) => updateQuantityValue(schema.hwmApartment_hybridVendorSwhCapex, e.target.value)}
                    fullWidth
                    required
                    // onClick={() => setVendorCapex(0)}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">RS</Typography>
                </Grid>
              </Grid>
              <Typography className={classes.textHeader4} style={{ marginBottom: '-15px' }}>
                Heat Pump
              </Typography>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
                <Grid xs={7}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.hwmApartment_hybridVendorHpCapex)}
                    placeholder=""
                    onChange={(e) => updateQuantityValue(schema.hwmApartment_hybridVendorHpCapex, e.target.value)}
                    fullWidth
                    required
                    // onClick={() => setVendorCapex(0)}
                    disabled={true}
                  />
                </Grid>
                <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">RS</Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.hwmApartment__pi_capexSourceType) === 'custom' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.hwmApartment__pi_capexSourceType, '');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.hwmApartment__pi_capexSourceType, 'custom');
                  }}
                />
              )}
              Custom
            </Typography>
          </Grid>
          {optionforswhApartment !== 'hybrid' && (
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.hwmApartment__pi_customCapex)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.hwmApartment__pi_customCapex, e.target.value);
                  }}
                  fullWidth
                  required
                  onClick={() => updateQuantityValue(schema.hwmApartment__pi_customCapex, getQuantityDefaultValue(schema.hwmApartment__pi_customCapex))}
                  disabled={getQuantityValue(schema.hwmApartment__pi_capexSourceType) !== 'custom' ? true : false}
                  removeResetIcon={true}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">RS</Typography>
              </Grid>
            </Grid>
          )}
          {optionforswhApartment === 'hybrid' && (
            <>
              <Typography className={classes.textHeader4} style={{ marginBottom: '-15px' }}>
                Solar Water Heater
              </Typography>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
                <Grid xs={7}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.hwmApartment__pi_customHybridSwhCapex)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.hwmApartment__pi_customHybridSwhCapex, e.target.value);
                    }}
                    fullWidth
                    required
                    onClick={() =>
                      updateQuantityValue(schema.hwmApartment__pi_customHybridSwhCapex, getQuantityDefaultValue(schema.hwmApartment__pi_customHybridSwhCapex))
                    }
                    disabled={getQuantityValue(schema.hwmApartment__pi_capexSourceType) !== 'custom' ? true : false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">RS</Typography>
                </Grid>
              </Grid>
              <Typography className={classes.textHeader4} style={{ marginBottom: '-15px' }}>
                Heat Pump
              </Typography>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
                <Grid xs={7}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.hwmApartment__pi_customHybridHpCapex)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.hwmApartment__pi_customHybridHpCapex, e.target.value);
                    }}
                    fullWidth
                    required
                    onClick={() =>
                      updateQuantityValue(schema.hwmApartment__pi_customHybridHpCapex, getQuantityDefaultValue(schema.hwmApartment__pi_customHybridHpCapex))
                    }
                    disabled={getQuantityValue(schema.hwmApartment__pi_capexSourceType) !== 'custom' ? true : false}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">RS</Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Project Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes may affect other project or intervention values</Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>{getQuantityLabel(schema.hwmApartment__pi_userCityResidentialUtilityTariff)}</Typography>
      <Grid item xs={4} direction="column" style={{ paddingTop: '10px' }}>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.hwmApartment__pi_userCityResidentialUtilityTariff)}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.hwmApartment__pi_userCityResidentialUtilityTariff, e.target.value);
              }}
              fullWidth
              required
              onClick={(e) => {
                e.preventDefault();
                updateQuantityValue(
                  schema.hwmApartment__pi_userCityResidentialUtilityTariff,
                  getQuantityDefaultValue(schema.hwmApartment__pi_userCityResidentialUtilityTariff)
                );
              }}
              disabled={false}
            />
          </Grid>
          <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
            <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userCityResidentialUtilityTariff)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Constants
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmApartment__pi_userRooftopAreaPerSwh)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmApartment__pi_userRooftopAreaPerSwh)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmApartment__pi_userRooftopAreaPerSwh, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmApartment__pi_userRooftopAreaPerSwh, getQuantityDefaultValue(schema.hwmApartment__pi_userRooftopAreaPerSwh));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userRooftopAreaPerSwh)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmApartment__pi_userRooftopAreaPerHp)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmApartment__pi_userRooftopAreaPerHp)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmApartment__pi_userRooftopAreaPerHp, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmApartment__pi_userRooftopAreaPerHp, getQuantityDefaultValue(schema.hwmApartment__pi_userRooftopAreaPerHp));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userRooftopAreaPerHp)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmApartment__pi_userHotWaterHours)}</Typography>
            <CustomizedTooltips
              icon="info"
              title={<Typography>Total hot water peak hours- lower the number, higher the HP capacity. </Typography>}
              placement="right"
            />
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmApartment__pi_userHotWaterHours)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmApartment__pi_userHotWaterHours, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmApartment__pi_userHotWaterHours, getQuantityDefaultValue(schema.hwmApartment__pi_userHotWaterHours));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userHotWaterHours)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmApartment__pi_userNumberOfShafts)}</Typography>
            <CustomizedTooltips icon="info" title={<Typography>Used for calculating heat loss from standby water</Typography>} placement="right" />
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmApartment__pi_userNumberOfShafts)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmApartment__pi_userNumberOfShafts, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmApartment__pi_userNumberOfShafts, getQuantityDefaultValue(schema.hwmApartment__pi_userNumberOfShafts));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userNumberOfShafts)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmApartment_cityEmissionFactor)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>
                {numberWithCommas(getQuantityValue(schema.hwmApartment_cityEmissionFactor))} {getQuantityUnit(schema.hwmApartment_cityEmissionFactor)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HWMApartmentCustomAdvancedParameters;
