import AdvancedInterventionDetails from '../../defaultIntervention/AdvancedInterventionDetails';
import BasicInterventionDetails from '../../defaultIntervention/BasicInterventionDetails';
import NaturalStpBasicParameters from '../../nstp/NaturalStpBasicParameters';
import NaturalStpAdvancedParameters from '../../nstp/NaturalStpAdvancedParameters';
import NaturalStpPipParameters from '../../nstp/NaturalStpPipParameters';
import HWMApartmentCustomAdvancedParameters from '../../hwmApartment/HWMApartmentCustomAdvancedParameters';
import HWMApartmentCustomBasicParameters from '../../hwmApartment/HWMApartmentCustomBasicParameters';
import HWMApartmentProjectInputs from '../../hwmApartment/HWMApartmentProjectInputs';
import HWMClubHouseProjectInputs from '../../hwmClubhouse/HWMClubhouseProjectInputs';
import HWMClubHouseCustomAdvancesParameters from '../../hwmClubhouse/HWMClubhouseCustomAdvanceParameters';
import HWMClubHouseCustomBasicParameters from '../../hwmClubhouse/HWMClubhouseCustomBasicParameters';
import HWMVillaCustomBasicParameters from '../../hwmVilla/HWMVillaCustomBasicParameters';
import HWMVillaCustomAdvancesParameters from '../../hwmVilla/HWMVillaCustomAdvancesParameters';
import HWMVillaProjectInputs from '../../hwmVilla/HWMVillaProjectInputs';
import HWMPentHouseProjectInputs from '../../hwmPenthouse/HWMPentHouseProjectInputs';
import HWMPentHouseCustomAdvancesParameters from '../../hwmPenthouse/HWMPentHouseCustomAdvancesParameters';
import HWMPentHouseCustomBasicParameters from '../../hwmPenthouse/HWMPentHouseCustomBasicParameters';
import HWMSwimmingPoolCustomBasicParameters from '../../hwmSwimmingpool/HWMSwimmingPoolCustomBasicParameters';
import HWMSwimmingPoolCustomAdvancesParameters from '../../hwmSwimmingpool/HWMSwimmingPoolCustomAdvancesParameters';
import HWMSwimmingPoolProjectInputs from '../../hwmSwimmingpool/HWMSwimmingPoolProjectInputs';
import SPVApartmentCustomBasicParameters from '../../spvApartment/SPVApartmentCustomBasicParameters';
import SPVApartmentProjectInputs from '../../spvApartment/SPVApartmentProjectInputs';
import SPVApartmentCustomAdvancedParameters from '../../spvApartment/SPVApartmentCustomAdvancedParameters';
import SPVVillaCustomAdvancesParameters from '../../spvVilla/SPVVillaCustomAdvancedParameters';
import SPVVillaCustomBasicParameters from '../../spvVilla/SPVVillaCustomBasicParameters';
import SPVVillaProjectInputs from '../../spvVilla/SPVVillaProjectInputs';
import TwrCustomBasicParameters from '../../twr/TwrCustomBasicParameters';
import TwrCustomAdvanceParameters from '../../twr/TwrCustomAdvancedParameters';
import TwrCustomPipParameters from '../../twr/TwrCustomPipParameters';
import SwmCustomBasicParameters from '../../swm/SwmCustomBasicParameters';
import SwmProjectInputParameters from '../../swm/SwmProjectInputParameters';
import SwmCustomAdvanceParameters from '../../swm/SwmCustomAdvanceParameters';
import EfficientFixturesKitchenSinkCustomBasicParameters from '../../efficientFixturesKitchenSink/efficientFixturesKitchenSinkCustomBasicParameters';
import EfficientFixturesKitchenSinkCustomAdvancedParameters from '../../efficientFixturesKitchenSink/efficientFixturesKitchenSinkCustomAdvancedParameters';
import EfficientFixturesKitchenSinkProjectInputs from '../../efficientFixturesKitchenSink/efficientFixturesKitchenSinkProjectInputs';
import EfficientFixturesShowerCustomAdvancedParameters from '../../efficientFixturesShower/efficientFixturesShowerCustomAdvancedParameters';
import EfficientFixturesShowerProjectInputs from '../../efficientFixturesShower/efficientFixturesShowerProjectInputs';
import EfficientFixturesShowerCustomBasicParameters from '../../efficientFixturesShower/efficientFixturesShowerCustomBasicParameters';
import EfficientFixturesDualFlushCustomBasicParameters from '../../efficientFixturesDualFlush/efficientFixturesDualFlushCustomBasicParameters';
import EfficientFixturesDualFlushCustomAdvancedParameters from '../../efficientFixturesDualFlush/efficientFixturesDualFlushCustomAdvancedParameters';
import EfficientFixturesDualFlushProjectInputs from '../../efficientFixturesDualFlush/efficientFixturesDualFlushProjectInputs';
import EfficientFixturesFaucetCustomAdvancedParameters from '../../efficientFixturesFaucet/EfficientFixturesFaucetCustomAdvancedParameters';
import EfficientFixturesFaucetCustomBasicParameters from '../../efficientFixturesFaucet/EfficientFixturesFaucetCustomBasicParameters';
import EfficientFixturesFaucetProjectInputs from '../../efficientFixturesFaucet/EfficientFixturesFaucetProjectInputs';
import EfficientFixturesHealthFaucetCustomAdvancedParameters from '../../efficientFixturesHealthFaucet/EfficientFixturesHealthFaucetCustomAdvancedParameters';
import EfficientFixturesHealthFaucetCustomBasicParameters from '../../efficientFixturesHealthFaucet/EfficientFixturesHealthFaucetCustomBasicParameters';
import EfficientFixturesHealthFaucetProjectInputs from '../../efficientFixturesHealthFaucet/EfficientFixturesHealthFaucetProjectInputs';
import SPVPenthouseCustomBasicParameters from '../../spvPenthouse/SPVPenthouseCustomBasicParameters';
import SPVPenthouseProjectInputs from '../../spvPenthouse/SPVPenthouseProjectInputs';
import SPVPenthouseCustomAdvancedParameters from '../../spvPenthouse/SPVPenthouseCustomAdvancedParameters';
import SPVClubhouseCustomBasicParameters from '../../spvClubhouse/SPVClubhouseCustomBasicParameters';
import SPVClubhouseProjectInputs from '../../spvClubhouse/SPVClubhouseProjectInputs';
import SPVClubhouseCustomAdvancedParameters from '../../spvClubhouse/SPVClubhouseCustomAdvancedParameters';
import WWMCustomAdvancedParameters from '../../wwm/WWMCustomAdvancedParameters';
import WWMCustomBasicParameters from '../../wwm/WWMCustomBasicParameters';
import WWMProjectInputs from '../../wwm/WWMProjectInputs';
import CSPCustomAdvancedParameters from '../../csp/CSPCustomAdvancedParameters';
import CSPCustomBasicParameters from '../../csp/CSPCustomBasicParameters';
import CSPProjectInputs from '../../csp/CSPProjectInputs';
import EPNSCustomBasicParameters from '../../exteriorPaintsNonSunny/EPNSCustomBasicParameters';
import EPNSCustomAdvancesParameters from '../../exteriorPaintsNonSunny/EPNSCustomAdvancedParameters';
import EPNSProjectInputs from '../../exteriorPaintsNonSunny/EPNSProjectInputs';
import EPSCustomBasicParameters from '../../exteriorPaintsSunny/EPSCustomBasicParameters';
import EPSCustomAdvancesParameters from '../../exteriorPaintsSunny/EPSCustomAdvancedParameters';
import EPSProjectInputs from '../../exteriorPaintsSunny/EPSProjectInputs';
import { capitalizeFilterValues } from '../../../intervention/utils';

const KongInterventionsComponentsMapper = {
  hwmApartment: {
    schemaKey: 'hwmApartment',
    product: {
      selected: 'hwmApartment__pi_selectedProductIdentifier',
      default: 'hwmApartment_product',
    },
    basic: HWMApartmentCustomBasicParameters,
    advanced: HWMApartmentCustomAdvancedParameters,
    project: HWMApartmentProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const option = getQuantityValue(schema.hwmApartment__pi_option);
      const swhType = getQuantityValue(schema.hwmApartment__pi_swhType);
      const swhVariant = getQuantityValue(schema.hwmApartment__pi_swhVariant);
      const hpType = getQuantityValue(schema.hwmApartment__pi_hpType);

      return products?.reduce((memo, product) => {
        if (option === 'solar water heater') {
          if (product?.subcategory?.name === 'Solar Water Heaters' && product?.filters?.Variant?.includes(swhVariant?.toUpperCase())) {
            if (swhType === 'central') {
              if (product?.filters?.['System types']?.includes('Centralized Solar Heater')) {
                memo.push(product);
              }
            }

            if (swhType === 'individual') {
              if (product?.filters?.['System types']?.includes('Individual Solar Heater')) {
                memo.push(product);
              }
            }
          }
        }

        if (option === 'heat pump') {
          if (hpType === 'commercial') {
            if (
              product?.subcategory?.name === 'Heat Pumps' &&
              (product?.filters?.['System types']?.includes('Swimming Pool Heat Pumps') ||
                product?.filters?.['System types']?.includes('Commercial Heat pumps'))
            ) {
              memo.push(product);
            }
          }

          if (hpType === 'domestic') {
            if (product?.subcategory?.name === 'Heat Pumps' && product?.filters?.['System types']?.includes('Domestic Heat Pumps')) {
              memo.push(product);
            }
          }
        }

        if (option === 'hybrid') {
          if (product?.subcategory?.name === 'Hybrid system') {
            memo.push(product);
          }
        }

        return memo;
      }, []);
    },
  },
  spvApartment: {
    schemaKey: 'spvApartment',
    product: {
      selected: 'spvApartment__pi_selectedProductIdentifier',
      default: 'spvApartment_product',
    },
    basic: SPVApartmentCustomBasicParameters,
    advanced: SPVApartmentCustomAdvancedParameters,
    project: SPVApartmentProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const updatedProducts = capitalizeFilterValues(products, 'Inverter Type');

      const panelType = getQuantityValue(schema.spvApartment__pi_panelType);
      const inverterType = getQuantityValue(schema.spvApartment__pi_inverterType);

      return updatedProducts?.reduce((memo, product) => {
        if (product?.filters?.['Panel Type']?.includes(panelType) && product?.filters?.['Inverter Type']?.includes(inverterType)) {
          memo.push(product);
        }
        return memo;
      }, []);
    },
  },
  spvPenthouse: {
    schemaKey: 'spvPenthouse',
    product: {
      selected: 'spvPenthouse__pi_selectedProductIdentifier',
      default: 'spvPenthouse_product',
    },
    basic: SPVPenthouseCustomBasicParameters,
    advanced: SPVPenthouseCustomAdvancedParameters,
    project: SPVPenthouseProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const updatedProducts = capitalizeFilterValues(products, 'Inverter Type');
      const panelType = getQuantityValue(schema.spvPenthouse__pi_panelType);
      const inverterType = getQuantityValue(schema.spvPenthouse__pi_inverterType);

      return updatedProducts?.reduce((memo, product) => {
        if (product?.filters?.['Panel Type']?.includes(panelType) && product?.filters?.['Inverter Type']?.includes(inverterType)) {
          memo.push(product);
        }
        return memo;
      }, []);
    },
  },
  spvClubhouse: {
    schemaKey: 'spvClubhouse',
    product: {
      selected: 'spvClubhouse__pi_selectedProductIdentifier',
      default: 'spvClubhouse_product',
    },
    basic: SPVClubhouseCustomBasicParameters,
    advanced: SPVClubhouseCustomAdvancedParameters,
    project: SPVClubhouseProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const updatedProducts = capitalizeFilterValues(products, 'Inverter Type');
      const panelType = getQuantityValue(schema.spvClubhouse__pi_panelType);
      const inverterType = getQuantityValue(schema.spvClubhouse__pi_inverterType);

      return updatedProducts?.reduce((memo, product) => {
        if (product?.filters?.['Panel Type']?.includes(panelType) && product?.filters?.['Inverter Type']?.includes(inverterType)) {
          memo.push(product);
        }
        return memo;
      }, []);
    },
  },
  efficientFixturesHealthFaucet: {
    schemaKey: 'efficientFixturesHealthFaucet',
    product: {
      selected: 'efficientFixturesHealthFaucet__pi_selectedProductIdentifier',
      default: 'efficientFixturesHealthFaucet_product',
    },
    basic: EfficientFixturesHealthFaucetCustomBasicParameters,
    advanced: EfficientFixturesHealthFaucetCustomAdvancedParameters,
    project: EfficientFixturesHealthFaucetProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  efficientFixturesFaucet: {
    schemaKey: 'efficientFixturesFaucet',
    product: {
      selected: 'efficientFixturesFaucet__pi_selectedProductIdentifier',
      default: 'efficientFixturesFaucet_product',
    },
    basic: EfficientFixturesFaucetCustomBasicParameters,
    advanced: EfficientFixturesFaucetCustomAdvancedParameters,
    project: EfficientFixturesFaucetProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  efficientFixturesKitchenSink: {
    schemaKey: 'efficientFixturesKitchenSink',
    product: {
      selected: 'efficientFixturesKitchenSink__pi_selectedProductIdentifier',
      default: 'efficientFixturesKitchenSink_product',
    },
    basic: EfficientFixturesKitchenSinkCustomBasicParameters,
    advanced: EfficientFixturesKitchenSinkCustomAdvancedParameters,
    project: EfficientFixturesKitchenSinkProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  efficientFixturesShower: {
    schemaKey: 'efficientFixturesShower',
    product: {
      selected: 'efficientFixturesShower__pi_selectedProductIdentifier',
      default: 'efficientFixturesShower_product',
    },
    basic: EfficientFixturesShowerCustomBasicParameters,
    advanced: EfficientFixturesShowerCustomAdvancedParameters,
    project: EfficientFixturesShowerProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  efficientFixturesDualFlush: {
    schemaKey: 'efficientFixturesDualFlush',
    product: {
      selected: 'efficientFixturesDualFlush__pi_selectedProductIdentifier',
      default: 'efficientFixturesDualFlush_product',
    },
    basic: EfficientFixturesDualFlushCustomBasicParameters,
    advanced: EfficientFixturesDualFlushCustomAdvancedParameters,
    project: EfficientFixturesDualFlushProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  spvVilla: {
    schemaKey: 'spvVilla',
    product: {
      selected: 'spvVilla__pi_selectedProductIdentifier',
      default: 'spvVilla_product',
    },
    basic: SPVVillaCustomBasicParameters,
    advanced: SPVVillaCustomAdvancesParameters,
    project: SPVVillaProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const updatedProducts = capitalizeFilterValues(products, 'Inverter Type');
      const panelType = getQuantityValue(schema.spvVilla__pi_panelType);
      const inverterType = getQuantityValue(schema.spvVilla__pi_inverterType);

      return updatedProducts?.reduce((memo, product) => {
        if (product?.filters?.['Panel Type']?.includes(panelType) && product?.filters?.['Inverter Type']?.includes(inverterType)) {
          memo.push(product);
        }
        return memo;
      }, []);
    },
  },
  hwmClubhouse: {
    schemaKey: 'hwmClubhouse',
    product: {
      selected: 'hwmClubhouse__pi_selectedProductIdentifier',
      default: 'hwmClubhouse_product',
    },
    basic: HWMClubHouseCustomBasicParameters,
    advanced: HWMClubHouseCustomAdvancesParameters,
    project: HWMClubHouseProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const option = getQuantityValue(schema.hwmClubhouse__pi_option);
      const swhType = getQuantityValue(schema.hwmClubhouse__pi_swhType);
      const swhVariant = getQuantityValue(schema.hwmClubhouse__pi_swhVariant);
      const hpType = getQuantityValue(schema.hwmClubhouse__pi_hpType);

      return products?.reduce((memo, product) => {
        if (option === 'solar water heater') {
          if (product?.subcategory?.name === 'Solar Water Heaters' && product?.filters?.Variant?.includes(swhVariant?.toUpperCase())) {
            if (swhType === 'central') {
              if (product?.filters?.['System types']?.includes('Centralized Solar Heater')) {
                memo.push(product);
              }
            }

            if (swhType === 'individual') {
              if (product?.filters?.['System types']?.includes('Individual Solar Heater')) {
                memo.push(product);
              }
            }
          }
        }

        if (option === 'heat pump') {
          if (hpType === 'commercial') {
            if (
              product?.subcategory?.name === 'Heat Pumps' &&
              (product?.filters?.['System types']?.includes('Swimming Pool Heat Pumps') ||
                product?.filters?.['System types']?.includes('Commercial Heat pumps'))
            ) {
              memo.push(product);
            }
          }

          if (hpType === 'domestic') {
            if (product?.subcategory?.name === 'Heat Pumps' && product?.filters?.['System types']?.includes('Domestic Heat Pumps')) {
              memo.push(product);
            }
          }
        }
        return memo;
      }, []);
    },
  },
  hwmVilla: {
    schemaKey: 'hwmVilla',
    product: {
      selected: 'hwmVilla__pi_selectedProductIdentifier',
      default: 'hwmVilla_product',
    },
    basic: HWMVillaCustomBasicParameters,
    advanced: HWMVillaCustomAdvancesParameters,
    project: HWMVillaProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const option = getQuantityValue(schema.hwmVilla__pi_option);
      const swhType = getQuantityValue(schema.hwmVilla__pi_swhType);
      const swhVariant = getQuantityValue(schema.hwmVilla__pi_swhVariant);
      const hpType = getQuantityValue(schema.hwmVilla__pi_hpType);

      return products?.reduce((memo, product) => {
        if (option === 'solar water heater') {
          if (product?.subcategory?.name === 'Solar Water Heaters' && product?.filters?.Variant?.includes(swhVariant?.toUpperCase())) {
            if (swhType === 'central') {
              if (product?.filters?.['System types']?.includes('Centralized Solar Heater')) {
                memo.push(product);
              }
            }

            if (swhType === 'individual') {
              if (product?.filters?.['System types']?.includes('Individual Solar Heater')) {
                memo.push(product);
              }
            }
          }
        }

        if (option === 'heat pump') {
          if (hpType === 'commercial') {
            if (
              product?.subcategory?.name === 'Heat Pumps' &&
              (product?.filters?.['System types']?.includes('Swimming Pool Heat Pumps') ||
                product?.filters?.['System types']?.includes('Commercial Heat pumps'))
            ) {
              memo.push(product);
            }
          }

          if (hpType === 'domestic') {
            if (product?.subcategory?.name === 'Heat Pumps' && product?.filters?.['System types']?.includes('Domestic Heat Pumps')) {
              memo.push(product);
            }
          }
        }
        return memo;
      }, []);
    },
  },
  twr: {
    schemaKey: 'twr',
    product: {
      selected: 'twr__pi_selectedProductIdentifier',
      default: 'twr_product',
    },
    basic: TwrCustomBasicParameters,
    advanced: TwrCustomAdvanceParameters,
    project: TwrCustomPipParameters,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  nstp: {
    schemaKey: 'nstp',
    product: {
      selected: 'nstp__pi_selectedProductIdentifier',
      default: 'nstp_product',
    },
    basic: NaturalStpBasicParameters,
    advanced: NaturalStpAdvancedParameters,
    project: NaturalStpPipParameters,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  hwmPenthouse: {
    schemaKey: 'hwmPenthouse',
    product: {
      selected: 'hwmPenthouse__pi_selectedProductIdentifier',
      default: 'hwmPenthouse_product',
    },
    basic: HWMPentHouseCustomBasicParameters,
    advanced: HWMPentHouseCustomAdvancesParameters,
    project: HWMPentHouseProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const option = getQuantityValue(schema.hwmPenthouse__pi_option);
      const swhType = getQuantityValue(schema.hwmPenthouse__pi_swhType);
      const swhVariant = getQuantityValue(schema.hwmPenthouse__pi_swhVariant);
      const hpType = getQuantityValue(schema.hwmPenthouse__pi_hpType);

      return products?.reduce((memo, product) => {
        if (option === 'solar water heater') {
          if (product?.subcategory?.name === 'Solar Water Heaters' && product?.filters?.Variant?.includes(swhVariant?.toUpperCase())) {
            if (swhType === 'central') {
              if (product?.filters?.['System types']?.includes('Centralized Solar Heater')) {
                memo.push(product);
              }
            }

            if (swhType === 'individual') {
              if (product?.filters?.['System types']?.includes('Individual Solar Heater')) {
                memo.push(product);
              }
            }
          }
        }

        if (option === 'heat pump') {
          if (hpType === 'commercial') {
            if (
              product?.subcategory?.name === 'Heat Pumps' &&
              (product?.filters?.['System types']?.includes('Swimming Pool Heat Pumps') ||
                product?.filters?.['System types']?.includes('Commercial Heat pumps'))
            ) {
              memo.push(product);
            }
          }

          if (hpType === 'domestic') {
            if (product?.subcategory?.name === 'Heat Pumps' && product?.filters?.['System types']?.includes('Domestic Heat Pumps')) {
              memo.push(product);
            }
          }
        }
        return memo;
      }, []);
    },
  },
  hwmSwimmingPool: {
    schemaKey: 'hwmSwimmingPool',
    product: {
      selected: 'hwmSwimmingPool__pi_selectedProductIdentifier',
      default: 'hwmSwimmingPool_product',
    },
    basic: HWMSwimmingPoolCustomBasicParameters,
    advanced: HWMSwimmingPoolCustomAdvancesParameters,
    project: HWMSwimmingPoolProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      const { getQuantityValue } = get;
      const hpType = getQuantityValue(schema.hwmSwimmingPool__pi_hpType);

      return products?.reduce((memo, product) => {
        if (hpType === 'swimming pool') {
          if (product?.subcategory?.name === 'Heat Pumps' && product?.filters?.['System types']?.includes('Swimming Pool Heat Pumps')) {
            memo.push(product);
          }
        }
        return memo;
      }, []);
    },
  },
  swm: {
    schemaKey: 'swm',
    product: {
      selected: 'swm__pi_selectedProductIdentifier',
      default: 'swm_product',
    },
    basic: SwmCustomBasicParameters,
    advanced: SwmCustomAdvanceParameters,
    project: SwmProjectInputParameters,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  wwm: {
    schemaKey: 'wwm',
    product: {
      selected: 'wwm__pi_selectedProductIdentifier',
      default: 'wwm_product',
    },
    basic: WWMCustomBasicParameters,
    advanced: WWMCustomAdvancedParameters,
    project: WWMProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  csp: {
    schemaKey: 'csp',
    product: {
      selected: 'csp__pi_selectedProductIdentifier',
      default: 'csp_product',
    },
    basic: CSPCustomBasicParameters,
    advanced: CSPCustomAdvancedParameters,
    project: CSPProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  exteriorPaintsNonSunny: {
    schemaKey: 'exteriorPaintsNonSunny',
    product: {
      selected: 'exteriorPaintsNonSunny__pi_selectedProductIdentifier',
      default: 'exteriorPaintsNonSunny_product',
    },
    basic: EPNSCustomBasicParameters,
    advanced: EPNSCustomAdvancesParameters,
    project: EPNSProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  exteriorPaintsSunny: {
    schemaKey: 'exteriorPaintsSunny',
    product: {
      selected: 'exteriorPaintsSunny__pi_selectedProductIdentifier',
      default: 'exteriorPaintsSunny_product',
    },
    basic: EPSCustomBasicParameters,
    advanced: EPSCustomAdvancesParameters,
    project: EPSProjectInputs,
    filterProducts: ({ products, schema, get }) => {
      return products;
    },
  },
  default: {
    schemaKey: 'default',
    product: {
      selected: '',
      default: '',
    },
    basic: BasicInterventionDetails,
    advanced: AdvancedInterventionDetails,
    project: null,
    filterProducts: () => { },
  },
};

export default KongInterventionsComponentsMapper;
