import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PrimaryButton from '../../../../common/button/Button';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CustomBreadCrumbs from '../../../../common/breadcrumbs/CustomBreadCrumbs';
import DataAndCostAssumptions from './DataAndCostAssumptions';

import rooftopRunoff from '../../../../../assets/rooftopRunoff.svg';
import surfaceRunoff from '../../../../../assets/surfaceRunoff.svg';
import solarwaterheater from '../../../../../assets/solarwaterheater.svg';
import solarPV from '../../../../../assets/solarPV.svg';
import smartWaterMeter from '../../../../../assets/smartwatermeter.svg';
import medicalWaste from '../../../../../assets/medical.png';
import eWastehandling from '../../../../../assets/eWastehandling.svg';
import EfficientFixtures from '../../../../../assets/efficientFixtures.svg';
import dualflush from '../../../../../assets/dualflush.svg';
import CentralisedRO from '../../../../../assets/CentralisedRO.svg';
import blackwaterReuse from '../../../../../assets/blackwaterReuse.svg';
import biogasPlant from '../../../../../assets/icons/waste/biogas.svg';
import greywaterReuse from '../../../../../assets/greywater.svg';
import incinaretor from '../../../../../assets/icons/waste/snitary.svg';
import lightManagementSystem from '../../../../../assets/lightManagementSystem.svg';
import composter from '../../../../../assets/composter.svg';
import toxicNew from '../../../../../assets/icons/waste/segregation.svg';
import ExteriorPaints from '../../../../../assets/interventions/materials/exterior-paints.svg';
import concrete from '../../../../../assets/interventions/materials/concrete.svg';
import structure from '../../../../../assets/interventions/materials/structure.svg';
import balconyandutility from '../../../../../assets/interventions/materials/balconyandutility.svg';
import livinganddining from '../../../../../assets/interventions/materials/livinganddining.svg';
import bedroom from '../../../../../assets/interventions/materials/bedroom.svg';
import bathroom from '../../../../../assets/interventions/materials/bathroom.svg';
import kitchen from '../../../../../assets/interventions/materials/kitchen.svg';
import hwmVillaicon from '../../../../../assets/interventions/HWM Villa Icon.svg';
import nstpIcon from '../../../../../assets/interventions/NSTPicon.svg';

//TODO: Should be based on metafire key. Ex: 'hwmApartment'
const icons = [
  { intervention: 'Biogas To Electricity Generation', icon: lightManagementSystem },
  { intervention: 'Solar Water Heater', icon: solarwaterheater },
  { intervention: 'Solar PV', icon: solarPV },
  { intervention: 'Centralized RO', icon: CentralisedRO },
  { intervention: 'Efficient Fixtures', icon: EfficientFixtures },
  { intervention: 'Smart Water Meters', icon: smartWaterMeter },
  { intervention: 'Dual Flush', icon: dualflush },
  { intervention: 'Rooftop RWH', icon: rooftopRunoff },
  { intervention: 'Surface RWH', icon: surfaceRunoff },
  { intervention: 'Black Water Re-use', icon: blackwaterReuse },
  { intervention: 'Wet Waste Management', icon: biogasPlant },
  { intervention: 'E-Waste Handling', icon: eWastehandling },
  { intervention: 'Medical Waste Handling', icon: medicalWaste },
  { intervention: 'Grey Water Re-use', icon: greywaterReuse },
  { intervention: 'Sanitary Napkin Incinerator', icon: incinaretor },
  { intervention: 'Collection & Segregation Point', icon: toxicNew },
  { intervention: 'Recycling Dry Waste', icon: composter },
  { intervention: 'Exterior Paints', icon: ExteriorPaints },
  { intervention: 'Interior Paints', icon: ExteriorPaints },
  { intervention: 'Structural Steel', icon: structure },
  { intervention: 'Concrete', icon: concrete },
  { intervention: 'Living & Dining', icon: livinganddining },
  { intervention: 'Bedroom', icon: bedroom },
  { intervention: 'Kitchen', icon: kitchen },
  { intervention: 'Bathroom', icon: bathroom },
  { intervention: 'Utility & Balcony', icon: balconyandutility },

  { intervention: 'Hot Water Management Villa', icon: hwmVillaicon },
  { intervention: 'Hot Water Management Apartment', icon: hwmVillaicon },
  { intervention: 'Hot Water Management Clubhouse', icon: hwmVillaicon },
  { intervention: 'Hot Water Management Swimming Pool', icon: hwmVillaicon },
  { intervention: 'Hot Water Management Penthouse', icon: hwmVillaicon },

  { intervention: 'SPV for Apartment', icon: solarPV },
  { intervention: 'SPV for Clubhouse', icon: solarPV },
  { intervention: 'SPV for Villa', icon: solarPV },
  { intervention: 'SPV for Penthouse', icon: solarPV },

  { intervention: 'Efficient Fixtures - Health Faucet', icon: EfficientFixtures },
  { intervention: 'Efficient Fixtures - Faucet', icon: EfficientFixtures },
  { intervention: 'Efficient Fixtures - Kitchen Faucet', icon: EfficientFixtures },
  { intervention: 'Efficient Fixtures - Showers', icon: EfficientFixtures },
  { intervention: 'Efficient Fixtures - Dual Flush', icon: dualflush },
  { intervention: 'Treated Water Reuse', icon: greywaterReuse },
  { intervention: 'Natural STP', icon: nstpIcon },
];

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '2%',
    marginTop: '1%',
  },
  logo: {
    height: '90%',
    marginLeft: '13%',
  },
  cardheading: {
    textAlign: 'center',
    marginLeft: '0%',
    paddingLeft: '3%',
    paddingRight: '5%',
  },
  cardbody: {
    textAlign: 'center',
    marginTop: '8%',
  },
  subBody: {
    color: '#9E9E9E',
    paddingLeft: '2%',
  },
  paper: {
    height: '9vh',
    width: '120%',
    background: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
  },
  grid1: {
    marginTop: '-2%',
  },
  grid2: {
    marginLeft: '3%',
  },
  line: {
    height: 40,
  },
}));

const InterventionDetailsHeader = () => {
  const classes = useStyles();
  const params = useParams();
  const { intervention } = useInterventionDetailsContext();

  const selectedIcon = intervention ? icons.find((icon) => icon.intervention === intervention.name) : null;
  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <CustomBreadCrumbs
          links={[{ href: `/projects/${params.id}/interventions`, text: 'Interventions' }]}
          stage={
            intervention ? <b>{intervention.name}</b> : <CasaSkeleton variant="rect" height="2vh" width={80} animation="wave" style={{ marginTop: '1%' }} />
          }
        />
        {/* Only for changing values interventions cost and assumption box */}
        <Grid container item xs={12} alignItems="center" justify="flex-start" spacing={2} className={classes.root}>
          {!selectedIcon || selectedIcon.icon === null ? null : (
            <Grid item xs={1}>
              <img src={selectedIcon.icon} alt="Back" />
            </Grid>
          )}
          <Grid item xs={9}>
            {intervention ? (
              <Typography variant="h3" style={{ marginLeft: '-2%' }}>
                {intervention.name}
              </Typography>
            ) : (
              <CasaSkeleton variant="rect" height="6vh" width={200} animation="wave" />
            )}
          </Grid>
          <Grid item xs={2} style={{ display: 'none' }}>
            <PrimaryButton children="save changes" onClick={() => {}} />
          </Grid>
        </Grid>
        <Grid container xs={12} alignItems="right" direction="row">
          <div data-tour="datacost" style={{ marginLeft: 'auto' }}>
            <DataAndCostAssumptions />
          </div>
          {/* <Grid
              item
              xs={6}
              style={{
                padding: '3%',
              }}
            >
              <ViewPreviosSavesModal
                inputs={props.inputs && props.inputs}
                allSandboxes={props.allSandboxes}
                activeSandbox={props.activeSandbox}
                handleRevert={(data) => props.handleRevert(data)}
              />
            </Grid> */}
        </Grid>
      </Grid>
    </>
  );
};

export default InterventionDetailsHeader;
