import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import Vector from '../../../../../assets/Vector279.svg';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import rsToCr from '../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../utils/numberToComma';
import AddCustomIntervention from './AddCustomIntervention';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
// import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '0%',
    flexGrow: '1',
  },
  heading: {
    marginTop: '3%',
    width: '90%',
    marginLeft: '3%',
  },

  smallpaper1: {
    width: '85%',
    height: '10vh',
    padding: theme.spacing(2, 2, 2, 2),
    boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
    background: '#66E3BE',
    borderRadius: '10px',
  },
  smallpaper2: {
    width: '85%',
    height: '10vh',
    borderRadius: '10px',
    padding: theme.spacing(1.5, 1.5, 1.5, 2),
    boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
  },
  textColor: {
    color: 'rgba(255, 255, 255, 0.82)',
  },
  content: {
    marginTop: '28%',
  },
}));

function InterventionOverview({ data }) {
  const classes = useStyles();
  const { getValue, getUnit } = useAppMetafireUtilities();
  const financeSavings = data?.quantities?.overall?.finance;
  const resourceSavings = data?.quantities?.overall?.resource;

  const capex = financeSavings?.capex;
  const totalCapex = financeSavings?.totalCapex;
  const opexSavings = financeSavings?.opexSavings;
  const carbon = resourceSavings?.carbon;
  const carbonConverted = resourceSavings?.carbonConverted;

  return (
    <>
      <Grid
        // container
        item
        xs={5}
        align="left"
        direction="column"
      >
        <Typography variant="h5">
          <b>Financial Impact (₹ cr)</b>
        </Typography>
        <Paper className={classes.smallpaper1}>
          <Grid container alignItems="center" item xs={12} direction="row">
            <Grid container xs={4} direction="column">
              <Typography variant="h6" color="textSecondary" className={classes.textColor}>
                Capex Increase
              </Typography>
              <Grid xs style={{ marginTop: '-5%' }}></Grid>
              <Typography variant="h5" className={classes.textColor}>
                <b>{numberWithCommas(rsToCr(getUnit(capex), getValue(capex)))}</b>
              </Typography>
            </Grid>
            <Grid container xs={4} direction="column">
              <Typography variant="h6" color="textSecondary" className={classes.textColor}>
                Total Capex
              </Typography>
              <Grid xs style={{ marginTop: '-5%' }}></Grid>
              <Typography variant="h5" className={classes.textColor}>
                {numberWithCommas(rsToCr(getUnit(totalCapex), getValue(totalCapex)))}
              </Typography>
            </Grid>
            {/* <Grid item xs={1}></Grid> */}
            <Grid container xs={4} direction="column">
              <Typography variant="h6" color="textSecondary" className={classes.textColor}>
                Annual Savings
              </Typography>
              <Grid xs style={{ marginTop: '-5%' }}></Grid>
              <Typography variant="h5" className={classes.textColor}>
                {numberWithCommas(rsToCr('Rs', getValue(opexSavings)))}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid
        // container
        item
        xs={5}
        align="left"
        direction="column"
      >
        <Typography variant="h5">
          <b>Carbon Savings</b>
        </Typography>
        <Paper className={classes.smallpaper2}>
          <Grid container alignItems="center" item xs={12} direction="row">
            <Grid item xs={6}>
              <Typography variant="h5">
                {numberWithCommas(getValue(carbon))} {getUnit(carbon)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <img alt="v" src={Vector} style={{ height: 'auto' }}></img>
            </Grid>
            <Grid container xs={5} direction="column">
              <Typography variant="h6" color="textSecondary">
                {`${numberWithCommas(getValue(carbonConverted))} ${getUnit(carbonConverted)}`}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

export default InterventionOverview;
