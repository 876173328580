import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '40%',
    paddingTop: '10%',
  },
  lineone: {
    fontSize: '13px',
    lineHeight: '25px',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
  linetow: {
    fontSize: '150px',
    lineHeight: '150px',
    fontWeight: 'bold',
    letterSpacing: '12px',
    color: '#000000',
  },
  linethree: {
    fontSize: '16px',
    lineHeight: '25px',
    letterSpacing: '0.714286px',
    color: '#000000',
    marginLeft: '-10%',
  },
}));
const NotFound = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container className={classes.root}>
        <Grid xs={12}>
          <Typography className={classes.linetow}>404</Typography>
        </Grid>
        <Grid xs={12}>
          <Typography className={classes.linethree}>WE ARE SORRY BUT THE PAGE YOU REQUESTED WAS NOT FOUND</Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default NotFound;
