import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import Tour from 'reactour';
import { wlakthroughContext } from '../../../../../projectRouting/Index';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  tourtexthead: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingBottom: '10px',
  },
  tourtext: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'justify',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
}));

const InterventionDetailsTour = () => {
  const classes = useStyles();

  /*Implementation of tour*/
  const { uiWalkthroughContext, handleMyWalkthrough } = useContext(wlakthroughContext);
  /*Get status of how it works*/
  const [userprofile, setUserprofile] = useState('');
  const [isTourOpen, setIsTourOpen] = useState(false);
  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  // TODO: Should use hook instead of direct axios
  const closeTour = () => {
    if (userprofile.showhowitworks === true) axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => {});
    setIsTourOpen(false);
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };

  // TODO: Should use hook instead of direct axios
  /*End for all walkthrough*/
  const handleEnd = () => {
    axios.put(`/userprofiles/${userprofile._id}/howitworks`, { howitworks: false }).then((resp) => {});
    handleMyWalkthrough(false);
    clearAllBodyScrollLocks();
  };

  useEffect(() => {
    clearAllBodyScrollLocks();
    axios.get(`/userprofiles`).then((resp) => {
      if (resp.data && resp.data.data && resp.data.data) {
        setUserprofile(resp.data.data.data);
        handleMyWalkthrough(resp.data.data.data.showhowitworks);
      }
    });
  }, []);

  useEffect(() => {
    if (uiWalkthroughContext) setIsTourOpen(uiWalkthroughContext);
  }, [uiWalkthroughContext]);

  const tourProps = {
    isOpen: isTourOpen,
    onRequestClose: closeTour,
    steps: [
      {
        selector: '[data-tour="parameter"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Intervention Parameters</Typography>
            <Typography className={classes.tourtext}>Edit these parameters to customise solutions as per user requirements</Typography>
          </Grid>
        ),
        position: 'right',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '150px',
        },
      },
      {
        selector: '[data-tour="datacost"]',
        content: (
          <Grid style={{ marginBottom: '-15px' }}>
            <Typography className={classes.tourtexthead}>Data & Cost Assumptions</Typography>
            <Typography className={classes.tourtext}>View all data & cost assumptions that were used to calculate the solution values. </Typography>
            <Typography className={classes.tourtext} style={{ paddingTop: '10px' }}>
              Some assumptions can be edited while some are considered universal values and cannot be edited
            </Typography>
          </Grid>
        ),
        position: 'bottom',
        style: {
          backgroundColor: '#FFFFFF',
          borderRadius: '8px',
          height: '240px',
        },
      },
      /* __________________________________Vendor Tour code goes here___________________________________*/
    ],
  };
  return (
    <Tour
      {...tourProps}
      onAfterOpen={disableBody}
      onBeforeClose={enableBody}
      lastStepNextButton={
        <Button style={{ color: '#29E1AC', marginLeft: '150%', marginTop: '-60px', backgroundColor: '#FFFFFF', fontSize: '12px' }} onClick={handleEnd}>
          End
        </Button>
      }
      disableKeyboardNavigation={['esc']}
      showNavigation={false}
      showNumber={false}
      prevButton
      nextButton={<Button style={{ color: '#29E1AC', backgroundColor: '#FFFFFF', marginLeft: '150%', fontSize: '12px' }}>Next</Button>}
      disableInteraction={true}
      badgeContent={(curr, tot) => `${curr} of ${tot}`}
      rounded={12}
      startAt={0}
    />
  );
};

export default InterventionDetailsTour;
