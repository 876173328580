import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';

const capitalizeOpex = (str, searchValue) => {
  if (str.includes(searchValue)) {
    let result = str.replace(searchValue, searchValue.toUpperCase());
    return result;
  } else {
    return str;
  }
};

function SecondaryImpactCard(props) {
  const useStyles = makeStyles((theme) => ({
    paper1: {
      outline: 'none',
      // width: props.width,
      // height: props.height,
      padding: theme.spacing(2, 2, 1, 0),
      background: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    },
    alignTypo: {
      marginTop: '10%',
    },
    alignUnits: {
      paddingLeft: 10,
    },
    typo1: {
      color: '#3BC7EE',
    },
    typo2: {
      color: '#26E0A3',
    },
  }));
  const classes = useStyles();
  const { getValue, getLabel, getUnit } = useAppMetafireUtilities();
  const { inputs } = props;

  return (
    <>
      <Card className={classes.paper1}>
        <Grid container alignItems="center" direction="row" xs={12}>
          {inputs?.map((inputItem) => {
            return inputItem?.value?.map((valueItem) => (
              <>
                <Grid xs={3} container alignItems="center" direction="column">
                  <Typography style={{ textAlign: 'center' }} color="textSecondary" variant="h6">
                    {capitalizeOpex(getLabel(valueItem?.resource))}
                  </Typography>
                  <Typography className={classes.alignTypo} variant="h4">
                    <b className={classes.typo1}>{numberWithCommas(getValue(valueItem?.resource) || 0)}</b>
                  </Typography>
                  <Typography className={classes.alignTypo} color="textSecondary">
                    {getUnit(valueItem?.resource)}
                  </Typography>
                </Grid>
                <Grid xs={3} container alignItems="center" direction="column">
                  <Typography style={{ textAlign: 'center' }} color="textSecondary">
                    {getLabel(valueItem?.opex)}
                  </Typography>
                  <Typography className={classes.alignTypo} variant="h4">
                    <b className={classes.typo1}>{numberWithCommas(getValue(valueItem?.opex) || 0)}</b>
                  </Typography>
                  <Typography className={classes.alignTypo} color="textSecondary">
                    {getUnit(valueItem?.opex)}
                  </Typography>
                </Grid>
              </>
            ));
          })}
        </Grid>
      </Card>
    </>
  );
}
export default SecondaryImpactCard;
