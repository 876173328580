import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, FormControl, Select, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import useApiRequest from '../../../hooks/useApiRequest';
import PreferenceUnits from '../common/components/PreferenceUnits';
import LocationBox from '../common/components/Location';
import Skeletons from '../../../common/skeleton/Skeletons';
import SimplePIPS from '../common/components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import Fields from '../../../common/textfield/Fields';
import OrganisationSelect from './Components/OrganisationSelect';
import TooglebuttonGroupForCreateProject from '../../createproject/components/TooglebuttonGroupForCreateProject';
import { makeStyles } from '@material-ui/core/styles';

// make sure This component will be cleaned up into multiple components for multiple edit projects page - refer to wireframe
// create a context for sqm and sqft and make the components separate
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 155,
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
}));

export const EditProjectBasicPIP = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let skeleton = [1, 2, 3, 4, 5];
  let { type, next, name } = props;
  const classes = useStyles();

  // project - data new impl
  const [requiredpips, setRequiredpips] = useState('');
  const [projectName, setProjectName] = useState('');
  const [selectedOrganisationID, setSelectedOrganisationID] = useState('');
  const [projectCategory, setProjectCategory] = useState('');
  const [projectSubcateogory, setProjectSubcategory] = useState('');

  const [myArray, setMyArray] = useState([]);

  const [unitPreferencesPIP, setUnitPreferencesPIP] = useState('');
  const [simplePIPs, setSimplePIPs] = useState('');
  const [locationBox, setLocationBoxPIP] = useState('');

  // others
  const [data, setData] = useState('');
  const [projectPIP, setProjectPIP] = useState([]);
  const [projectData, setProjectData] = useState('');

  //Location Service
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [newloc, setNewloc] = useState({});
  const [value, setValue] = React.useState(options[0]);
  const [inputchange, setInputChange] = useState('');
  const [city, setCity] = useState({});

  // unit states
  const [unit, setUnit] = useState('');
  const [radioButtonValue, setRadioButtonValue] = useState('');
  const [unitPIPid, setUnitPIPid] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);
  const [re, makeRequest] = useApiRequest(`/pipschemev2?category=${type}`, {
    verb: 'get',
  });
  const [mo, doRequest] = useApiRequest(`/projectv2/${id}/basics`, {
    verb: 'get',
  });

  const [pips, doPipsRequest] = useApiRequest(`/pipschemev2?required=true`, {
    verb: 'get',
  });

  useEffect(() => {
    if (re.status === null && mo.status === null && pips.status === null) {
      makeRequest();
      doRequest();
      doPipsRequest();
    }
  }, [re, mo, pips]);

  useEffect(() => {
    const basicPIPs = ['project__pi_builtUpArea', 'project__pi_rooftopArea', 'project__pi_siteArea', 'project__pi_city'];
    if (data) {
      const unitPreferences = data.filter((field) => field.name === 'project__pi_unitPreferenceForArea');
      const SimplePIPs = data
        .filter((field) => basicPIPs.includes(field.name))
        ?.sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      const LocationBox = data.filter((field) => field.name === 'location');
      setSimplePIPs(SimplePIPs);
      setUnitPreferencesPIP(unitPreferences);
      setLocationBoxPIP(LocationBox);
    }
  }, [data]);

  //useEffects
  useEffect(() => {
    if (projectPIP.length !== 0) {
      let arr2 = [];
      projectPIP.forEach((d) => arr2.push({ PIPScheme: d.PIPScheme._id, value: d.value }));
      if (arr2.length !== 0) arr2.forEach((element) => appendChanged(element.PIPScheme, element.value));
    }
  }, [projectPIP]);

  useEffect(() => {
    if (projectPIP.length !== 0 && myArray.length !== 0) {
      myArray.forEach((ele) => {
        let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
        if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
      });
    }
  }, [projectPIP, myArray]);

  useEffect(() => {
    if (pips.response) setRequiredpips(pips?.response?.data?.data);
  }, [pips.response]);

  useEffect(() => {
    if (re.response) setData(re?.response?.data?.data?.data);
  }, [re.response]);

  useEffect(() => {
    if (mo.response) {
      const projectData = mo?.response?.data?.data?.data;
      setProjectData(projectData);
      setProjectName(projectData?.name);
      setProjectCategory(projectData?.category);
      setProjectSubcategory(projectData?.subcategory);
    }
  }, [mo.response]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/ProjectPIPMapping/get?project=${id}`)
        .then((response) => {
          if (response && response.data && response.data.data) setProjectPIP(response?.data?.data?.data);
        })
        .catch((err) => console.log(err, 'error'));
    }
  }, [id]);

  useEffect(() => {
    if (radioButtonValue && radioButtonValue === 'Sqm' && unitPIPid) appendChanged(unitPIPid, 'Sqm');
    if (radioButtonValue && radioButtonValue === 'Sqft' && unitPIPid) appendChanged(unitPIPid, 'Sqft');
  }, [radioButtonValue, unitPIPid]);

  useEffect(() => {
    if (unit && unit) setRadioButtonValue(unit);
  }, [unit]);

  useEffect(() => {
    if (radioButtonValue && radioButtonValue) setUnit(radioButtonValue);
  }, [radioButtonValue]);

  useEffect(() => {
    if (projectPIP) {
      const unit = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_unitPreferenceForArea');
      const projectUnit = unit && unit.value ? unit.value : 'Sqm';
      setUnit(projectUnit && projectUnit);
    }
  }, [projectPIP]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  useEffect(() => {
    if (props.saveAfterSomethingChanged === true && next) {
      props.toogleShowModal(false);
      handlSetSomethingChanged(false);
      props.setFalseAfterSomethingChanged();
      props.SETNEXT('');
      handleSave(next && next);
    }
  }, [props.saveAfterSomethingChanged, next]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };

  const handleSearch = (e) => {
    setSearch(true);
    setLoading(true);
    axios.get(`/location/?location=${inputchange}`).then((resp) => {
      if (resp && resp.data && resp.data.data) setOptions([...resp.data.data.data]);
    });
    setOpen(true);
    setLoading(false);
    return () => setSearch(false);
  };

  const handleSave = (next) => {
    if (type === 'basic' && newloc && newloc.placeId) {
      axios.get(`/location/place?id=${newloc.placeId}`).then((ress) => {
        if (ress && ress.data && ress.data.data) {
          let loc = ress.data.data.data;
          // sends data
          let body2 = {
            projectId: id,
            values: myArray && myArray.length !== 0 && myArray,
          };
          let body3 = {
            newLocation: {
              name: loc.name,
              type: 'Point',
              coordinates: [loc.coordinates.lat, loc.coordinates.long],
            },
          };
          axios.put(`/ProjectPIPMapping`, body2).then((response) => {
            axios.put(`/projectv2/${id}`, body3).then((response) => {
              props.toogleShowModal(false);
              handlSetSomethingChanged(false);
              props.setFalseAfterSomethingChanged();
              props.SETNEXT('');

              swal({ icon: 'success', title: 'Data Saved' });
              if (next && next === 'insights') history.push(`/projects/${id}/insights`);
              if (next && next === 'projects') history.push(`/projects`);
              else history.push(`/projects/${id}/edit?type=${next}`);
            });
          });
        }
      });
    } else {
      // sends data
      let body2 = {
        projectId: id,
        values: myArray && myArray.length !== 0 && myArray,
      };
      axios.put(`/ProjectPIPMapping`, body2).then((response) => {
        if (response) {
          let body1 = {
            name: projectName && projectName,
            category: projectCategory && projectCategory,
            subcategory: projectSubcateogory && projectSubcateogory,
            organisation: selectedOrganisationID && selectedOrganisationID,
          };
          // not working now test it
          axios.put(`/projectv2/${id}/basics`, body1).then((response) => {
            props.toogleShowModal(false);
            handlSetSomethingChanged(false);
            props.setFalseAfterSomethingChanged();
            props.SETNEXT('');
            swal({ icon: 'success', title: 'Data Saved' });
            if (next && next === 'insights') history.push(`/projects/${id}/insights`);
            if (next && next === 'projects') history.push(`/projects`);
            else history.push(`/projects/${id}/edit?type=${next}`);
          });
        }
      });
    }
  };

  // creates myArray for sending all values together
  const appendChanged = (id, value) => {
    const body = {
      PIPScheme: id,
      value: value,
    };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.PIPScheme === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, body]);
    }
  };

  const handleChangeCategory = (event, newSelected) => setProjectCategory(newSelected);
  const handleCityvalue = (pipmappingId, value) => {
    appendChanged(pipmappingId, value);
    const obj = myArray?.find((ele) => ele.PIPScheme === pipmappingId);
    setCity(obj);
  };

  useEffect(() => {
    const obj = projectPIP?.find((ele) => ele.PIPScheme.name === 'project__pi_city');
    setCity(obj);
  }, [projectPIP]);
  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData?.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>
      {/* Project Creation pips are now added here */}
      {/* Name */}
      <Grid container direction="row" item xs={12} style={{ paddingLeft: '2%', marginTop: '3%' }}>
        <Grid item xs={12} Style={{ marginTop: '3%' }}>
          <Typography variant="h5"> Enter Project Name*</Typography>
        </Grid>
        <Grid item xs={2}>
          <Fields
            error={!projectName ? true : false}
            helperText={!projectName ? 'project name missing.' : ''}
            id="standard-error-helper-text"
            placeholder="Project Name*"
            value={projectName}
            onChange={(event) => setProjectName(event.target.value)}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      {/* Organisation */}
      <Grid container direction="row" item xs={12} style={{ paddingLeft: '2%', marginTop: '3%' }}>
        <Grid container item xs={12} Style={{ marginTop: '3%' }}>
          <Typography variant="h5"> Enter Organisation Name*</Typography>
        </Grid>
        <Grid item xs={2}>
          <OrganisationSelect selectedOrganisationID={(payload) => setSelectedOrganisationID(payload)} projectOrganisation={projectData?.organisation} />
        </Grid>
      </Grid>
      <Grid container direction="row" item xs={12} style={{ paddingLeft: '2%' }}>
        <TooglebuttonGroupForCreateProject
          category={projectCategory && projectCategory}
          handleChange={handleChangeCategory}
          subCategory={projectSubcateogory && projectSubcateogory}
          setSubCategory={(v) => setProjectSubcategory(v)}
          requiredpips={requiredpips && requiredpips}
          appendChanged={(a, b) => appendChanged(a, b)}
          projectPIP={projectPIP && projectPIP}
          myArray={myArray && myArray}
          // clickedContinue={clickedContinue && clickedContinue}
          // setChange={(v) => setChange(v)}
          // change={change && change}
        />
      </Grid>
      {/* category and subcategory */}

      {/* units ////////////////////////////////////////////////////////////////////// */}
      {type && type === 'basic' && unitPreferencesPIP && projectPIP?.length !== 0
        ? unitPreferencesPIP.map((field, index) => {
            return (
              <PreferenceUnits
                field={field}
                type={type}
                index={index}
                radioButtonValue={radioButtonValue}
                onChange={(e) => {
                  setRadioButtonValue(e.target.value);
                  setUnitPIPid(field._id);
                }}
              />
            );
          })
        : null}

      {/* location box */}
      {type && type === 'basic' && locationBox && projectData
        ? locationBox.map((field, index) => {
            return (
              <LocationBox
                field={field}
                type={type}
                index={index}
                options={options}
                loading={loading}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  setNewloc(newValue);
                  if (newValue != null) {
                    appendChanged(field._id, newValue.description);
                  }
                }}
                onInputChange={(e, newInputValue) => {
                  setInputChange(newInputValue);
                }}
                handleSearch={handleSearch}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) => option.description === value.description}
                defaultValue={{
                  description: projectData?.location?.name,
                  co_ordinates: projectData?.location?.coordinates,
                }}
              />
            );
          })
        : type &&
          type !== 'fixture' &&
          type !== 'file' &&
          skeleton &&
          skeleton.map((d, index) => {
            return <Skeletons index={index} />;
          })}

      {/* pips */}
      {type && type === 'basic' && simplePIPs && projectPIP?.length !== 0 && projectData && city
        ? simplePIPs.map((field, index) => {
            if (field.name !== 'project__pi_city') {
              return (
                <SimplePIPS
                  key={index}
                  index={index}
                  unit={unit && unit}
                  field={field && field}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                />
              );
            } else {
              return (
                <Grid style={{ paddingLeft: '2%', paddingBottom: '2%' }}>
                  <Grid item xs={12} className={classes.spacing}>
                    <Typography variant="h5"> Enter Project City*</Typography>
                  </Grid>
                  <FormControl variant="outlined" className={classes.formControl}>
                    {/* <Select
                      value={'Bengaluru'}
                      onChange={(e) => {
                        e.preventDefault();
                        appendChanged(field._id, e.target.value);
                      }}
                    > */}
                    <Select value={city?.value} onChange={(e) => handleCityvalue(field._id, e.target.value)}>
                      <MenuItem value="Bengaluru" name="Bengaluru">
                        Bengaluru
                      </MenuItem>
                      <MenuItem value="Mumbai" name="Mumbai">
                        Mumbai
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              );
            }
          })
        : type &&
          type !== 'fixture' &&
          type !== 'file' &&
          skeleton &&
          skeleton.map((d, index) => {
            return <Skeletons index={index} />;
          })}

      {type && type === 'basic' ? (
        <Typography variant="h6" color="textSecondary" style={{ marginLeft: '2%' }}>
          *All fields are mandatory
        </Typography>
      ) : (
        ''
      )}
      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
