import React, { useEffect, useState, useContext } from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import useApiRequest from '../../../hooks/useApiRequest';
import Skeletons from '../../../common/skeleton/Skeletons';
import SimplePIPS from '../common/components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import CustomDwellingPIP from '../common/components/CustomDwellingPIP';

const EditProjectPageDwellingPIP = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let skeleton = [1, 2, 3, 4, 5];
  let { type, next, name } = props;

  const [myArray, setMyArray] = useState([]);
  const [simplePIPs, setSimplePIPs] = useState('');
  const [projectData, setProjectData] = useState('');

  /*will be cleaned up later*/
  const [studio, setStudio] = useState('');
  const [single, setSingle] = useState('');
  const [double, setDouble] = useState('');
  const [three, setThree] = useState('');
  const [four, setFour] = useState('');
  const [studioCarpet, setStudioCarpet] = useState('');
  const [singleCarpet, setSingleCarpet] = useState('');
  const [doublecarpet, setDoublecarpet] = useState('');
  const [threeecarpet, setThreecarpet] = useState('');
  const [fourcarpet, setFourcarpet] = useState('');
  const [villa_studio, setVilla_Studio] = useState('');
  const [villa_single, setVilla_Single] = useState('');
  const [villa_double, setVilla_Double] = useState('');
  const [villa_three, setVilla_Three] = useState('');
  const [villa_four, setVilla_Four] = useState('');
  const [villa_studioCarpet, setVilla_StudioCarpet] = useState('');
  const [villa_singleCarpet, setVilla_SingleCarpet] = useState('');
  const [villa_doublecarpet, setVilla_Doublecarpet] = useState('');
  const [villa_threeecarpet, setVilla_Threeecarpet] = useState('');
  const [villa_fourcarpet, setVilla_Fourcarpet] = useState('');

  // penth house related data
  const [pentHousePIP, setPentHousePIP] = useState('');
  const [pentHouseEnabled, setPentHouseEnabled] = useState(false);

  const [penthouseAvailableRooftop, setPenthouseAvailableRooftop] = useState('');
  const [penthouse_studio, setPenthouse_Studio] = useState('');
  const [penthouse_single, setPenthouse_Single] = useState('');
  const [penthouse_double, setPenthouse_Double] = useState('');
  const [penthouse_three, setPenthouse_Three] = useState('');
  const [penthouse_four, setPenthouse_Four] = useState('');

  // others
  const [data, setData] = useState('');
  const [projectPIP, setProjectPIP] = useState([]);

  // unit states
  const [unit, setUnit] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);
  const [{ status, response }, makeRequest] = useApiRequest(`/pipschemev2?category=${type}`, {
    verb: 'get',
  });
  const [mo, doRequest] = useApiRequest(`/projectv2/${id}/basics`, {
    verb: 'get',
  });

  if (status === null && type && type !== 'file') makeRequest();
  if (mo?.status === null && type !== 'file') doRequest();

  useEffect(() => {
    if (mo?.response) setProjectData(mo?.response?.data?.data?.data);
  }, [mo?.response]);

  useEffect(() => {
    const basicPIPs = ['project__pi_numberOfFloors', 'project__pi_wwr', 'project__pi_perimeter', 'project__pi_floorToFloorHeight'];
    const penthhousePIP = ['project__pi_penthouseEnabled'];
    if (data) {
      const SimplePIPs = data.filter((field) => basicPIPs.includes(field.name));
      const PenthhousePIP = data.find((field) => penthhousePIP.includes(field.name));
      setPentHousePIP(PenthhousePIP);
      setSimplePIPs(SimplePIPs);
    }
  }, [data]);

  //useEffects
  useEffect(() => {
    if (projectPIP.length !== 0) {
      let arr2 = [];
      projectPIP.forEach((d) => arr2.push({ PIPScheme: d.PIPScheme._id, value: d.value }));
      if (arr2.length !== 0) arr2.forEach((element) => appendChanged(element.PIPScheme, element.value));
    }
  }, [projectPIP]);

  useEffect(() => {
    if (projectPIP.length !== 0 && myArray.length !== 0) {
      myArray.forEach((ele) => {
        let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
        if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
      });
    }
  }, [projectPIP, myArray]);

  useEffect(() => {
    if (response) {
      const unsortedData = response && response.data.data.data;
      const sorted = unsortedData.sort((a, b) => compare(a, b));
      setData(sorted);
    }
  }, [response]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/ProjectPIPMapping/get?project=${id}`)
        .then((response) => {
          if (response && response.data && response.data.data) {
            setProjectPIP(response.data.data.data);
            const penthHousePIPFromResponse = response.data.data.data?.find((d) => d?.PIPScheme?.name === 'project__pi_penthouseEnabled');
            setPentHouseEnabled(penthHousePIPFromResponse?.value === true ? true : false);
          }
        })
        .catch((err) => console.log(err, 'error'));
    }
  }, [id]);

  // if penth house is not enabled then
  // useEffect(() => {
  //     if (!pentHouseEnabled) {
  //         // appendChanged(penthouseAvailableRooftop?.PIPScheme?._id, 0)
  //         appendChanged(penthouse_single?.PIPScheme?._id, 0)
  //         appendChanged(penthouse_double?.PIPScheme?._id, 0)
  //         appendChanged(penthouse_three?.PIPScheme?._id, 0)
  //         appendChanged(penthouse_four?.PIPScheme?._id, 0)
  //     }
  // }, [pentHouseEnabled]);

  useEffect(() => {
    if (projectPIP) {
      // check for pips for existing unit for the project
      const unit = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_unitPreferenceForArea');
      // find the value as sqm or sqft from pip mapping
      const projectUnit = unit && unit.value ? unit.value : 'Sqm';
      // set project unit if found from pips
      setUnit(projectUnit && projectUnit);
    }
  }, [projectPIP]);

  useEffect(() => {
    if (props.saveAfterSomethingChanged === true && next) {
      props.toogleShowModal(false);
      handlSetSomethingChanged(false);
      props.setFalseAfterSomethingChanged();
      props.SETNEXT('');
      handleSave(next && next);
    }
  }, [props.saveAfterSomethingChanged, next]);

  /*will be cleaned up later*/
  useEffect(() => {
    if (data) {
      const studio = data && data.find((d) => d.name === 'project__pi_studio');
      const single = data && data.find((d) => d.name === 'project__pi_oneBHK');
      const double = data && data.find((d) => d.name === 'project__pi_twoBHK');
      const three = data && data.find((d) => d.name === 'project__pi_threeBHK');
      const four = data && data.find((d) => d.name === 'project__pi_fourBHK');
      const studioCarpet = data && data.find((d) => d.name === 'project__pi_studioCarpetArea');
      const singleCarpet = data && data.find((d) => d.name === 'project__pi_oneBhkCarpetArea');
      const doublecarpet = data && data.find((d) => d.name === 'project__pi_twoBhkCarpetArea');
      const threeecarpet = data && data.find((d) => d.name === 'project__pi_threeBhkCarpetArea');
      const fourcarpet = data && data.find((d) => d.name === 'project__pi_fourBhkCarpetArea');
      const villa_studio = data && data.find((d) => d.name === 'project__pi_villaStudio');
      const villa_single = data && data.find((d) => d.name === 'project__pi_villaOneBHK');
      const villa_double = data && data.find((d) => d.name === 'project__pi_villaTwoBHK');
      const villa_three = data && data.find((d) => d.name === 'project__pi_villaThreeBHK');
      const villa_four = data && data.find((d) => d.name === 'project__pi_villaFourBHK');
      const villa_studioCarpet = data && data.find((d) => d.name === 'project__pi_villaStudioCarpetArea');
      const villa_singleCarpet = data && data.find((d) => d.name === 'project__pi_villaOneBHKCarpetArea');
      const villa_doublecarpet = data && data.find((d) => d.name === 'project__pi_villaTwoBHKCarpetArea');
      const villa_threeecarpet = data && data.find((d) => d.name === 'project__pi_villaThreeBHKCarpetArea');
      const villa_fourcarpet = data && data.find((d) => d.name === 'project__pi_villaFourBHKCarpetArea');
      const penthouseAvailableRooftopArea = data && data.find((d) => d.name === 'project__pi_availablePenthouseRooftopArea');
      const penthouse_single = data && data.find((d) => d.name === 'project__pi_penthouseOneBHK');
      const penthouse_double = data && data.find((d) => d.name === 'project__pi_penthouseTwoBHK');
      const penthouse_three = data && data.find((d) => d.name === 'project__pi_penthouseThreeBHK');
      const penthouse_four = data && data.find((d) => d.name === 'project__pi_penthouseFourBHK');
      const penthouse_studio = data && data.find((d) => d.name === 'project__pi_penthouseStudio');
      setFourcarpet(fourcarpet);
      setThreecarpet(threeecarpet);
      setDoublecarpet(doublecarpet);
      setSingleCarpet(singleCarpet);
      setStudioCarpet(studioCarpet);
      setFour(four);
      setThree(three);
      setDouble(double);
      setSingle(single);
      setStudio(studio);
      setVilla_Fourcarpet(villa_fourcarpet);
      setVilla_Threeecarpet(villa_threeecarpet);
      setVilla_Doublecarpet(villa_doublecarpet);
      setVilla_SingleCarpet(villa_singleCarpet);
      setVilla_StudioCarpet(villa_studioCarpet);
      setVilla_Four(villa_four);
      setVilla_Three(villa_three);
      setVilla_Double(villa_double);
      setVilla_Single(villa_single);
      setVilla_Studio(villa_studio);
      setPenthouse_Studio(penthouse_studio);
      setPenthouse_Single(penthouse_single);
      setPenthouse_Double(penthouse_double);
      setPenthouse_Three(penthouse_three);
      setPenthouse_Four(penthouse_four);
      setPenthouseAvailableRooftop(penthouseAvailableRooftopArea);
    }
  }, [data]);

  const compare = (a, b) => {
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    else return 0;
  };

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };

  const handleSave = (next) => {
    // sends data
    let body2 = {
      projectId: id,
      values: myArray && myArray.length !== 0 && myArray,
    };
    axios.put(`/ProjectPIPMapping`, body2).then((response) => {
      props.toogleShowModal(false);
      handlSetSomethingChanged(false);
      props.setFalseAfterSomethingChanged();
      props.SETNEXT('');
      swal({ icon: 'success', title: 'Data Saved' });
      if (next && next === 'insights') history.push(`/projects/${id}/insights`);
      if (next && next === 'projects') history.push(`/projects`);
      else history.push(`/projects/${id}/edit?type=${next}`);
    });
  };

  // creates myArray for sending all values together
  const appendChanged = (id, value) => {
    const body = {
      PIPScheme: id,
      value: value,
    };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.PIPScheme === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, body]);
    }
  };

  // console.log(myArray && myArray, "pip**")

  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData?.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {/* Dwelling - will be cleaned up later*/}
      {type && type === 'dwelling' && simplePIPs && projectPIP.length !== 0 && (
        <>
          <Grid container xs={12} style={{ marginLeft: '2%', marginBottom: '3%', marginTop: '3%' }}>
            {/* studio */}
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="h5">Studio</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? studio && studio : villa_studio && villa_studio}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={1}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? studioCarpet && studioCarpet : villa_studioCarpet && villa_studioCarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
            {/* onebhk */}
            <Grid container item xs={12}>
              <Grid xs={12}>
                <Typography variant="h5" style={{ marginTop: '2%' }}>
                  1 BHK
                </Typography>
              </Grid>
              <Grid container xs={12} alignItems="center" justifyContent="flex-start">
                <Grid item xs={6}>
                  <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
                    <Typography variant="h6" style={{ letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                      {single?.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="center">
                  <Grid container alignItems="center" item xs={12}>
                    <Typography variant="h5">Carpet Area</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} alignItems="center" justifyContent="flex-start">
                <Grid item xs={6}>
                  <CustomDwellingPIP
                    index={0}
                    unit={unit && unit}
                    field={projectData?.subcategory != 'villa' ? single && single : villa_single && villa_single}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="center">
                  <Grid container alignItems="center" item xs={12}>
                    <CustomDwellingPIP
                      index={0}
                      unit={unit && unit}
                      field={projectData?.subcategory != 'villa' ? singleCarpet && singleCarpet : villa_singleCarpet && villa_singleCarpet}
                      myArray={myArray && myArray}
                      projectPIP={projectPIP && projectPIP}
                      appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                      type={type}
                      PIPs={simplePIPs && simplePIPs}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* two bhk */}
            <Grid container item xs={12}>
              <Grid xs={12}>
                <Typography variant="h5" style={{ marginTop: '2%' }}>
                  2 BHK
                </Typography>
              </Grid>
              <Grid container xs={12} alignItems="center" justifyContent="flex-start">
                <Grid item xs={6}>
                  <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
                    <Typography variant="h6" style={{ letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                      {double?.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="center">
                  <Grid container alignItems="center" item xs={12}>
                    <Typography variant="h5">Carpet Area</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} alignItems="center" justifyContent="flex-start">
                <Grid item xs={6}>
                  <CustomDwellingPIP
                    index={0}
                    unit={unit && unit}
                    field={projectData?.subcategory != 'villa' ? double && double : villa_double && villa_double}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                </Grid>
                <Grid item xs={6} container alignItems="center" justifyContent="center">
                  <Grid container alignItems="center" item xs={12}>
                    <CustomDwellingPIP
                      index={0}
                      unit={unit && unit}
                      field={projectData?.subcategory != 'villa' ? doublecarpet && doublecarpet : villa_doublecarpet && villa_doublecarpet}
                      myArray={myArray && myArray}
                      projectPIP={projectPIP && projectPIP}
                      appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                      type={type}
                      PIPs={simplePIPs && simplePIPs}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* three bhk */}
            <Grid container item xs={12} style={{ marginTop: '3%' }}>
              <Grid item xs={6}>
                <Typography variant="h5">3 BHK</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? three && three : villa_three && villa_three}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? threeecarpet && threeecarpet : villa_threeecarpet && villa_threeecarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
            {/* fourbhk */}
            <Grid container item xs={12} style={{ marginTop: '3%' }}>
              <Grid item xs={6}>
                <Typography variant="h5">4 BHK</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? four && four : villa_four && villa_four}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Carpet Area</Typography>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? fourcarpet && fourcarpet : villa_fourcarpet && villa_fourcarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {/*Working*/}
      {type && type === 'dwelling' && simplePIPs && projectPIP.length !== 0
        ? simplePIPs.map((field, index) => {
            return (
              <SimplePIPS
                index={index}
                unit={unit && unit}
                field={field && field}
                myArray={myArray && myArray}
                projectPIP={projectPIP && projectPIP}
                appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                type={type}
              />
            );
          })
        : type &&
          type === 'dwelling' &&
          skeleton &&
          skeleton.map((d, index) => {
            return <Skeletons index={index} />;
          })}

      {/* Penthouse details  enable and disable*/}
      {projectData && projectData?.subcategory === 'apartment' && (
        <Grid item container justifyContent="left" direction="row" xs={12} style={{ paddingLeft: '2%' }}>
          <HeadAndBackIcon
            heading={`Penthouse Details`}
            visitGoals={false}
            headingVariant="h5"
            headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
            showArrow={false}
          />
          <Grid xs={12}>
            <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
              {pentHousePIP?.description}
            </Typography>
          </Grid>
          <Grid xs={12} container justifyContent="flex-start" alignItems="center">
            <Grid xs={1} container justifyContent="flex-start" alignItems="flex-start">
              <Checkbox
                checked={pentHouseEnabled}
                style={{ color: '#66E3BE' }}
                onChange={(e) => {
                  setPentHouseEnabled(e.target.checked);
                  appendChanged(pentHousePIP?._id, e.target.checked);
                }}
              />
            </Grid>
            <Grid xs={10} container justifyContent="flex-start" alignItems="center" style={{ marginLeft: '-5%' }}>
              <Typography variant="h6">{pentHousePIP?.displayName}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* for penthouse pip -  will be cleaned up later*/}
      {pentHouseEnabled && pentHouseEnabled === true && type && type === 'dwelling' && projectData && projectData?.subcategory === 'apartment' ? (
        <>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">Available Penthouse Rooftop Area</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={penthouseAvailableRooftop && penthouseAvailableRooftop}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">Studio</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={penthouse_studio && penthouse_studio}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">1 BHK</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={penthouse_single && penthouse_single}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">2 BHK</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={penthouse_double && penthouse_double}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">3 BHK</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={penthouse_three && penthouse_three}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
            <Typography variant="h5">4 BHK</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={penthouse_four && penthouse_four}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        ''
      )}

      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
export default EditProjectPageDwellingPIP;
