import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import Fields from '../../../common/textfield/Fields';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import useApiRequest from '../../../hooks/useApiRequest';
import { ProjectContext } from '../../../../routing/IndexRouting';
import CustomizedTooltips from '../../../common/tooltip/TooltipV2';
import MaterialPresetBrand from './components/MaterialsPresetBrand';
import MaterialPresetPaint from './components/MaterialsPresetPaint';
import MaterialPresetFinish from './components/MaterialsPresetFinish';
import Skeletons from '../../../common/skeleton/Skeletons';
import { TooltipData, ParentPage, ChildPage, ComponentName, ComponentTooltipData } from '../../../../staticData/TooltipStaticData';
import { GreenRadio } from '../../../common/RadioButton/GreenRadio';
import { SomethingChanged } from '../EditProject';
import InteriorPaints from './components/InteriorPaints';
import IndoorFlooring from './components/IndoorFlooring';
import PrimaryButton from '../../../common/button/Button';

const MaterialDetails = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let skeleton = [1, 2, 3, 4, 5];
  let { type, next, name } = props;
  const [projectPIP, setProjectPIP] = useState('');
  const [data, setData] = useState('');
  const [myArray, setMyArray] = useState([]);
  const [value, setValue] = React.useState(undefined);
  const [manualPips, setManualPips] = useState('');
  const [presetPips, setpresetPips] = useState('');

  // for exterior
  const [paintBrands, setPaintBrands] = useState('');
  const [paintFinishes, setPaintFinishes] = useState('');
  const [paintNames, setPaintNames] = useState('');

  // for interior
  const [interiorPaintBrands, setInteriorPaintBrands] = useState('');

  const [selectedPaintBrand, setSelectedPaintBrand] = useState('');
  const [selectedPaintFinish, setSelectedPaintFinish] = useState('');
  const [selectedPaintName, setSelectedPaintName] = useState('');

  const [solventEnabled, setSolventEnabled] = useState(false);
  const [waterBasedEnabled, setWaterBasedEnabled] = useState(false);
  const [manualEnabled, setManualEnabled] = useState(false);

  //
  const [typeOfConcretePIP, setTypeOfConcretePIP] = useState('');
  const [exteriorPaintUserChoicePIP, setExteriorPaintUserChoicePIP] = useState('');
  const [exteriorPaintUserChoicePIPValue, setExteriorPaintUserChoicePIPValue] = useState('');

  const [interiorPaintUserChoicePIP, setInteriorPaintUserChoicePIP] = useState('');
  const [interiorPaintUserChoicePIPValue, setInteriorPaintUserChoicePIPValue] = useState('');

  const { projectData } = useContext(ProjectContext);

  const [indoorFlooringPips, setIndoorFlooringPips] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);

  // others
  const [{ status, response }, makeRequest] = useApiRequest(`/pipschemev2?category=${type}`, {
    verb: 'get',
  });

  if (status === null && type && type !== 'file') makeRequest();

  useEffect(() => {
    if (response) {
      const unsortedData = response && response.data.data.data;
      const manualPips =
        unsortedData &&
        unsortedData.filter(
          (d) =>
            d.name === 'project__pi_exteriorPaintManualBrandName' ||
            d.name === 'project__pi_exteriorPaintManualPaintName' ||
            d.name === 'project__pi_exteriorPaintManualCoverageArea' ||
            d.name === 'project__pi_exteriorPaintManualEC' ||
            d.name === 'project__pi_exteriorPaintManualCapex'
        );
      const presetPips =
        unsortedData &&
        unsortedData
          .filter(
            (d) =>
              d.name === 'project__pi_exteriorPaintWaterBasedBrandName' ||
              d.name === 'project__pi_exteriorPaintWaterBasedFinish' ||
              d.name === 'project__pi_exteriorPaintWaterBasedPaintName' ||
              d.name === 'project__pi_exteriorPaintManualCoverageArea' ||
              d.name === 'project__pi_exteriorPaintManualEC' ||
              d.name === 'project__pi_exteriorPaintManualCapex'
          )
          .sort((a, b) => (a.name === 'project__pi_exteriorPaintWaterBasedBrandName' ? -1 : 0));
      setpresetPips(presetPips && presetPips);
      setManualPips(manualPips && manualPips);

      const indoorPips = unsortedData.filter(
        (d) =>
          d.name === 'project__pi_indoorFlooringLivingAndDining' ||
          d.name === 'project__pi_indoorFlooringKitchen' ||
          d.name === 'project__pi_indoorFlooringBedroom' ||
          d.name === 'project__pi_indoorFlooringBathroom' ||
          d.name === 'project__pi_indoorFlooringUtilityAndBalcony'
      );
      setIndoorFlooringPips(indoorPips && indoorPips);
      setTypeOfConcretePIP(unsortedData.find((d) => d.name === 'project__pi_typeOfConcrete'));
      setExteriorPaintUserChoicePIP(unsortedData.find((d) => d.name === 'project__pi_exteriorPaintUserChoice'));
      setInteriorPaintUserChoicePIP(unsortedData.find((d) => d.name === 'project__pi_interiorPaintUserChoice'));

      setData(unsortedData && unsortedData);
    }
  }, [response]);

  useEffect(() => {
    axios.get(`/PaintProvider?list=Brand&type=Exterior Paints`).then((res) => {
      setPaintBrands(res && res.data.data.data);
      axios.get(`/PaintProvider?list=Brand&type=Interior Paints`).then((res) => setInteriorPaintBrands(res && res.data.data.data));
    });
  }, []);

  useEffect(() => {
    if (selectedPaintBrand) {
      axios.get(`PaintProvider?list=Finish&type=Exterior Paints&brand=${selectedPaintBrand}`).then((res) => setPaintFinishes(res && res.data.data.data));
    }
  }, [selectedPaintBrand]);

  useEffect(() => {
    if (selectedPaintBrand && selectedPaintFinish) {
      axios
        .get(`PaintProvider?list=Paint&brand=${selectedPaintBrand}&finish=${selectedPaintFinish}&type=Exterior Paints`)
        .then((res) => setPaintNames(res && res.data.data.data));
    }
  }, [selectedPaintBrand, selectedPaintFinish]);

  useEffect(() => {
    if (projectPIP.length !== 0) {
      let arr2 = [];
      projectPIP.forEach((d) => arr2.push({ PIPScheme: d.PIPScheme._id, value: d.value }));
      if (arr2.length !== 0) arr2.forEach((element) => appendChanged(element.PIPScheme, element.value));
    }
  }, [projectPIP]);

  useEffect(() => {
    if (projectPIP.length !== 0 && myArray.length !== 0) {
      myArray.forEach((ele) => {
        let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
        if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
      });
    }
  }, [projectPIP, myArray]);

  useEffect(() => {
    if (props.saveAfterSomethingChanged === true && next) {
      props.toogleShowModal(false);
      handlSetSomethingChanged(false);
      props.setFalseAfterSomethingChanged();
      handleSave(next && next);
    }
  }, [props.saveAfterSomethingChanged, next]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/ProjectPIPMapping/get?project=${id}`)
        .then((response) => {
          if (response && response.data && response.data.data) setProjectPIP(response.data.data.data);
        })
        .catch((err) => console.log(err, 'error'));
    }
  }, [id]);

  useEffect(() => {
    if (projectPIP) {
      const presetType = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintUserChoice');
      const presetBrand = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintWaterBasedBrandName');
      const presetFinish = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintWaterBasedFinish');
      const presetName = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintWaterBasedPaintName');
      const presetTypeValue = presetType && presetType.value ? presetType.value : '';
      const presetBrandValue = presetBrand && presetBrand.value ? presetBrand.value : '';
      const presetFinishValue = presetFinish && presetFinish.value ? presetFinish.value : '';
      const presetNameValue = presetName && presetName.value ? presetName.value : '';
      if (presetBrandValue && presetBrandValue) setSelectedPaintBrand(presetBrandValue);
      if (presetFinishValue && presetFinishValue) setSelectedPaintFinish(presetFinishValue);
      if (presetNameValue && presetNameValue) setSelectedPaintName(presetNameValue);
      if (presetTypeValue && presetTypeValue === 'Solvent-Based Paints') setSolventEnabled(true);
      if (presetTypeValue && presetTypeValue === 'Use Paints from popular brands') setWaterBasedEnabled(true);
      if (presetTypeValue && presetTypeValue === 'Manual') setManualEnabled(true);

      const exteriorPaintUserChoice = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_exteriorPaintUserChoice');
      const interiorPaintUserChoice = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_interiorPaintUserChoice');

      setExteriorPaintUserChoicePIPValue(exteriorPaintUserChoice?.value);
      setInteriorPaintUserChoicePIPValue(interiorPaintUserChoice?.value);
    }
  }, [projectPIP]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else history.push(`/projects/${id}/edit?type=${next}`);
    }
  };

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects`);
    history.push(`/projects/${id}/edit?type=basic`);
  };

  const appendChanged = (id, value) => {
    const body = {
      PIPScheme: id,
      value: value,
    };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.PIPScheme === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else setMyArray((oldArray) => [...oldArray, body]);
  };

  const handleSave = (next) => {
    if (next === 'file') {
      let body2 = {
        projectId: id,
        values: myArray && myArray.length !== 0 && myArray,
      };
      axios.put(`/ProjectPIPMapping`, body2).then((response) => {
        swal({ icon: 'success', title: 'Data Saved' });
        props.setFalseAfterSomethingChanged();
        handlSetSomethingChanged(false);
        history.push(`/projects/${id}/edit?type=${next}`);
      });
    }
  };

  useEffect(() => {
    if (solventEnabled) setValue('preset1');
    if (waterBasedEnabled) setValue('preset2');
    if (manualEnabled) setValue('other');
  }, [solventEnabled, waterBasedEnabled, manualEnabled]);

  const filterFromData = (tooltipData, ParentPage, ChildPage, ComponentName) => {
    const result = tooltipData && tooltipData.find((d) => d.parentPage === ParentPage && d.childPage === ChildPage && d.componentName === ComponentName);
    return result && result.content;
  };

  const ExteriorPaintsTooltipData = filterFromData(
    TooltipData,
    ParentPage.INTERVENTIONS,
    ChildPage.INTERVENTIONS_SUMMARY,
    ComponentName.EXTERIOR_PAINTS,
    ComponentTooltipData.EXTERIOR_PAINTS
  );

  const handleError = (error) => swal({ icon: 'error', title: error });

  return (
    <>
      <Grid container justifyContent="flex-start" direction="column" item xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData ? projectData.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {data && data ? (
        <>
          {/* exterior implementation */}
          <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
            <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '3%' }}>
              <Typography variant="h5">Exterior Paint</Typography>
              <CustomizedTooltips
                marginTop="15px"
                icon="info"
                title={ExteriorPaintsTooltipData.map((v) => (
                  <Typography>{v.data}</Typography>
                ))}
                placement="right"
              />
            </Grid>
            <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="exterior paint"
                  name="paint schemes"
                  value={
                    myArray &&
                    exteriorPaintUserChoicePIP &&
                    myArray.length !== 0 &&
                    myArray.find((d) => d.PIPScheme === exteriorPaintUserChoicePIP._id) &&
                    myArray.find((d) => d.PIPScheme === exteriorPaintUserChoicePIP._id).value
                  }
                  onChange={(e) => {
                    appendChanged(exteriorPaintUserChoicePIP && exteriorPaintUserChoicePIP._id, e.target.value);
                    setExteriorPaintUserChoicePIPValue(e.target.value);
                  }}
                >
                  {exteriorPaintUserChoicePIP.options.map((option, index) => {
                    return (
                      <>
                        <FormControlLabel key={index} value={option} control={<GreenRadio />} label={option} />
                        {exteriorPaintUserChoicePIPValue &&
                          index === 1 &&
                          exteriorPaintUserChoicePIPValue === 'Water Based' &&
                          presetPips &&
                          presetPips.map((pip) => {
                            if (pip.name === 'project__pi_exteriorPaintWaterBasedBrandName') {
                              return (
                                <>
                                  <MaterialPresetBrand
                                    value={value && value}
                                    pip={pip && pip}
                                    selectedPaintBrand={selectedPaintBrand && selectedPaintBrand}
                                    paintBrands={paintBrands && paintBrands}
                                    setSelectedPaintBrand={(v) => setSelectedPaintBrand(v)}
                                    appendChanged={(a, b) => appendChanged(a, b)}
                                  />
                                </>
                              );
                            }
                            if (pip.name === 'project__pi_exteriorPaintWaterBasedPaintName') {
                              return (
                                <>
                                  <MaterialPresetPaint
                                    value={value && value}
                                    pip={pip && pip}
                                    selectedPaintBrand={selectedPaintBrand && selectedPaintBrand}
                                    selectedPaintFinish={selectedPaintFinish && selectedPaintFinish}
                                    paintNames={paintNames && paintNames}
                                    selectedPaintName={selectedPaintName && selectedPaintName}
                                    setSelectedPaintName={(v) => setSelectedPaintName(v)}
                                    appendChanged={(a, b) => appendChanged(a, b)}
                                  />
                                </>
                              );
                            }
                            if (pip.name === 'project__pi_exteriorPaintWaterBasedFinish') {
                              return (
                                <>
                                  <MaterialPresetFinish
                                    value={value && value}
                                    pip={pip && pip}
                                    selectedPaintBrand={selectedPaintBrand && selectedPaintBrand}
                                    selectedPaintFinish={selectedPaintFinish && selectedPaintFinish}
                                    paintFinishes={paintFinishes && paintFinishes}
                                    setSelectedPaintFinish={(v) => setSelectedPaintFinish(v)}
                                    appendChanged={(a, b) => appendChanged(a, b)}
                                  />
                                </>
                              );
                            }
                          })}
                        {exteriorPaintUserChoicePIPValue &&
                          index === 2 &&
                          exteriorPaintUserChoicePIPValue === 'Manual' &&
                          manualPips &&
                          manualPips.map((pip) => {
                            return (
                              <>
                                <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '2%' }}>
                                  <Typography variant="h6">{pip.displayName}</Typography>
                                </Grid>
                                <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '3%' }}>
                                  <Fields
                                    placeholder="Enter name"
                                    value={
                                      myArray && myArray.find((d) => d.PIPScheme === pip._id)
                                        ? myArray && myArray.find((d) => d.PIPScheme === pip._id).value
                                        : projectPIP &&
                                          projectPIP.find((d) => d.PIPScheme.name === pip.name) &&
                                          projectPIP.find((d) => d.PIPScheme.name === pip.name).value
                                    }
                                    onChange={(e) => {
                                      e.preventDefault();
                                      appendChanged(pip._id, e.target.value);
                                    }}
                                    onClick={(e) => e.target.select()}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                              </>
                            );
                          })}
                      </>
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          {/* interior implementation */}
          <InteriorPaints
            data={data && data}
            interiorPaintBrands={interiorPaintBrands && interiorPaintBrands}
            interiorPaintFinish={interiorPaintBrands && interiorPaintBrands}
            interiorPaintNames={interiorPaintBrands && interiorPaintBrands}
            appendChanged={(a, b) => appendChanged(a, b)}
            projectPIP={projectPIP && projectPIP}
            myArray={myArray && myArray}
            interiorPaintUserChoicePIP={interiorPaintUserChoicePIP && interiorPaintUserChoicePIP}
            interiorPaintUserChoicePIPValue={interiorPaintUserChoicePIPValue && interiorPaintUserChoicePIPValue}
            setInteriorPaintUserChoicePIP={setInteriorPaintUserChoicePIP}
            setInteriorPaintUserChoicePIPValue={setInteriorPaintUserChoicePIPValue}
            manualPips={manualPips && manualPips}
          />

          <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
            <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
              <Typography variant="h5">{typeOfConcretePIP && typeOfConcretePIP.displayName}</Typography>
            </Grid>
            <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="exterior paint"
                  name="paint schemes"
                  value={
                    myArray &&
                    typeOfConcretePIP &&
                    myArray.length !== 0 &&
                    myArray.find((d) => d.PIPScheme === typeOfConcretePIP._id) &&
                    myArray.find((d) => d.PIPScheme === typeOfConcretePIP._id).value
                  }
                  onChange={(e) => {
                    appendChanged(typeOfConcretePIP && typeOfConcretePIP._id, e.target.value);
                  }}
                >
                  {typeOfConcretePIP.options.map((option, index) => {
                    return <FormControlLabel key={index} value={option} control={<GreenRadio />} label={option} />;
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <IndoorFlooring
            data={indoorFlooringPips && indoorFlooringPips}
            appendChanged={(a, b) => appendChanged(a, b)}
            myArray={myArray && myArray}
            projectPIP={projectPIP && projectPIP}
          />
        </>
      ) : (
        skeleton &&
        skeleton.map((d, index) => {
          return <Skeletons index={index} />;
        })
      )}

      <Grid container direction="row" alignItems="flex-end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '6%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};

export default MaterialDetails;
