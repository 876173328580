import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const SPVVillaProjectInputs = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel },
    schema,
  } = useInterventionDetailsContext();

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.spvVilla_availableVillaRooftopArea)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.spvVilla_availableVillaRooftopArea)} {getQuantityUnit(schema.spvVilla_availableVillaRooftopArea)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};

export default SPVVillaProjectInputs;
