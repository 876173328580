import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const HWMSwimmingPoolCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const hwmhpType = getQuantityValue(schema.hwmSwimmingPool__pi_hpType);
  const hwmhpheating = getQuantityValue(schema.hwmSwimmingPool__pi_hpHeatingSpeed);

  const handleHpType = (type) => {
    updateQuantityValue(schema.hwmSwimmingPool__pi_hpType, type);
  };

  const handleHpTemp = (temp) => {
    updateQuantityValue(schema.hwmSwimmingPool__pi_hpHeatingSpeed, temp);
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid item xs={4} container alignItems="flex-start" direction="column">
        <Grid xs>
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.hwmSwimmingPool__pi_userHpCapacity)}</Typography>
        </Grid>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.hwmSwimmingPool__pi_userHpCapacity)}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.hwmSwimmingPool__pi_userHpCapacity, Number(e.target.value));
              }}
              fullWidth
              required
              onClick={() => updateQuantityValue(schema.hwmSwimmingPool__pi_userHpCapacity, getQuantityDefaultValue(schema.hwmSwimmingPool__pi_userHpCapacity))}
              disabled={false}
            />
          </Grid>
          <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
            <Typography variant="h6">{getQuantityUnit(schema.hwmSwimmingPool__pi_userHpCapacity)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8} container alignItems="flex-start" style={{ maxWidth: '58%' }}>
        <Grid item xs={6}>
          <Typography className={classes.textheader2}>Type</Typography>
          <Typography className={classes.textHeader3}>
            {hwmhpType === 'swimming pool' ? (
              <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('swimming pool')} />
            )}
            Swimming Pool
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.textheader2} style={{ paddingRight: '50px' }}>
            Heating
          </Typography>
          <Typography className={classes.textHeader3}>
            {hwmhpheating === 'slow' ? (
              <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('slow')} />
            )}
            Slow Heating
          </Typography>
          <Typography className={classes.textHeader3}>
            {hwmhpheating === 'fast' ? (
              <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('fast')} />
            )}
            Fast Heating
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row">
        <Grid item xs={6}>
          <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%', justifyContent: 'center' }}>
            <Grid item xs={7}>
              <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmSwimmingPool_individualProductCapacity)}</Typography>
              <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>{`${numberWithCommas(
                Number(getQuantityValue(schema.hwmSwimmingPool_individualProductCapacity))
              )} ${getQuantityUnit(schema.hwmSwimmingPool_individualProductCapacity)}`}</Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmSwimmingPool_numberOfProducts)}</Typography>
              <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>{`${getQuantityValue(
                schema.hwmSwimmingPool_numberOfProducts
              )} ${getQuantityUnit(schema.hwmSwimmingPool_numberOfProducts)}`}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
            <Grid item xs={6}>
              <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmSwimmingPool_areaRequired)}</Typography>
              <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>{`${numberWithCommas(
                Number(getQuantityValue(schema.hwmSwimmingPool_areaRequired))
              )} ${getQuantityUnit(schema.hwmSwimmingPool_areaRequired)}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HWMSwimmingPoolCustomBasicParameters;
