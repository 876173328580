import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Grid, Typography } from '@material-ui/core';
import CustomCheckBox from '../../../../common/checkbox/CustomCheckBox';
import CategoryRow from './categoryRow';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import FlowDiagrams from '../flow-diagram/FlowDiagrams';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.571429px',
    textTransform: 'uppercase',
  },
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',

    lineHeight: '22px',
    letterSpacing: '0.571429px',
    color: '#000000',
    fontWeight: '600',
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    maxWidth: '100%',
    border: '1px solid rgba(0, 0, 0, 0.14)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    // boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
  },
  container: {
    width: '100%',
    marginBottom: '3%',
  },
  checkboxlabel: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
    // position: 'static',
  },
  linkfont: {
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    color: '#00C58A',
  },
  flowDiagram: {
    textDecoration: 'underline',
    color: '#00C58A',
    cursor: 'pointer',
  },
  paper: {
    position: 'absolute',
    // width: '550px',
    outline: 'none',
    height: 'auto',
    // maxHeight: '800',
    width: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 2),
  },
  close: {
    marginLeft: '95%',
  },
}));

export default function CategoryTable(props) {
  const classes = useStyles();
  const { data, handleRefresh, projectID, handleEditCustomIntervention, handleEditCustominterventionID, handleOpenBackdrop } = props;

  const name = data?.name;
  const impactcolumn1 = data ? `${data?.name?.toUpperCase()} SAVINGS` : 'SAVINGS';
  const impactcolumn2 = '(ANNUAL)';

  const [modalStyle] = React.useState(getModalStyle);
  const [openFlowDiagram, setOpenFlowDiagram] = React.useState(false);
  const [checkState, setCheckState] = useState(true);

  const handleChange = () => setCheckState(!checkState);

  // const sortedData = tableData;
  // if (name === 'Energy') {
  //   sortedData = tableData && tableData;
  // }
  // if (name === 'Water') {
  //   sortedData = tableData && tableData.sort((a, b) => (a.name === 'Demand Optimization' ? -1 : 0));
  // }
  // if (name === 'Waste') {
  //   sortedData = tableData && tableData.sort((a, b) => (a.name === 'Reduction' ? -1 : 0));
  // }
  // if (name === 'Materials') {
  //   sortedData = tableData && tableData.sort((a, b) => (a.name === 'Reduction' ? -1 : 0));
  // }
  // const customInterventionsIndex = sortedData && sortedData?.findIndex((item) => item.name === 'Custom Interventions');
  // if (customInterventionsIndex !== -1) {
  //   const customInterventions = sortedData && sortedData?.splice(customInterventionsIndex, 1)[0];
  //   sortedData && sortedData?.push(customInterventions);
  // }
  const tableData = data?.subcategories;
  const cols = ['Category', 'IGBC Points', 'CAPEX (ONE-TIME)', 'OPEX Savings (Annual)', 'Resource Impact'];
  return (
    <>
      <>
        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12}>
          <Grid container item xs={4} data-tour="diagram">
            <Typography className={classes.flowDiagram} onClick={() => setOpenFlowDiagram(true)}>
              SHOW FLOW DIAGRAM
            </Typography>
          </Grid>
          <Grid container item xs={6} justify="flex-end" alignItems="flex-end">
            <CustomCheckBox
              checkState={checkState}
              handleChange={handleChange}
              label={<Typography className={classes.checkboxlabel}>Show CAPEX (Rs)</Typography>}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.container} justify="center" alignItems="center" item xs={12} data-tour="table">
          <TableContainer component={Paper} className={classes.table}>
            <Table>
              <colgroup>
                <col style={{ width: '33.34%' }} />
              </colgroup>
              {/* table head */}
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <Typography variant="body1">{cols[0]}</Typography>
                  </StyledTableCell>

                  <StyledTableCell>
                    <Typography variant="body1">Capex Increase</Typography>{' '}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1"> Total Capex</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">{cols[3].split('(')[0]}</Typography>
                    <Typography variant="body1">{'(' + cols[3].split('(')[1]}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="body1">{impactcolumn1}</Typography>
                    <Typography variant="body1">{data?.name === 'Materials' ? '(One-Time)' : impactcolumn2}</Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {/* table body */}
              <TableBody>
                {tableData &&
                  tableData?.map((row, index) => {
                    return (
                      <>
                        <CategoryRow
                          key={index}
                          row={row}
                          StyledTableCell={StyledTableCell}
                          checkState={checkState}
                          handleRefresh={() => handleRefresh()}
                          projectID={projectID}
                          category={name}
                          state={checkState}
                          //   approval={props.approval}
                          data={name}
                          name={row?.name}
                          handleEditCustomIntervention={(value) => handleEditCustomIntervention(value)}
                          handleEditCustominterventionID={(value) => handleEditCustominterventionID(value)}
                          handleOpenBackdrop={(v) => handleOpenBackdrop(v)}
                        />
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Modal
          open={openFlowDiagram}
          onClose={() => setOpenFlowDiagram(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <CloseIcon className={classes.close} onClick={() => setOpenFlowDiagram(false)} />
            <FlowDiagrams data={data} />
          </div>
        </Modal>
      </>
    </>
  );
}
