import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
const SwmCustomAdvanceParameters = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel, getQuantityDefaultValue },
    set: { updateQuantityValue },
    schema,
  } = useInterventionDetailsContext();
  const customPrice = getQuantityValue(schema.swm__pi_customCapex);
  const capexMultiplierSource = getQuantityValue(schema.swm__pi_capexSourceMultiplier);
  const userTankerPrice = getQuantityValue(schema.swm__pi_waterTankerPricePerKL);
  const inletPerStudioApartment = getQuantityValue(schema.swm__pi_userInletsPerStudio);
  const inletPerOneBHK = getQuantityValue(schema.swm__pi_userInletsPerOneBHK);
  const inletPerTwoBHK = getQuantityValue(schema.swm__pi_userInletsPerTwoBHK);
  const inletPerThreeBHK = getQuantityValue(schema.swm__pi_userInletsPerThreeBHK);
  const inletPerFourBHK = getQuantityValue(schema.swm__pi_userInletsPerFourBHK);
  console.log(capexMultiplierSource, 'source');
  return (
    <>
      <Grid container item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid xs={4}>
          <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Intervention Cost Assumptions</Typography>
            <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
          </Grid>
          <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
            {'Capex (Rs/sensor)'}
          </Typography>
        </Grid>
        <Grid xs={8} style={{ display: 'flex', flexDirection: 'row' }}>
          <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              {(capexMultiplierSource === 'CUSTOM' || capexMultiplierSource === null) && (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.swm__pi_capexSourceMultiplier, 'VENDOR');
                  }}
                />
              )}
              {capexMultiplierSource === 'VENDOR' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.swm__pi_capexSourceMultiplier, '');
                  }}
                />
              )}
              <Typography>Selected Vendor</Typography>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center', marginLeft: '15%' }}>
              <Typography>{getQuantityValue(schema.swm_vendorCapex)}</Typography>
              <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
            </Grid>
          </Grid>
          <Grid xs={8} style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              {(capexMultiplierSource === 'VENDOR' || capexMultiplierSource === null) && (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.swm__pi_capexSourceMultiplier, 'CUSTOM');
                  }}
                />
              )}
              {capexMultiplierSource === 'CUSTOM' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.swm__pi_capexSourceMultiplier, '');
                  }}
                />
              )}
              <Typography>Custom</Typography>
            </Grid>
            <Grid style={{ display: 'flex', alignItems: 'center' }}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={customPrice}
                placeholder=""
                fullWidth
                required
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.swm__pi_customCapex, +e.target.value);
                }}
                disabled={capexMultiplierSource !== 'CUSTOM' ? true : false}
                disableTextField={capexMultiplierSource !== 'CUSTOM' ? true : false}
                removeResetIcon={true}
              />
              <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={4} style={{ marginBottom: '2%' }}>
          <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
            {'Opex Costs (AMC + Charges)'}
          </Typography>
          <Typography>{`${getQuantityValue(schema.swm_opexCosts)}  Rs`}</Typography>
        </Grid>
        <Grid xs={4}>
          <Grid xs={12} style={{ marginTop: '4%', display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Project Cost Assumptions</Typography>
            <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
          </Grid>
          <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
            {'Water Tanker Price per KL'}
          </Typography>
          <Grid xs={8} style={{ display: 'flex' }}>
            <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={userTankerPrice}
                placeholder=""
                fullWidth
                required
                onChange={(e) => {
                  updateQuantityValue(schema.swm__pi_waterTankerPricePerKL, +e.target.value);
                }}
                onClick={(e) => {
                  updateQuantityValue(schema.swm__pi_waterTankerPricePerKL, getQuantityDefaultValue(schema.swm__pi_waterTankerPricePerKL));
                }}
              />
              <Typography style={{ marginLeft: '20%' }}>Rs</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid xs={4} style={{ marginTop: '4%', display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Constants</Typography>
            <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
          </Grid>
          <Grid xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
            <Grid xs={8}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                {'Inlet per Studio Apartment'}
              </Typography>
              <Grid xs={12} style={{ display: 'flex', width: '150px' }}>
                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={inletPerStudioApartment}
                    placeholder=""
                    fullWidth
                    required
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.swm__pi_userInletsPerStudio, +e.target.value);
                    }}
                    onClick={(e) => {
                      updateQuantityValue(schema.swm__pi_userInletsPerStudio, getQuantityDefaultValue(schema.swm__pi_userInletsPerStudio));
                    }}
                  />
                  <Typography style={{ marginLeft: '5%' }}>nos</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} style={{ marginLeft: '5%' }}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                {'Inlet per 1 BHK Apartment'}
              </Typography>
              <Grid xs={12} style={{ display: 'flex', width: '200px' }}>
                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={inletPerOneBHK}
                    placeholder=""
                    fullWidth
                    required
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.swm__pi_userInletsPerOneBHK, +e.target.value);
                    }}
                    onClick={(e) => {
                      updateQuantityValue(schema.swm__pi_userInletsPerOneBHK, getQuantityDefaultValue(schema.swm__pi_userInletsPerOneBHK));
                    }}
                  />
                  <Typography style={{ marginLeft: '5%' }}>nos</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} style={{ marginLeft: '5%' }}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                {'Inlet per 2 BHK Apartment'}
              </Typography>
              <Grid xs={12} style={{ display: 'flex', width: '200px' }}>
                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={inletPerTwoBHK}
                    placeholder=""
                    fullWidth
                    required
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.swm__pi_userInletsPerTwoBHK, +e.target.value);
                    }}
                    onClick={(e) => {
                      updateQuantityValue(schema.swm__pi_userInletsPerTwoBHK, getQuantityDefaultValue(schema.swm__pi_userInletsPerTwoBHK));
                    }}
                  />
                  <Typography style={{ marginLeft: '5%' }}>nos</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid xs={12} style={{ display: 'flex', flexDirection: 'row', marginTop: '3%' }}>
            <Grid xs={8}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                {'Inlet per 3 BHK Apartment'}
              </Typography>
              <Grid xs={12} style={{ display: 'flex', width: '150px' }}>
                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={inletPerThreeBHK}
                    placeholder=""
                    fullWidth
                    required
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.swm__pi_userInletsPerThreeBHK, +e.target.value);
                    }}
                    onClick={(e) => {
                      updateQuantityValue(schema.swm__pi_userInletsPerThreeBHK, getQuantityDefaultValue(schema.swm__pi_userInletsPerThreeBHK));
                    }}
                  />
                  <Typography style={{ marginLeft: '5%' }}>nos</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} style={{ marginLeft: '5%' }}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
                {'Inlets per 4 BHK Apartment'}
              </Typography>
              <Grid xs={12} style={{ display: 'flex', width: '200px' }}>
                <Grid style={{ display: 'flex', alignItems: 'center', marginTop: '2%' }}>
                  <ResetFields
                    id="outlined-basic"
                    variant="outlined"
                    value={inletPerFourBHK}
                    placeholder=""
                    fullWidth
                    required
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.swm__pi_userInletsPerFourBHK, +e.target.value);
                    }}
                    onClick={(e) => {
                      updateQuantityValue(schema.swm__pi_userInletsPerFourBHK, getQuantityDefaultValue(schema.swm__pi_userInletsPerFourBHK));
                    }}
                  />
                  <Typography style={{ marginLeft: '5%' }}>nos</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={8} style={{ marginLeft: '5%' }}>
              <Typography color="textSecondary" variant="h6" style={{ marginTop: '7%' }}>
                {'CO2e Emission Factor'}
              </Typography>
              <Grid xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '3%' }}>
                <Typography>{getQuantityValue(schema.swm_carbon) + '          ' + `kg CO2e/kWh`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default SwmCustomAdvanceParameters;
