import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const SPVClubhouseProjectInputs = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid item xs={3}>
        <Typography className={classes.textHeader5}>{getQuantityLabel(schema.spvClubhouse_availableClubhouseRooftopArea)}</Typography>
        <Typography className={classes.textHeader3}>
          {getQuantityValue(schema.spvClubhouse_availableClubhouseRooftopArea)} {getQuantityUnit(schema.spvClubhouse_availableClubhouseRooftopArea)}
        </Typography>
      </Grid>

      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};

export default SPVClubhouseProjectInputs;
