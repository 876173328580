import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SelectedInterv from '../child-components/SelectedIntervention';
import InterventionsComponent from '../child-components/interventionsComponent';

const InterventionsAndSelectedInterventions = (props) => {
  return (
    <>
      <Grid container xs={12}>
        <Grid container item xs={12} sm={12} md={6} lg={6} style={{ paddingRight: 10 }}>
          <Typography variant="h5" style={{ letterSpacing: '1px' }}>
            Interventions
          </Typography>
          <InterventionsComponent intervention={props.intervention} />
        </Grid>
        <Grid container item xs={12} sm={12} md={6} lg={6} style={{ paddingLeft: 10 }}>
          <Typography variant="h5" style={{ letterSpacing: '1px' }}>
            Resource Savings
          </Typography>
          <SelectedInterv intervention={props.intervention} />
        </Grid>
      </Grid>
    </>
  );
};

export default InterventionsAndSelectedInterventions;
