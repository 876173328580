import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Grid, Typography } from '@material-ui/core';
import CurrentProjectCard from './CurrentProjectCard';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import PrimaryButton from '../../../common/button/Button';
import currentProjecticon from '../../../../assets/project-image.svg';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: '3%',
  },
  span: {
    marginLeft: '240px',
    fontWeight: '850',
    fontSize: '12px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    textDecoration: 'underline',
    color: 'rgba(0, 219, 153, 0.82)',
    cursor: 'pointer',
  },
  paper: {
    width: '50%',
    marginLeft: '5%',
    backgroundColor: '#fffff',
  },
}));

const CurrentProjectComponent = (props) => {
  const classes = useStyles();
  const direction = props.width || window.innerWidth > 1279 ? 'column' : 'row';
  return (
    <>
      <Grid item xs={props.width || window.innerWidth > 1279 ? 3 : 6}>
        <div style={{ position: props.width || window.innerWidth > 1279 ? 'fixed' : '', marginTop: '4%' }}>
          <Grid item container direction={direction} xs={12}>
            <Grid item container>
              <Typography style={{ fontWeight: 'bold' }} variant="h5">
                Current Project
              </Typography>

              <Typography
                variant="h6"
                onClick={props.handleEditCurrentProject}
                style={{
                  paddingTop: '2%',
                  marginLeft: '38%',
                  background: 'transparent',
                  color: '#66E3BE',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                <b>Edit Details</b>
              </Typography>
              <Grid item xs={12} className={classes.spacing}></Grid>
              {props.currentproject && props.currentproject ? (
                <CurrentProjectCard
                  name={props.currentproject.name}
                  category={props.currentproject.category}
                  rooftop={
                    props.currentproject?.pips &&
                    props.currentproject?.pips.length !== 0 &&
                    props.currentproject?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_rooftopArea')
                      ? props.currentproject?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_rooftopArea').value
                      : 0
                  }
                  builtup={
                    props.currentproject?.pips &&
                    props.currentproject?.pips.length !== 0 &&
                    props.currentproject?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_builtUpArea')
                      ? props.currentproject?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_builtUpArea').value
                      : 0
                  }
                  location={props.currentproject.location?.name}
                  unit1={
                    props.currentproject?.pips &&
                    props.currentproject?.pips.length !== 0 &&
                    props.currentproject?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_rooftopArea')
                      ? props.currentproject?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_rooftopArea').pipScheme.unit
                      : ''
                  }
                  unit2={
                    props.currentproject?.pips &&
                    props.currentproject?.pips.length !== 0 &&
                    props.currentproject?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_builtUpArea')
                      ? props.currentproject?.pips.find((d) => d.pipScheme && d.pipScheme.name === 'project__pi_builtUpArea').pipScheme.unit
                      : ''
                  }
                />
              ) : props.currentproject === null ? (
                <Typography variant="h5">Please select or create a new Project to continue</Typography>
              ) : (
                <Grid item xs={3}>
                  <CasaSkeleton variant="rect" height="40vh" width="32rem" animation="wave" />
                </Grid>
              )}
            </Grid>
            <Grid align="right" item xs={9} style={{ marginLeft: props.width || window.innerWidth > 1279 ? '-5%' : '23%', paddingTop: '4%' }}>
              <PrimaryButton onClick={props.handleInsiteButton} children="View Insights" style={{ width: '150px', borderRadius: '40px' }} />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default CurrentProjectComponent;
