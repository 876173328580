import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
export const NaturalStpBasicParameters = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel },
    schema,
  } = useInterventionDetailsContext();
  return (
    <Grid item xs={12} container alignItems="flex-start" direction="row">
      <div style={{ display: 'flex' }}>
        <>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.nstp_stpCapacity)}</Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '2%' }}>
              <Typography>{getQuantityValue(schema.nstp_stpCapacity)}</Typography>
              <Typography style={{ marginLeft: '5%' }}>{getQuantityUnit(schema.nstp_stpCapacity)}</Typography>
            </div>
          </Grid>
        </>
      </div>
    </Grid>
  );
};

export default NaturalStpBasicParameters;
