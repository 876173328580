import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, GridList } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { ProjectContext } from '../../../routing/IndexRouting';
import PrimaryButton from '../../common/button/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import sidebaricon2 from '../../../assets/sidebaricon2.svg';
import { useHistory } from 'react-router-dom';
import cookie from 'react-cookies';
import axios from '../../../config/Axios';
import CommonAllProjectsCard from './myprojects/CommonAllProjectsCard';
import appartment from '../../../assets/appartment.svg';
import CasaSkeleton from '../../common/skeleton/CasaSkeleton';
import { UserContext } from '../../../routing/IndexRouting';

export const AllProjectContext = React.createContext();

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '650px',
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  modalhead: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
  singemodalpara: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '28px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
  linkfont: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#00C58A',
  },
  close: {
    marginLeft: '95%',
  },
  text: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',

    color: 'rgba(0, 0, 0, 0.6)',
  },
  root: {
    '&:focus': {
      border: ' 2px solid rgba(0, 197, 138, 0.5)',
    },
    '&$selected': {},
  },
}));

const SwitchProjectModal = (props) => {
  const { handleProjectData } = useContext(ProjectContext);

  const token = cookie.load('SDPLUS_TOKEN');
  let history = useHistory();

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [projects, setProjects] = useState('');
  const [allprojectData, handleAllProjectData] = useState(null);
  const [myProjectData, setMyProjectData] = useState('');
  const { userDetails } = useContext(UserContext);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLink = (e) => history.push('/projects');
  const [currentProfile, setCurrentProfile] = useState(null);

  useEffect(() => {
    if (userDetails && userDetails._id && token) {
      axios
        .post(
          '/graphql',
          {
            query: `query ExampleQuery {
              getProjectsbyUserID(
                id: "${userDetails._id}"
                filter: {
                  goalFilter: { category: [] }
                  interventionFilter: { category: [] }
                  pipNames: { pips: ["project__pi_rooftopArea", "project__pi_builtUpArea"] }
                }
                pagination: { skip: 0, limit: 100 }
              ) {
                currentProject {
                  location {
                    name
                    type
                    coordinates
                  }
                  category
                  pips {
                    pipScheme {
                      name
                      unit
                    }
                    value
                  }
                  _id
                  name
                }
                otherProjects {
                  name
                  location {
                    name
                    type
                    coordinates
                  }
                  category
                  pips {
                    pipScheme {
                      name
                      unit
                    }
                    value
                  }
                  _id
                }
              }
            }`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            setMyProjectData(res.data.data.getProjectsbyUserID);
          }
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    }
  }, [token, userDetails]);

  useEffect(() => {
    if (myProjectData) {
      axios
        .get(`/userprofiles`)
        .then((response) => {
          console.log(response, 'response');
          setProjects(myProjectData.otherProjects);
          setCurrentProfile(response.data.data.data);
        })
        .catch((err) => {
          console.log(err, 'error');
        });
    }
  }, [myProjectData]);

  console.log(projects && projects, 'projects');
  const body1 = (
    <div style={modalStyle} className={classes.paper}>
      <Typography className={classes.modalhead}>Switch Project</Typography>
      {/* all projects card */}
      <Grid container xs={12}>
        {projects ? (
          projects.slice(-4).map((project, index) => {
            return (
              <>
                <GridList className={classes.gridList}>
                  <CommonAllProjectsCard
                    index={index}
                    project={project}
                    appartment={appartment}
                    handleProjectData={handleProjectData}
                    currentprojects={null}
                    history={history}
                    userDetails={userDetails && userDetails}
                    // currentProfile={null}
                    currentProfile={currentProfile !== null && currentProfile}
                  />
                </GridList>
                <br />
              </>
            );
          })
        ) : (
          <>
            <Grid item xs={12}>
              <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
            </Grid>
            <br />
            <br />

            <Grid item xs={12}>
              <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
            </Grid>
            <br />
            <br />

            <Grid item xs={12}>
              <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
            </Grid>
            <br />
            <br />
          </>
        )}
      </Grid>
      <Link onClick={handleLink} to="#" exact={true} style={{ color: 'rgba(0, 219, 153, 0.82)' }}>
        <Typography className={classes.linkfont} position="flexend">
          View All Projects
        </Typography>
      </Link>
    </div>
  );
  const body2 = (
    <div style={modalStyle} className={classes.paper}>
      <CloseIcon className={classes.close} onClick={handleClose} />
      <Typography className={classes.modalhead}>Switch Project</Typography>
      <Typography className={classes.singemodalpara}>
        Hey, you don’t seem to have another
        <br /> project to switch to. If you want to <br />
        work on a different project, <br />
        you can create a<br /> New Project.
      </Typography>
      <PrimaryButton
        type="button"
        className={classes.button}
        align="center"
        style={{
          background: 'linear-gradient(90deg, #1FDEDB 0.58%, #42E198 100%)',
          borderRadius: '1.25rem',
          color: 'white',
          width: '40%',
        }}
        // onclick={handleClick}
      >
        create new project
      </PrimaryButton>
    </div>
  );

  return (
    <div>
      <AllProjectContext.Provider value={{ allprojectData, handleAllProjectData }}>
        <Link
          className="button"
          style={{
            background: 'transparent',
            color: 'white',
            width: '30%',
          }}
          to="#"
        >
          <img src={sidebaricon2} alt="..." onClick={handleOpen} title="Switch Project" />
        </Link>
        <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
          {projects.length === 1 ? body2 : body1}
        </Modal>
      </AllProjectContext.Provider>
    </div>
  );
};
export default SwitchProjectModal;
