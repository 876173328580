import axios from 'axios';
import { useEffect, useState } from 'react';

const useAppApiRequest = (url, opts = { method: 'GET', headers: {}, params: {}, data: {} }, deps = [], enabled = true) => {
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);

  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchData = async () => {
    if (!enabled) return;
    const request = {
      ...opts,
      url,
    };
    try {
      setIsFetching(true);
      const response = await axios(request);
      setResponseData(response);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      setIsError(true);
      setError(err);
    }
  };

  const reset = () => {
    setIsFetching(false);
    setIsError(false);
    setError(null);
    setResponseData(null);
  };

  const refetch = async () => {
    fetchData();
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
    return () => reset();
  }, [url, ...deps]);

  return {
    isFetching,
    isError,
    error,
    data: responseData,
    refetch,
  };
};

export { useAppApiRequest };
