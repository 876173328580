import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const TwrCustomPipParameters = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel, getQuantityDefaultValue },
    set: { updateQuantityValue },
    schema,
  } = useInterventionDetailsContext();
  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.twr_studio)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.twr_studio) || 0} {getQuantityUnit(schema.twr_studio)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.twr_oneBHK)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.twr_oneBHK) || 0} {getQuantityUnit(schema.twr_oneBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.twr_twoBHK)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.twr_twoBHK) || 0} {getQuantityUnit(schema.twr_twoBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.twr_threeBHK)}</Typography>
          <Typography className={classes.textHeader3}>
            {' '}
            {getQuantityValue(schema.twr_threeBHK) || 0} {getQuantityUnit(schema.twr_threeBHK)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.twr_fourBHK)}</Typography>
          <Typography className={classes.textHeader3}>
            {' '}
            {getQuantityValue(schema.twr_fourBHK) || 0} {getQuantityUnit(schema.twr_fourBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.twr_penthouseOneBHK)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.twr_penthouseOneBHK) || 0} {getQuantityUnit(schema.twr_penthouseOneBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.twr_penthouseTwoBHK)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.twr_penthouseTwoBHK) || 0} {getQuantityUnit(schema.twr_penthouseTwoBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.twr_penthouseThreeBHK)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.twr_penthouseThreeBHK) || 0} {getQuantityUnit(schema.twr_penthouseThreeBHK)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.twr_penthouseFourBHK)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.twr_penthouseFourBHK) || 0} {getQuantityUnit(schema.twr_penthouseFourBHK)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TwrCustomPipParameters;
