import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
// import { Link } from 'react-router-dom';
// import ShowSaveAndCancel from './showSaveAndCancel';
// import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
// import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
// import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
// import { useInterventionDetailsContext } from '../../context/InterventionDetails';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const HWMApartmentProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue },
    schema,
  } = useInterventionDetailsContext();

  const onebhk = getQuantityValue(schema.hwmApartment_oneBHK);
  const twobhk = getQuantityValue(schema.hwmApartment_twoBHK);
  const threebhk = getQuantityValue(schema.hwmApartment_threeBHK);
  const fourbhk = getQuantityValue(schema.hwmApartment_fourBHK);
  const studio = getQuantityValue(schema.hwmApartment_studio);
  const rooftoparea = getQuantityValue(schema.hwmApartment_rooftopArea);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Rooftop Area</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(rooftoparea)} sqm</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Studio</Typography>
          <Typography className={classes.textHeader3}>{studio} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>1 BHK</Typography>
          <Typography className={classes.textHeader3}>{onebhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>2 BHK</Typography>
          <Typography className={classes.textHeader3}>{twobhk} nos</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>3 BHK</Typography>
          <Typography className={classes.textHeader3}>{threebhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>4 BHK</Typography>
          <Typography className={classes.textHeader3}>{fourbhk} nos</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HWMApartmentProjectInputs;
