import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const HWMPentHouseProjectInputs = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue },
    schema,
  } = useInterventionDetailsContext();

  const onebhk = getQuantityValue(schema.hwmPenthouse_penthouseOneBHK);
  const twobhk = getQuantityValue(schema.hwmPenthouse_penthouseTwoBHK);
  const threebhk = getQuantityValue(schema.hwmPenthouse_penthouseThreeBHK);
  const fourbhk = getQuantityValue(schema.hwmPenthouse_penthouseFourBHK);
  const rooftopareaavailable = getQuantityValue(schema.hwmPenthouse_availablePenthouseRooftopArea);
  const floorheight = getQuantityValue(schema.hwmPenthouse_floorToFloorHeight);
  const studio = getQuantityValue(schema.hwmPenthouse_penthouseStudio);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>PH Studio</Typography>
          <Typography className={classes.textHeader3}>{studio} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
          <Typography className={classes.textHeader3}>{onebhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
          <Typography className={classes.textHeader3}>{twobhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
          <Typography className={classes.textHeader3}>{threebhk} nos</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
          <Typography className={classes.textHeader3}>{fourbhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Available Penthouse Rooftop</Typography>
          <Typography className={classes.textHeader3}>{rooftopareaavailable} Sqm</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Floor Height</Typography>
          <Typography className={classes.textHeader3}>{floorheight} m</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HWMPentHouseProjectInputs;
