import React, { useState, useEffect } from 'react';
import { Grid, Typography, Checkbox } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { GreenRadio } from '../../../../common/RadioButton/GreenRadio';
import ComplexPIPchildren from './ComplexPIPChildren';
import { CAPEXPIPsForWWB } from '../EditProjectCommon';

const penthHousePIPName = 'project__pi_penthouseEnabled';

function ComplexPIPS(props) {
  const { index, field, unit, myArray, projectPIP, appendChanged, type, PIPs, subcategory } = props;
  const [radioButtonValue, setRadioButtonValue] = useState('');
  const [capexPips, setCapexPips] = useState(PIPs.filter((d) => d.name === CAPEXPIPsForWWB.OrganicComposter || d.name === CAPEXPIPsForWWB.Composting));

  const handleRadioButtonChange = (event) => setRadioButtonValue(event.target.value);

  useEffect(() => {
    if (radioButtonValue && field) appendChanged(field._id, radioButtonValue);
  }, [radioButtonValue, field]);

  useEffect(() => {
    if (myArray && projectPIP && field) {
      setRadioButtonValue(
        myArray && myArray.find((s) => s.PIPScheme === field?._id)
          ? myArray.find((s) => s.PIPScheme === field?._id).value
          : projectPIP.find((d) => d.PIPScheme?._id === field?._id)
          ? projectPIP.find((d) => d.PIPScheme?._id === field._id).value
          : ''
      );
    }
  }, [myArray, projectPIP, field]);

  return (
    <>
      <Grid container direction="row" item xs={12} key={index} style={{ height: 'Auto', marginLeft: '2%', marginBottom: '2%' }}>
        <Grid item alignItems="left" xs={12}>
          {field?.name === penthHousePIPName && subcategory !== 'villa' ? (
            <Typography variant="h5">
              <b>Penthouse Details</b>
            </Typography>
          ) : (
            <Typography variant="h5">{field.displayName + `${type && type === 'basic' ? '*' : ''}`}</Typography>
          )}
        </Grid>
        {field.description && field.description !== 'NA' ? (
          <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
            <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
              {field.description}
            </Typography>
          </Grid>
        ) : null}
        {field?.name === penthHousePIPName && subcategory !== 'villa' && (
          <Grid container alignItems="center" justifyContent="flex-start" item xs={12} style={{ marginBottom: '1%' }}>
            <Grid item xs={1} container alignItems="center" justifyContent="flex-start">
              <Checkbox
                checked={
                  myArray &&
                  myArray.length !== 0 &&
                  myArray.find((d) => d.PIPScheme === field._id) &&
                  myArray.find((d) => d.PIPScheme === field._id).value === true
                    ? true
                    : false
                }
                style={{ color: '#66E3BE', marginLeft: '-10%' }}
                onChange={(e) => {
                  appendChanged(field._id, e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={6} container alignItems="center" justifyContent="flex-start" alignContent="flex-start">
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', marginLeft: '-10%' }}>
                {field?.displayName}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid container direction="row" item xs={12} key={index + 1} style={{ height: 'Auto' }}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={
                myArray && myArray.find((s) => s.PIPScheme === field?._id)
                  ? myArray.find((s) => s.PIPScheme === field?._id).value
                  : projectPIP.find((d) => d.PIPScheme?._id === field?._id)
                  ? projectPIP.find((d) => d.PIPScheme?._id === field._id).value
                  : radioButtonValue
              }
              onChange={handleRadioButtonChange}
            >
              {field.options &&
                field.options.map((option, index) => {
                  if (option === 'Default') {
                    return <FormControlLabel value={option} control={<GreenRadio />} label={option} key={index} />;
                  } else if (option === 'Organic Waste Composter') {
                    return (
                      <>
                        <FormControlLabel value={option} control={<GreenRadio />} label={option} key={index} />
                        {option === 'Organic Waste Composter' &&
                          radioButtonValue === 'Organic Waste Composter' &&
                          capexPips &&
                          capexPips.map((c, i) => {
                            if (c.name === CAPEXPIPsForWWB.OrganicComposter)
                              return (
                                <ComplexPIPchildren
                                  index={i}
                                  unit="NA"
                                  field={c && c}
                                  myArray={myArray && myArray}
                                  projectPIP={projectPIP && projectPIP}
                                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                                  type={type}
                                />
                              );
                          })}
                      </>
                    );
                  } else if (option === 'Composting') {
                    return (
                      <>
                        <FormControlLabel value={option} control={<GreenRadio />} label={option} key={index} />
                        {option === 'Composting' &&
                          radioButtonValue === 'Composting' &&
                          capexPips &&
                          capexPips.map((c, i) => {
                            if (c.name === CAPEXPIPsForWWB.Composting)
                              return (
                                <ComplexPIPchildren
                                  index={i}
                                  unit="NA"
                                  field={c && c}
                                  myArray={myArray && myArray}
                                  projectPIP={projectPIP && projectPIP}
                                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                                  type={type}
                                />
                              );
                          })}
                      </>
                    );
                  }
                })}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}

export default ComplexPIPS;
