export const getEnabledCount = (custom, interv) => {
  let intervCount = 0;
  let customCount = custom;
  interv
    .map((arr) => arr.length)
    .forEach((D) => {
      intervCount = intervCount + D;
    });
  return customCount + intervCount;
};

export const calculateEnabledCount = (data) => {
  const interv = data?.subcategories?.filter((interventions) => interventions?.name !== 'Custom Interventions');
  const custom = data?.subcategories?.filter((interventions) => interventions?.name === 'Custom Interventions');
  const customCount = custom[0]?.interventions?.filter((item) => item?.enabled === true)?.length;
  const intervCount = interv?.map((intervention) => intervention?.interventions?.filter((item) => item?.enabled === true));
  // console.log({ interv, customCount, intervCount }, 'dataaaa');
  return getEnabledCount(customCount, intervCount);
};

export const getTotalInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    memo += sbCategory?.interventions?.length || 0;
    return memo;
  }, 0);
};

export const getEnabledInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    sbCategory?.interventions.forEach((intervention) => {
      if (intervention?.quantities?.enabled?.value === true) {
        memo.push(intervention);
      }
    });
    return memo;
  }, []);
};

export const getEnabledAndActivatedInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    sbCategory?.interventions.forEach((intervention) => {
      if (intervention?.quantities?.enabled?.value === true && intervention?.quantities?.activated?.value === true) {
        memo.push(intervention);
      }
    });
    return memo;
  }, []);
};

export const getActivatedInterventionsInCategory = (data = []) => {
  return data?.subcategories?.reduce((memo, sbCategory) => {
    sbCategory?.interventions.forEach((intervention) => {
      if (intervention?.quantities?.activated?.value === true) {
        memo.push(intervention);
      }
    });
    return memo;
  }, []);
};

export function capitalizeFilterValues(products = [], keyName) {
  if (Array.isArray(products)) {
    return products.reduce((memo, product) => {
      if (product?.filters && product?.filters[keyName]) {
        let cloneProduct = Object.assign({}, product);
        const updatedValues = cloneProduct.filters[keyName].map((value) => {
          return value
            .split(' ')
            .map((word) =>
              word
                .split('-')
                .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
                .join(' ')
            )
            .join(' ');
        });
        cloneProduct.filters[keyName] = updatedValues;
        memo.push(cloneProduct);
      } else {
        memo.push(product);
      }
      return memo;
    }, []);
  } else {
    console.error('The input is not an array of products');
    return products;
  }
}
