import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const WWMProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue },
    schema,
  } = useInterventionDetailsContext();

  const onebhk = getQuantityValue(schema.wwm_oneBHK);
  const twobhk = getQuantityValue(schema.wwm_twoBHK);
  const threebhk = getQuantityValue(schema.wwm_threeBHK);
  const fourbhk = getQuantityValue(schema.wwm_fourBHK);
  const studio = getQuantityValue(schema.wwm_studio);
  const phOneBHK = getQuantityValue(schema.csp_penthouseOneBHK);
  const phTwoBHK = getQuantityValue(schema.csp_penthouseTwoBHK);
  const phTreeBHK = getQuantityValue(schema.csp_penthouseThreeBHK);
  const phFourBHK = getQuantityValue(schema.csp_penthouseFourBHK);
  const subcategory = getQuantityValue(schema.wwm_subcategory);
  const penthouseEnabled = getQuantityValue(schema.wwm_penthouseEnabled);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        {subcategory === 'apartment' && (
          <Grid item xs={2}>
            <Typography className={classes.textHeader5}>Studio</Typography>
            <Typography className={classes.textHeader3}>{studio} nos</Typography>
          </Grid>
        )}
        <Grid item xs={2}>
          <Typography className={classes.textHeader5}>1 BHK</Typography>
          <Typography className={classes.textHeader3}>{onebhk} nos</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.textHeader5}>2 BHK</Typography>
          <Typography className={classes.textHeader3}>{twobhk} nos</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.textHeader5}>3 BHK</Typography>
          <Typography className={classes.textHeader3}>{threebhk} nos</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.textHeader5}>4 BHK</Typography>
          <Typography className={classes.textHeader3}>{fourbhk} nos</Typography>
        </Grid>
      </Grid>
      {penthouseEnabled && subcategory === 'apartment' && (
        <Grid container item xs={12} style={{ paddingTop: '1%' }}>
          <Grid item xs={2}>
            <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
            <Typography className={classes.textHeader3}>{phOneBHK} nos</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
            <Typography className={classes.textHeader3}>{phTwoBHK} nos</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
            <Typography className={classes.textHeader3}>{phTreeBHK} nos</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
            <Typography className={classes.textHeader3}>{phFourBHK} nos</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default WWMProjectInputs;
