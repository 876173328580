import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { GreenRadio } from '../../../common/RadioButton/GreenRadio';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: '3vh',
  },
}));

function ToogleSqmAndSqft(props) {
  const { requiredpips, radioButtonValue, handleRadioButtonChange, setUnitPIPid } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container justify="left" direction="row" item xs={12} style={{ marginBottom: '1%' }}>
        <Grid item xs={12}>
          {requiredpips &&
            requiredpips.data.map((field, index) => {
              if (field.name === 'project__pi_unitPreferenceForArea') {
                return (
                  <>
                    <Grid item xs={12} className={classes.spacing}>
                      <Typography variant="h5">{field.displayName}</Typography>
                    </Grid>
                    <Grid item xs={7} style={{ marginBottom: '1%' }}>
                      <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.38)', lineHeight: '22px', letterSpacing: '0.571429px' }}>
                        {field.description}
                      </Typography>
                    </Grid>
                    <Grid container direction="row" item xs={12} key={index} style={index === 0 ? { height: 'Auto' } : { height: 'Auto' }}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={radioButtonValue}
                          onChange={(e) => {
                            handleRadioButtonChange(e);
                            setUnitPIPid(field._id);
                          }}
                        >
                          {field.options &&
                            field.options.map((option, index) => {
                              return (
                                <>
                                  <FormControlLabel value={option} control={<GreenRadio />} label={option} />
                                </>
                              );
                            })}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </>
                );
              }
            })}
        </Grid>
      </Grid>
    </>
  );
}

export default ToogleSqmAndSqft;
