export const projectQuery = (userId) => `query ExampleQuery {
    getProjectsbyUserID(
      id: "${userId}"
      filter: {
        goalFilter: { category: [] }
        interventionFilter: { category: [] }
        pipNames: { pips: ["project__pi_rooftopArea", "project__pi_builtUpArea"] }
      }
      pagination: { skip: 0, limit: 1000 }
    ) {
      currentProject {
        location {
          name
          type
          coordinates
        }
        category
        pips {
          pipScheme {
            name
            unit
          }
          value
        }
        _id
        name
      }
      otherProjects {
        name
        location {
          name
          type
          coordinates
        }
        category
        pips {
          pipScheme {
            name
            unit
          }
          value
        }
        _id
      }
    }
  }`;
