import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import useStyles from './styles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const CSPCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid xs={4}>
        <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
            Intervention Cost Assumptions
          </Typography>
          <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
        </Grid>
        <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
          {'Capex (per kg)'}
        </Typography>
      </Grid>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Grid xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            {(getQuantityValue(schema.csp__pi_capexMultiplierSource) === 'CUSTOM' || getQuantityValue(schema.csp__pi_capexMultiplierSource) === '') && (
              <RadioButtonUncheckedIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.csp__pi_capexMultiplierSource, 'VENDOR');
                }}
              />
            )}
            {getQuantityValue(schema.csp__pi_capexMultiplierSource) === 'VENDOR' && (
              <CheckCircleIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.csp__pi_capexMultiplierSource, '');
                }}
              />
            )}
            <Typography>Selected Vendor</Typography>
          </Grid>
          <Grid style={{ display: 'flex', alignItems: 'center', marginLeft: '15%' }}>
            <Typography>{getQuantityValue(schema.csp_vendorCapex)}</Typography>
            <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
          </Grid>
        </Grid>
        <Grid xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid xs={6} style={{ display: 'flex', alignItems: 'center' }}>
            {(getQuantityValue(schema.csp__pi_capexMultiplierSource) === 'VENDOR' || getQuantityValue(schema.csp__pi_capexMultiplierSource) === '') && (
              <RadioButtonUncheckedIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.csp__pi_capexMultiplierSource, 'CUSTOM');
                }}
              />
            )}
            {getQuantityValue(schema.csp__pi_capexMultiplierSource) === 'CUSTOM' && (
              <CheckCircleIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.csp__pi_capexMultiplierSource, 'CUSTOM');
                }}
              />
            )}
            <Typography>Custom</Typography>
          </Grid>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.csp__pi_customCapex)}
              placeholder=""
              fullWidth
              required
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.csp__pi_customCapex, e.target.value);
              }}
              disabled={getQuantityValue(schema.csp__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
              disableTextField={getQuantityValue(schema.csp__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
            />
            <Typography style={{ marginLeft: '2%' }}>Rs</Typography>
          </Grid>
        </Grid>
      </div>

      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Constants
      </Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_dryWastePerKg)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_dryWastePerKg)} {getQuantityUnit(schema.csp_dryWastePerKg)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_eWastePerKg)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_eWastePerKg)} {getQuantityUnit(schema.csp_eWastePerKg)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_medicalWastePerKg)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_medicalWastePerKg)} {getQuantityUnit(schema.csp_medicalWastePerKg)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_emissionFactorForDryWaste)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_emissionFactorForDryWaste)} {getQuantityUnit(schema.csp_emissionFactorForDryWaste)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_emissionFactorForEWaste)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_emissionFactorForEWaste)} {getQuantityUnit(schema.csp_emissionFactorForEWaste)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.csp_emissionFactorForMedicalWaste)}</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.csp_emissionFactorForMedicalWaste)} {getQuantityUnit(schema.csp_emissionFactorForMedicalWaste)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CSPCustomAdvancedParameters;
