import React, { useEffect, useState, useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import useApiRequest from '../../../hooks/useApiRequest';
import Skeletons from '../../../common/skeleton/Skeletons';
import SimplePIPS from '../common/components/SimplePIPS';
import { SomethingChanged } from '../EditProject';

// make sure This component will be cleaned up into multiple components for multiple edit projects page - refer to wireframe
// create a context for sqm and sqft and make the components separate

export const EditProjectLandDetailsPIP = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let skeleton = [1, 2, 3, 4, 5];
  let { type, next, name } = props;
  const [myArray, setMyArray] = useState([]);

  const [simplePIPs, setSimplePIPs] = useState('');

  // others
  const [data, setData] = useState('');
  const [projectPIP, setProjectPIP] = useState([]);
  const [unit, setUnit] = useState('');
  const [projectData, setProjectData] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);
  const [{ status, response }, makeRequest] = useApiRequest(`/pipschemev2?category=${type}`, {
    verb: 'get',
  });

  const [mo, doRequest] = useApiRequest(`/projectv2/${id}/basics`, {
    verb: 'get',
  });

  if (status === null && type === 'land') makeRequest();
  if (mo?.status === null && type === 'land') doRequest();

  useEffect(() => {
    if (mo?.response) {
      setProjectData(mo?.response?.data?.data?.data);
    }
  }, [mo?.response]);

  useEffect(() => {
    const basicPIPs = ['project__pi_landscapeArea', 'project__pi_softsurfaceArea'];
    if (data) {
      const SimplePIPs = data.filter((field) => basicPIPs.includes(field.name));
      setSimplePIPs(SimplePIPs);
    }
  }, [data]);

  //useEffects
  useEffect(() => {
    if (projectPIP.length !== 0) {
      let arr2 = [];
      projectPIP.forEach((d) => arr2.push({ PIPScheme: d.PIPScheme._id, value: d.value }));
      if (arr2.length !== 0) arr2.forEach((element) => appendChanged(element.PIPScheme, element.value));
    }
  }, [projectPIP]);

  useEffect(() => {
    if (projectPIP.length !== 0 && myArray.length !== 0) {
      myArray.forEach((ele) => {
        let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
        if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
      });
    }
  }, [projectPIP, myArray]);

  useEffect(() => {
    if (response) setData(response?.data?.data?.data);
  }, [response]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/ProjectPIPMapping/get?project=${id}`)
        .then((response) => {
          if (response && response.data && response.data.data) setProjectPIP(response?.data?.data?.data);
        })
        .catch((err) => console.log(err, 'error'));
    }
  }, [id]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  useEffect(() => {
    if (props.saveAfterSomethingChanged === true && next) {
      props.toogleShowModal(false);
      handlSetSomethingChanged(false);
      props.setFalseAfterSomethingChanged();
      props.SETNEXT('');
      handleSave(next && next);
    }
  }, [props.saveAfterSomethingChanged, next]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };

  // creates myArray for sending all values together
  const appendChanged = (id, value) => {
    const body = {
      PIPScheme: id,
      value: value,
    };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.PIPScheme === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, body]);
    }
  };

  const handleSave = (next) => {
    // sends data
    let body2 = {
      projectId: id,
      values: myArray && myArray.length !== 0 && myArray,
    };
    axios.put(`/ProjectPIPMapping`, body2).then((response) => {
      props.toogleShowModal(false);
      handlSetSomethingChanged(false);
      props.setFalseAfterSomethingChanged();
      props.SETNEXT('');
      swal({ icon: 'success', title: 'Data Saved' });
      if (next && next === 'insights') history.push(`/projects/${id}/insights`);
      if (next && next === 'projects') history.push(`/projects`);
      else history.push(`/projects/${id}/edit?type=${next}`);
    });
  };

  useEffect(() => {
    if (projectPIP) {
      const unit = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_unitPreferenceForArea');
      const projectUnit = unit && unit.value ? unit.value : 'Sqm';
      setUnit(projectUnit && projectUnit);
    }
  }, [projectPIP]);

  // console.log(myArray && myArray, "pip**")

  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData?.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {/* pips */}
      {type && type === 'land' && simplePIPs && projectPIP?.length !== 0
        ? simplePIPs.map((field, index) => {
            return (
              <SimplePIPS
                index={index}
                unit={unit && unit}
                field={field && field}
                myArray={myArray && myArray}
                projectPIP={projectPIP && projectPIP}
                appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                type={type}
              />
            );
          })
        : type &&
          type !== 'fixture' &&
          type !== 'file' &&
          skeleton &&
          skeleton.map((d, index) => {
            return <Skeletons index={index} />;
          })}

      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
