import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
}));
const ApiError = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div>
        <Typography variant="h3">Oops!</Typography>
        <Typography variant="h5">Something went wrong.</Typography>
      </div>
    </div>
  );
};
export default ApiError;
