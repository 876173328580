export const LegalQuantityTypes = {
  String: 'string',
  ArrayWithStrings: 'string[]',
  Number: 'number',
  ArrayWithNumbers: 'number[]',
  Boolean: 'boolean',
  ArrayWithBooleans: 'boolean[]',
  UserActualDefault: 'UserActualDefault',
  Donuts: 'Donuts',
  Overview: 'Overview',
  PassiveImpact: 'PassiveImpact',
  Object: 'object',
};

export const MetafireFeatures = {
  INTERVENTIONS: 'INTERVENTIONS',
  INSIGHTS: 'INSIGHTS',
  GOALS: 'GOALS',
};
