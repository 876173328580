import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';

const HWMClubHouseCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const hwmClubhouseOption = getQuantityValue(schema.hwmClubhouse__pi_option);
  const handleHwmClubhouseOption = (option) => {
    updateQuantityValue(schema.hwmClubhouse__pi_option, option);
  };

  const handleSWhTypeSWH = (type) => {
    updateQuantityValue(schema.hwmClubhouse__pi_swhType, type);
    if (type === 'central') {
      if (!getQuantityLabel(schema.hwmClubhouse__pi_swhType) === type) {
        updateQuantityValue(schema.hwmClubhouse__pi_swhVariant, null);
      }
    }
  };

  const handleSWhTechnology = (technology) => {
    updateQuantityValue(schema.hwmClubhouse__pi_swhVariant, technology);
  };

  const handleHpType = (type) => {
    updateQuantityValue(schema.hwmClubhouse__pi_hpType, type);
  };

  const handleHpTemp = (temp) => {
    updateQuantityValue(schema.hwmClubhouse__pi_hpHeatingSpeed, temp);
  };
  // console.log(hwmClubhouseOption, 'current');
  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <Typography className={classes.textHeader1}>
            {hwmClubhouseOption === 'solar water heater' ? (
              <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmClubhouseOption('solar water heater')} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmClubhouseOption('solar water heater')} />
            )}
            Solar Water Heater
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader1}>
            {hwmClubhouseOption === 'heat pump' ? (
              <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmClubhouseOption('heat pump')} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmClubhouseOption('heat pump')} />
            )}
            Heat Pump
          </Typography>
        </Grid>
      </Grid>
      {hwmClubhouseOption === 'solar water heater' && (
        <>
          <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
            <Grid xs>
              <Typography className={classes.textheader2}>{getQuantityLabel(schema.hwmClubhouse__pi_userSwhCapacity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.hwmClubhouse__pi_userSwhCapacity)}
                  placeholder=""
                  onChange={(e) => {
                    updateQuantityValue(schema.hwmClubhouse__pi_userSwhCapacity, Number(e.target.value));
                  }}
                  fullWidth
                  required
                  onClick={() => updateQuantityValue(schema.hwmClubhouse__pi_userSwhCapacity, getQuantityDefaultValue(schema.hwmClubhouse__pi_userSwhCapacity))}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.hwmClubhouse__pi_userSwhCapacity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} container alignItems="flex-start">
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Type</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmClubhouse__pi_swhType) === 'central' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTypeSWH('central')} />
                )}
                {getQuantityValue(schema.hwmClubhouse__pi_swhType) !== 'central' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTypeSWH('central')} />
                )}
                Central
              </Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmClubhouse__pi_swhType) === 'individual' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTypeSWH('individual')} />
                )}
                {getQuantityValue(schema.hwmClubhouse__pi_swhType) !== 'individual' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTypeSWH('individual')} />
                )}
                Individual
              </Typography>
            </Grid>
            {getQuantityValue(schema.hwmClubhouse__pi_swhType) === 'central' && (
              <Grid item xs={6}>
                <Typography className={classes.textheader2}>Technology</Typography>
                <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                  <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                  ETC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                    placement="right"
                  />
                </Typography>
                <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                  <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                  FPC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                    placement="right"
                  />
                </Typography>
              </Grid>
            )}
            {getQuantityValue(schema.hwmClubhouse__pi_swhType) !== 'central' && (
              <Grid item xs={6}>
                <Typography className={classes.textheader2}>Technology</Typography>
                <Typography className={classes.textHeader3}>
                  {getQuantityValue(schema.hwmClubhouse__pi_swhVariant) === 'etc' && (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('etc')} />
                  )}
                  {getQuantityValue(schema.hwmClubhouse__pi_swhVariant) !== 'etc' && (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('etc')} />
                  )}
                  ETC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                    placement="right"
                  />
                </Typography>
                <Typography className={classes.textHeader3}>
                  {getQuantityValue(schema.hwmClubhouse__pi_swhVariant) === 'fpc' && (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />
                  )}
                  {getQuantityValue(schema.hwmClubhouse__pi_swhVariant) !== 'fpc' && (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />
                  )}
                  FPC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                    placement="right"
                  />
                </Typography>
              </Grid>
            )}
          </Grid>
        </>
      )}
      {hwmClubhouseOption === 'heat pump' && (
        <>
          <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
            <Grid xs>
              <Typography className={classes.textheader2}>{getQuantityLabel(schema.hwmClubhouse__pi_userHpCapacity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.hwmClubhouse__pi_userHpCapacity)}
                  placeholder=""
                  onChange={(e) => {
                    updateQuantityValue(schema.hwmClubhouse__pi_userHpCapacity, Number(e.target.value));
                  }}
                  fullWidth
                  required
                  onClick={() => updateQuantityValue(schema.hwmClubhouse__pi_userHpCapacity, getQuantityDefaultValue(schema.hwmClubhouse__pi_userHpCapacity))}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.hwmClubhouse__pi_userHpCapacity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} container alignItems="flex-start">
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Type</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmClubhouse__pi_hpType) === 'commercial' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpType('commercial')} />
                )}
                {getQuantityValue(schema.hwmClubhouse__pi_hpType) !== 'commercial' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('commercial')} />
                )}
                Commercial
              </Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmClubhouse__pi_hpType) === 'domestic' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpType('domestic')} />
                )}
                {getQuantityValue(schema.hwmClubhouse__pi_hpType) !== 'domestic' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('domestic')} />
                )}
                Domestic
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Heating</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmClubhouse__pi_hpHeatingSpeed) === 'slow' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('slow')} />
                )}
                {getQuantityValue(schema.hwmClubhouse__pi_hpHeatingSpeed) !== 'slow' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('slow')} />
                )}
                Slow Heating
              </Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmClubhouse__pi_hpHeatingSpeed) === 'fast' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('fast')} />
                )}
                {getQuantityValue(schema.hwmClubhouse__pi_hpHeatingSpeed) !== 'fast' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('fast')} />
                )}
                Fast Heating
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {hwmClubhouseOption === 'solar water heater' && (
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}> {getQuantityLabel(schema.hwmClubhouse_swhIndividualProductCapacity)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmClubhouse_swhIndividualProductCapacity)} {getQuantityUnit(schema.hwmClubhouse_swhIndividualProductCapacity)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmClubhouse_swhNumberOfProducts)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmClubhouse_swhNumberOfProducts)} {getQuantityUnit(schema.hwmClubhouse_swhNumberOfProducts)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmClubhouse_swhAreaRequired)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmClubhouseareaRequired?.value))} ${hwmClubhouseareaRequired?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmClubhouse_swhAreaRequired)} {getQuantityUnit(schema.hwmClubhouse_swhAreaRequired)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {hwmClubhouseOption === 'heat pump' && (
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmClubhouse_hpIndividualProductCapacity)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmClubhouse_hpIndividualProductCapacity)} {getQuantityUnit(schema.hwmClubhouse_hpIndividualProductCapacity)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmClubhouse_hpNumberOfProducts)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmClubhouse_hpNumberOfProducts)} {getQuantityUnit(schema.hwmClubhouse_hpNumberOfProducts)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmClubhouse_hpAreaRequired)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmClubhouseareaRequired?.value))} ${hwmClubhouseareaRequired?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmClubhouse_hpAreaRequired)} {getQuantityUnit(schema.hwmClubhouse_hpAreaRequired)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};
export default HWMClubHouseCustomBasicParameters;
