import VendorDetail from '../components/views/vendors/details/VendorDetail';
import InterventionDetailsContextComponent from '../components/views/interventions/interventionDetails/InterventionDetailsContextComponent'; //change here
import MainGrid from '../components/views/insightsPage/Main';
import MarketingDummy from '../components/views/marketing/MarketingDummyImage';
import DocumentDashboard from '../components/views/igbc-dashboard/documents-dashboard/DocumentDasboard';
import Certification from '../components/views/certification/Certification';
import Credit from '../components/views/certification/credit/Credit';
// import CreateGoals from '../components/views/goals/CreateGoals';
import GoalsInsights from '../components/views/goals/GoalsInsights';
import InterventionSummary from '../components/views/interventions/intervention/main/InterventionSummary';

const projectRouting = [
  { exact: true, path: '/insights', component: MainGrid },
  { exact: true, path: '/interventions', component: InterventionSummary },
  { exact: true, path: '/interventions/:iid', component: InterventionDetailsContextComponent },
  { exact: true, path: '/interventions/:iid/vendors/:id', component: VendorDetail },
  { exact: true, path: '/marketing', component: MarketingDummy },
  // { exact: true, path: '/certification', component: DocumentDashboard },
  { exact: true, path: '/certification', component: Certification },
  { exact: true, path: '/certification/credit/:creditId', component: Credit },
  { exact: true, path: '/certification/upload', component: DocumentDashboard },
  { exact: true, path: '/goals-insights', component: GoalsInsights },
];

export default projectRouting;
