import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import useInterventionDetails from '../../../marketplace/hooks/useInterventionDetailsData';
import DummyAddProductCard from '../../../marketplace/common/DummyAddProductCard';
import { useParams } from 'react-router';
import { getSubcategory } from './CustomFilterForKongInterv';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import InterventionProductCard from '../main/InterventionProductCard';

const useStyles = makeStyles((theme) => ({
  vendorGrid: {
    marginTop: '3%',
  },
  textwithrefresh: {
    display: 'flex',
    alignItems: 'center',
  },
  refreshicon: {
    color: 'rgba(38, 224, 163, 1)',
    cursor: 'pointer',
  },
  defaultText: {
    fontFamily: 'Lato',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.7142857313156128px',
    textAlign: 'left',
  },
}));

const widthstyle = {
  width: '220px',
};

const mapping = [
  { intervention: 'Solar PV', category: 'Hot Water Management', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Solar Water Heater', category: 'Hot Water Management', productIdentifier: 'NA', defaultTag: 'NA' },
  {
    intervention: 'Hot Water Management Villa',
    category: 'Hot Water Management',
    subcategory: 'Solar Water Heaters',
    productIdentifier: 'hwmVilla.productIdentifier',
    defaultTag: 'hwmVilla.defaultProductIdentifier',
  },
  {
    intervention: 'Hot Water Management Apartment',
    category: 'Hot Water Management',
    subcategory: 'Solar Water Heaters',
    productIdentifier: 'hwmApartment.productidentifier',
    defaultTag: 'hwmApartment.defaultproductidentifier',
  },
  {
    intervention: 'Hot Water Management Penthouse',
    category: 'Hot Water Management',
    subcategory: 'Solar Water Heaters',
    productIdentifier: 'hwmPentHouse.productidentifier',
    defaultTag: 'hwmPentHouse.defaultproductidentifier',
  },
  {
    intervention: 'Hot Water Management Clubhouse',
    category: 'Hot Water Management',
    subcategory: 'Solar Water Heaters',
    productIdentifier: 'hwmClubHouse.productidentifier',
    defaultTag: 'hwmClubHouse.defaultproductidentifier',
  },
  {
    intervention: 'Hot Water Management Swimming Pool',
    category: 'Hot Water Management',
    subcategory: 'Heat Pumps',
    productIdentifier: 'hwmSwimmingPool.productIdentifier',
    defaultTag: 'hwmSwimmingPool.defaultProductIdentifier',
  },
  { intervention: 'Efficient Fixtures', category: 'Plumbing Fixtures', productIdentifier: 'NA' },
  {
    intervention: 'Efficient Fixtures - Health Faucet',
    category: 'Plumbing Fixtures',
    subcategory: 'Health Faucet',
    productIdentifier: 'efhealthfaucet.productidentifier',
    defaultTag: 'efhealthfaucet.defaultproductidentifier',
  },
  {
    intervention: 'Efficient Fixtures - Faucet',
    category: 'Plumbing Fixtures',
    subcategory: 'Faucets',
    productIdentifier: 'effaucet.productidentifier',
    defaultTag: 'effaucet.defaultProductidentifier',
  },
  {
    intervention: 'Efficient Fixtures - Kitchen Faucet',
    category: 'Plumbing Fixtures',
    subcategory: 'Kitchen Sink',
    productIdentifier: 'efkitchenfaucet.productidentifier',
    defaultTag: 'efkitchenfaucet.defaultproductidentifier',
  },
  {
    intervention: 'Efficient Fixtures - Showers',
    category: 'Plumbing Fixtures',
    subcategory: 'Shower',
    productIdentifier: 'efshower.productidentifier',
    defaultTag: 'efshower.defaultProductidentifier',
  },
  {
    intervention: 'Efficient Fixtures - Dual Flush',
    category: 'Plumbing Fixtures',
    subcategory: 'Flushing',
    productIdentifier: 'efdualflush.productidentifier',
    defaultTag: 'efdualflush.defaultproductidentifier',
  },
  { intervention: 'Concrete', category: 'Cement', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Utility & Balcony', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Bedroom', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Living & Dining', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Bathroom', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Kitchen', category: 'Flooring', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Structural Steel', category: 'Steel', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Exterior Paints', category: 'Paints', productIdentifier: 'NA', defaultTag: 'NA' },
  { intervention: 'Interior Paints', category: 'Paints', productIdentifier: 'NA', defaultTag: 'NA' },
  {
    intervention: 'Treated Water Reuse',
    category: 'Waste Water Treatment',
    subcategory: 'Tertiary Treatment',
    productIdentifier: 'treatedwaterreuse.productidentifier',
    defaultTag: 'treatedwaterreuse.defaultProductidentifier',
  },
  {
    intervention: 'Natural STP',
    category: 'Waste Water Treatment',
    subcategory: 'Natural STP',
    productIdentifier: 'naturalstp.productidentifier',
    defaultTag: 'naturalstp.defaultProductidentifier',
  },
  {
    intervention: 'Smart Water Meters',
    category: 'Water Management',
    subcategory: 'Smart Water Meter',
    productIdentifier: 'swm.productidentifier',
    defaultTag: 'swm.defaultProductidentifier',
  },
  {
    intervention: 'Wet Waste Management',
    category: 'Waste Management',
    subcategory: 'Semi-Automatic Composters',
    productIdentifier: 'wwm.productidentifier',
    defaultTag: 'wwm.defaultProductidentifier',
  },
  {
    intervention: 'Collection & Segregation Point ',
    category: 'Waste Management',
    subcategory: 'Waste Management Company',
    productIdentifier: 'csp.productidentifier',
    defaultTag: 'csp.defaultProductidentifier',
  },
  {
    intervention: 'SPV for Apartment',
    category: 'SPV',
    subcategory: 'Hybrid',
    productIdentifier: 'spvApartment.productidentifier',
    defaultTag: 'spvApartment.defaultProductidentifier',
  },
  {
    intervention: 'SPV for Penthouse',
    category: 'SPV',
    subcategory: 'Hybrid',
    productIdentifier: 'spvPenthouse.productidentifier',
    defaultTag: 'spvPenthouse.defaultProductidentifier',
  },
  {
    intervention: 'SPV for Clubhouse',
    category: 'SPV',
    subcategory: 'Hybrid',
    productIdentifier: 'spvClubhouse.productidentifier',
    defaultTag: 'spvClubhouse.defaultProductidentifier',
  },
  {
    intervention: 'SPV for Villa',
    category: 'SPV',
    subcategory: 'Hybrid',
    productIdentifier: 'spvVilla.productidentifier',
    defaultTag: 'spvVilla.defaultProductidentifier',
  },
];

export const MarketPlaceLink = ({ interventionname, interventiondetails }) => {
  const classes = useStyles();
  const { iid } = useParams();
  const {
    product,
    get: { getShortlistedProductsForCombination },
    set: { updateSelectedProduct, removeSelectedProduct },
  } = useInterventionDetailsContext();

  const defaultProduct = product?.default;
  const selectedProduct = product?.selected;
  const { productDetailsData, fetchData: fetchProductDetailsData } = useInterventionDetails(product?.productIdentifiers?.join());

  const [filteredProducts, setFilteredProducts] = useState([]);
  // console.log(product, 'product product');
  useEffect(() => {
    if (product?.productIdentifiers?.length) {
      fetchProductDetailsData();
    }
  }, [product?.productIdentifiers]);

  useEffect(() => {
    if (productDetailsData?.products) {
      const filteredProducts = getShortlistedProductsForCombination([...productDetailsData?.products]);
      setFilteredProducts(filteredProducts);
    }
  }, [productDetailsData, defaultProduct]);

  const handleRefresh = () => {
    fetchProductDetailsData();
  };

  const getCategoryname = () => {
    const category = mapping.find((element) => element.intervention === interventionname);
    return category.category;
  };
  // console.log(product, productDetailsData, filteredProducts, 'deepak');
  return (
    <Grid container direction="column" item xs={12} className={classes.vendorGrid}>
      {
        <Grid container direction="column" item xs={3}>
          <Typography variant="h5">Selected Product</Typography>
        </Grid>
      }
      {productDetailsData?.products &&
        selectedProduct &&
        productDetailsData?.products?.map((element) => {
          if (element.identifier === selectedProduct) {
            return (
              <div>
                <InterventionProductCard
                  product={element}
                  defaultProduct={defaultProduct}
                  selectedProduct={selectedProduct}
                  section="selected"
                  onRemoveProduct={() => removeSelectedProduct(product)}
                  onSelectProduct={() => updateSelectedProduct(product)}
                />
              </div>
            );
          }
        })}
      {filteredProducts && filteredProducts.length !== 0 ? (
        <>
          <Grid container direction="column" item xs={3}>
            <Typography variant="h5" className={classes.textwithrefresh}>
              Shortlisted Products
              <RefreshIcon className={classes.refreshicon} onClick={handleRefresh} />
            </Typography>
          </Grid>
          <Grid container direction="row" item xs={12}>
            <DummyAddProductCard
              widthstyle={widthstyle}
              mappingid={iid}
              categoryName={getCategoryname}
              subcategoryName={getSubcategory(interventiondetails && interventiondetails, interventionname && interventionname)}
            />
            {filteredProducts &&
              filteredProducts.map((product) => {
                return (
                  <div style={{ marginRight: '2%' }}>
                    <InterventionProductCard
                      defaultProduct={defaultProduct}
                      selectedProduct={selectedProduct}
                      product={product}
                      section="shortlisted"
                      onRemoveProduct={() => removeSelectedProduct(product)}
                      onSelectProduct={() => updateSelectedProduct(product)}
                    />
                  </div>
                );
              })}
          </Grid>
        </>
      ) : (
        <>
          <Grid container direction="column" item xs={3}>
            <Typography variant="h5" className={classes.textwithrefresh}>
              Shortlisted Products
              <RefreshIcon className={classes.refreshicon} onClick={handleRefresh} />
            </Typography>
          </Grid>
          <Grid container direction="row" item xs={12}>
            <DummyAddProductCard
              widthstyle={widthstyle}
              mappingid={iid}
              categoryName={getCategoryname}
              subcategoryName={getSubcategory(interventiondetails && interventiondetails, interventionname && interventionname)}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
