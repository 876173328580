import { Button, Typography, makeStyles, withStyles } from '@material-ui/core';
import waterIcon from '../../../../../assets/Fill 688.svg';
import powerIcon from '../../../../../assets/Fill 1452.svg';
import wasteicon from '../../../../../assets/interventions/Group 1365.svg';
import wasteActiveicon from '../../../../../assets/interventions/wasteActivated.svg';
import waterActiveicon from '../../../../../assets/interventions/waterActivated.svg';
import energyActiveicon from '../../../../../assets/interventions/energyActivated.svg';
import activeMaterialIcon from '../../../../../assets/interventions/materials/active-materials.svg';
import notActiveMaterialIcon from '../../../../../assets/interventions/materials/not-active-materials.svg';

const StyledButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 3, 3),
    '&:focus': {
      outline: 'none',
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  endIco: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  endIcoActive: {
    fontSize: '16px',
    lineHeight: '24px',
    fontStyle: 'normal',
    letterSpacing: '0.571429px',
    color: '#FFFFFF',
    fontWeight: '800',
  },
  label: {
    width: '100%',
    textTransform: 'none',
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
}));

const SummaryCategoriesButton = ({ category, selectedType, count = 0, handleActiveBtn = () => {} }) => {
  const classes = useStyles();

  const categoryName = category?.name?.toLowerCase();
  const props = {
    energy: {
      style: { backgroundColor: '#FFF4D5' },
      activeIcon: energyActiveicon,
      icon: powerIcon,
    },
    water: {
      style: { backgroundColor: '#CFF2FA' },
      activeIcon: waterActiveicon,
      icon: waterIcon,
    },
    materials: {
      style: { backgroundColor: '#E1E6FF' },
      activeIcon: activeMaterialIcon,
      icon: notActiveMaterialIcon,
    },
    waste: {
      style: { backgroundColor: '#F3DDC8' },
      activeIcon: wasteActiveicon,
      icon: wasteicon,
    },
  };

  return (
    <StyledButton
      variant="text"
      style={selectedType === categoryName ? { ...props[categoryName]?.style } : { backgroundColor: 'white' }}
      className={classes.button}
      startIcon={
        <img src={selectedType === categoryName ? props[categoryName]?.activeIcon : props[categoryName]?.icon} alt="..." style={{ height: '3.5vh' }} />
      }
      endIcon={<Typography className={classes.endIco}>{count || 0}</Typography>}
      children={<Typography className={classes.label}>{category?.name}</Typography>}
      onClick={() => handleActiveBtn(categoryName)}
    />
  );
};
export default SummaryCategoriesButton;
