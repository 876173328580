import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import useStyles from './styles';

const HWMClubHouseProjectInputs = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    schema,
  } = useInterventionDetailsContext();

  const swimmingpoolsurfacearea = getQuantityValue(schema.hwmClubhouse_swimmingPoolSurfaceArea);
  const rooftoparea = getQuantityValue(schema.hwmClubhouse_clubhouseRooftopArea);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Club House Rooftop Area</Typography>
          <Typography className={classes.textHeader3}>{rooftoparea} Sqm</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Swimming Pool Surface Area</Typography>
          <Typography className={classes.textHeader3}>{swimmingpoolsurfacearea} Sqm</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};
export default HWMClubHouseProjectInputs;
