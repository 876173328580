import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import useApiRequest from '../../../hooks/useApiRequest';
import PlumbingDetailsComponent from '../plumbing/PlumbingDetailsComponent';
import { ProjectContext } from '../../../../routing/IndexRouting';
import PreferenceUnits from './components/PreferenceUnits';
import LocationBox from './components/Location';
import Skeletons from '../../../common/skeleton/Skeletons';
import SimplePIPS from './components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import ComplexPIPS from './components/ComplexPIPS';
import CustomDwellingPIP from './components/CustomDwellingPIP';

export const CAPEXPIPsForWWB = {
  OrganicComposter: 'project__pi_ubcOrganicWasteComposterCapex',
  Composting: 'project__pi_ubcNaturalCompostingCapex',
};

export const renewableenergy = {
  spv: 'project__pi_baseCaseCapacity',
  swh: 'project__pi_baseCaseSwhCapacity',
};

export const Dwelling = {
  perimeter: 'project__pi_perimeter',
  wwr: 'project__pi_wwr',
  floors: 'project__pi_numberOfFloors',
  floorheight: 'project__pi_floorToFloorHeight',
};

// make sure This component will be cleaned up into multiple components for multiple edit projects page - refer to wireframe
// create a context for sqm and sqft and make the components separate

const CommonEditProject = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let skeleton = [1, 2, 3, 4, 5];
  let { type, next, name } = props;
  const [myArray, setMyArray] = useState([]);
  const { handleProjectData } = useContext(ProjectContext);
  const [unitPreferencesPIP, setUnitPreferencesPIP] = useState('');
  const [simplePIPs, setSimplePIPs] = useState('');
  const [locationBox, setLocationBoxPIP] = useState('');

  // others
  const [data, setData] = useState('');
  const [projectPIP, setProjectPIP] = useState([]);
  const [builtup, setBuiltuparea] = useState('');
  const [dwelling, setDwelling] = useState('');
  const [mannuals, setmannuals] = useState('');
  const [projectData, setProjectData] = useState('');

  // const [projectdetails, setProjectDetails] = useState('');
  // const { subcategory } = projectdetails

  //Location Service
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [newloc, setNewloc] = useState({});
  const [value, setValue] = React.useState(options[0]);
  const [inputchange, setInputChange] = useState('');

  // unit states
  const [unit, setUnit] = useState('');
  const [radioButtonValue, setRadioButtonValue] = useState('');
  const [unitPIPid, setUnitPIPid] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);
  const [{ status, response }, makeRequest] = useApiRequest(`/pipschemev2?category=${type}`, {
    verb: 'get',
  });
  const [mo, doRequest] = useApiRequest(`/projectv2/${id}/basics`, {
    verb: 'get',
  });
  if (status === null && type && type !== 'file') makeRequest();
  if (mo?.status === null && type && type !== 'file') doRequest();

  useEffect(() => {
    if (mo?.response) {
      setProjectData(mo?.response?.data?.data?.data);
    }
  }, [mo?.response]);

  useEffect(() => {
    if (data) {
      const unitPreferences = data.filter((field) => field.name === 'project__pi_unitPreferenceForArea');
      const SimplePIPs = data.filter(
        (field) => field.name !== 'project__pi_includeClubhouse' && field.name !== 'location' && field.name !== 'project__pi_unitPreferenceForArea'
      );
      const LocationBox = data.filter((field) => field.name === 'location');
      setSimplePIPs(SimplePIPs);
      setUnitPreferencesPIP(unitPreferences);
      setLocationBoxPIP(LocationBox);
    }
  }, [data]);

  useEffect(() => {
    if (simplePIPs && type) {
      if (type === 'basic') {
        const sortedData = simplePIPs.sort(function (a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setSimplePIPs(sortedData);
      }
    }
  }, [simplePIPs, type]);

  //useEffects
  useEffect(() => {
    if (projectPIP.length !== 0) {
      let arr2 = [];
      projectPIP.forEach((d) => arr2.push({ PIPScheme: d.PIPScheme._id, value: d.value }));
      if (arr2.length !== 0) arr2.forEach((element) => appendChanged(element.PIPScheme, element.value));
    }
  }, [projectPIP]);

  useEffect(() => {
    if (projectPIP.length !== 0 && myArray.length !== 0) {
      myArray.forEach((ele) => {
        let valueFromProjectPIP = projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme) && projectPIP.find((e) => e.PIPScheme._id === ele.PIPScheme).value;
        if (ele.value !== valueFromProjectPIP) handlSetSomethingChanged(true);
      });
    }
  }, [projectPIP, myArray]);

  useEffect(() => {
    if (response) {
      const unsortedData = response && response.data.data.data;
      const sorted = unsortedData.sort((a, b) => compare(a, b));
      setData(sorted);
    }
  }, [response]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/ProjectPIPMapping/get?project=${id}`)
        .then((response) => {
          if (response && response.data && response.data.data) {
            setProjectPIP(response.data.data.data);
            // axios.get(`/projectv2/${id}`).then((res) => {
            //   if (res && res.data && res.data.data) setProjectDetails(res.data.data.data);
            // });
          }
        })
        .catch((err) => console.log(err, 'error'));
    }
  }, [id]);

  // useEffect(() => {
  //   if (projectdetails) handleProjectData(projectdetails);
  // }, [projectdetails]);

  useEffect(() => {
    if (data) {
      const mannualData = data.filter(
        (d) =>
          d.category === 'fixture' &&
          (d.name === 'project__pi_domesticKitchenSinkFlow' ||
            d.name === 'project__pi_domesticShowerFlow' ||
            d.name === 'project__pi_domesticFaucetFlow' ||
            d.name === 'project__pi_domestichHealthFaucetFlow' ||
            d.name === 'project__pi_domesticFlushingFullFlow' ||
            d.name === 'project__pi_domesticFlushingHalfPrice')
      );
      setmannuals(mannualData);
    }
  }, [data]);

  useEffect(() => {
    if (radioButtonValue && radioButtonValue === 'Sqm' && unitPIPid) appendChanged(unitPIPid, 'Sqm');
    if (radioButtonValue && radioButtonValue === 'Sqft' && unitPIPid) appendChanged(unitPIPid, 'Sqft');
  }, [radioButtonValue, unitPIPid]);

  useEffect(() => {
    if (unit && unit) setRadioButtonValue(unit);
  }, [unit]);

  useEffect(() => {
    if (radioButtonValue && radioButtonValue) setUnit(radioButtonValue);
  }, [radioButtonValue]);

  /*will be cleaned up later*/
  const [studio, setStudio] = useState('');
  const [single, setSingle] = useState('');
  const [double, setDouble] = useState('');
  const [three, setThree] = useState('');
  const [four, setFour] = useState('');
  const [studioCarpet, setStudioCarpet] = useState('');
  const [singleCarpet, setSingleCarpet] = useState('');
  const [doublecarpet, setDoublecarpet] = useState('');
  const [threeecarpet, setThreecarpet] = useState('');
  const [fourcarpet, setFourcarpet] = useState('');
  const [villa_studio, setVilla_Studio] = useState('');
  const [villa_single, setVilla_Single] = useState('');
  const [villa_double, setVilla_Double] = useState('');
  const [villa_three, setVilla_Three] = useState('');
  const [villa_four, setVilla_Four] = useState('');
  const [villa_studioCarpet, setVilla_StudioCarpet] = useState('');
  const [villa_singleCarpet, setVilla_SingleCarpet] = useState('');
  const [villa_doublecarpet, setVilla_Doublecarpet] = useState('');
  const [villa_threeecarpet, setVilla_Threeecarpet] = useState('');
  const [villa_fourcarpet, setVilla_Fourcarpet] = useState('');
  const [penthouseAvailableRooftop, setPenthouseAvailableRooftop] = useState('');
  const [penthouse_single, setPenthouse_Single] = useState('');
  const [penthouse_double, setPenthouse_Double] = useState('');
  const [penthouse_three, setPenthouse_Three] = useState('');
  const [penthouse_four, setPenthouse_Four] = useState('');

  useEffect(() => {
    if (projectPIP) {
      const builtupArea = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_builtUpArea');
      const studio = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_studio');
      const single = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_oneBHK');
      const double = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_twoBHK');
      const three = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_threeBHK');
      const four = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_fourBHK');
      const penthouseEnabled = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_penthouseEnabled');
      // check for pips for existing unit for the project
      const unit = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_unitPreferenceForArea');
      const StudioUnits = studio && studio.value ? parseInt(studio.value) : 0;
      const OneBHKUnits = single && single.value ? parseInt(single.value) : 0;
      const TwoBHKUnits = double && double.value ? parseInt(double.value) : 0;
      const ThreeBHKUnits = three && three.value ? parseInt(three.value) : 0;
      const FourBHKUnits = four && four.value ? parseInt(four.value) : 0;

      // find the value as sqm or sqft from pip mapping
      const projectUnit = unit && unit.value ? unit.value : 'Sqm';
      setBuiltuparea(builtupArea && builtupArea.value);
      setDwelling(StudioUnits + OneBHKUnits + TwoBHKUnits + ThreeBHKUnits + FourBHKUnits);
      // set project unit if found from pips
      setUnit(projectUnit && projectUnit);
    }
  }, [projectPIP]);

  const compare = (a, b) => {
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    else return 0;
  };
  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  useEffect(() => {
    if (props.saveAfterSomethingChanged === true && next) {
      props.toogleShowModal(false);
      handlSetSomethingChanged(false);
      props.setFalseAfterSomethingChanged();
      props.SETNEXT('');
      handleSave(next && next);
    }
  }, [props.saveAfterSomethingChanged, next]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };
  const handleSearch = (e) => {
    setSearch(true);
    setLoading(true);
    axios.get(`/location/?location=${inputchange}`).then((resp) => {
      if (resp && resp.data && resp.data.data) setOptions([...resp.data.data.data]);
    });
    setOpen(true);
    setLoading(false);
    return () => setSearch(false);
  };

  const handleSave = (next) => {
    if (next === 'materials') {
      let body2 = {
        projectId: id,
        values: myArray && myArray.length !== 0 && myArray,
      };
      axios.put(`/ProjectPIPMapping`, body2).then((response) => {
        props.toogleShowModal(false);
        handlSetSomethingChanged(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');

        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
    } else if (type === 'basic' && newloc && newloc.placeId) {
      axios.get(`/location/place?id=${newloc.placeId}`).then((ress) => {
        if (ress && ress.data && ress.data.data) {
          let loc = ress.data.data.data;
          // sends data
          let body2 = {
            projectId: id,
            values: myArray && myArray.length !== 0 && myArray,
          };
          let body3 = {
            newLocation: {
              name: loc.name,
              type: 'Point',
              coordinates: [loc.coordinates.lat, loc.coordinates.long],
            },
          };
          axios.put(`/ProjectPIPMapping`, body2).then((response) => {
            axios.put(`/projectv2/${id}`, body3).then((response) => {
              props.toogleShowModal(false);
              handlSetSomethingChanged(false);
              props.setFalseAfterSomethingChanged();
              props.SETNEXT('');

              swal({ icon: 'success', title: 'Data Saved' });
              if (next && next === 'insights') history.push(`/projects/${id}/insights`);
              if (next && next === 'projects') history.push(`/projects`);
              else history.push(`/projects/${id}/edit?type=${next}`);
            });
          });
        }
      });
    } else {
      // sends data
      let body2 = {
        projectId: id,
        values: myArray && myArray.length !== 0 && myArray,
      };
      axios.put(`/ProjectPIPMapping`, body2).then((response) => {
        props.toogleShowModal(false);
        handlSetSomethingChanged(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
    }
  };

  // creates myArray for sending all values together
  const appendChanged = (id, value) => {
    const body = {
      PIPScheme: id,
      value: value,
    };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.PIPScheme === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, body]);
    }
  };

  /*will be cleaned up later*/
  useEffect(() => {
    if (simplePIPs) {
      const studio = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_studio');
      const single = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_oneBHK');
      const double = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_twoBHK');
      const three = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_threeBHK');
      const four = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_fourBHK');
      const studioCarpet = simplePIPs && simplePIPs.find((d) => d.name === 'studiocarpetarea');
      const singleCarpet = simplePIPs && simplePIPs.find((d) => d.name === 'singlecarpetarea');
      const doublecarpet = simplePIPs && simplePIPs.find((d) => d.name === 'doublecarpetarea');
      const threeecarpet = simplePIPs && simplePIPs.find((d) => d.name === 'threecarpetarea');
      const fourcarpet = simplePIPs && simplePIPs.find((d) => d.name === 'fourcarpetarea');
      const villa_studio = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_villaStudio');
      const villa_single = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_villaOneBHK');
      const villa_double = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_villaTwoBHK');
      const villa_three = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_villaThreeBHK');
      const villa_four = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_villaFourBHK');
      const villa_studioCarpet = simplePIPs && simplePIPs.find((d) => d.name === 'villa.studio.carpetarea');
      const villa_singleCarpet = simplePIPs && simplePIPs.find((d) => d.name === 'villa.onebhk.carpetarea');
      const villa_doublecarpet = simplePIPs && simplePIPs.find((d) => d.name === 'villa.twobhk.carpetarea');
      const villa_threeecarpet = simplePIPs && simplePIPs.find((d) => d.name === 'villa.threebhk.carpetarea');
      const villa_fourcarpet = simplePIPs && simplePIPs.find((d) => d.name === 'villa.fourbhk.carpetarea');
      const penthouseAvailableRooftopArea = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_availablePenthouseRooftopArea');
      const penthouse_single = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_penthouseOneBHK');
      const penthouse_double = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_penthouseTwoBHK');
      const penthouse_three = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_penthouseThreeBHK');
      const penthouse_four = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_penthouseFourBHK');
      const penthouse_studio = simplePIPs && simplePIPs.find((d) => d.name === 'project__pi_penthouseStudio');
      setFourcarpet(fourcarpet);
      setThreecarpet(threeecarpet);
      setDoublecarpet(doublecarpet);
      setSingleCarpet(singleCarpet);
      setStudioCarpet(studioCarpet);
      setFour(four);
      setThree(three);
      setDouble(double);
      setSingle(single);
      setStudio(studio);
      setVilla_Fourcarpet(villa_fourcarpet);
      setVilla_Threeecarpet(villa_threeecarpet);
      setVilla_Doublecarpet(villa_doublecarpet);
      setVilla_SingleCarpet(villa_singleCarpet);
      setVilla_StudioCarpet(villa_studioCarpet);
      setVilla_Four(villa_four);
      setVilla_Three(villa_three);
      setVilla_Double(villa_double);
      setVilla_Single(villa_single);
      setVilla_Studio(villa_studio);
      setPenthouse_Single(penthouse_single);
      setPenthouse_Double(penthouse_double);
      setPenthouse_Three(penthouse_three);
      setPenthouse_Four(penthouse_four);
      setPenthouseAvailableRooftop(penthouseAvailableRooftopArea);
    }
  }, [simplePIPs]);

  const commonAreaDetailsPIPIds = ['project__pi_availableClubhouseRooftopArea'];

  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '7%', paddingLeft: '2%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>
      {/* units ////////////////////////////////////////////////////////////////////// */}
      {type && type !== 'fixture' && type !== 'file' && unitPreferencesPIP
        ? unitPreferencesPIP.map((field, index) => {
            return (
              <PreferenceUnits
                field={field}
                type={type}
                index={index}
                radioButtonValue={radioButtonValue}
                onChange={(e) => {
                  setRadioButtonValue(e.target.value);
                  setUnitPIPid(field._id);
                }}
              />
            );
          })
        : null}
      {/* others ////////////////////////////////////////////////////////////////////////// */}
      {type && type !== 'fixture' && type !== 'file' && locationBox
        ? locationBox.map((field, index) => {
            return (
              <LocationBox
                field={field}
                type={type}
                index={index}
                options={options}
                loading={loading}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  setNewloc(newValue);
                  if (newValue != null) {
                    appendChanged(field._id, newValue.description);
                  }
                }}
                onInputChange={(e, newInputValue) => {
                  setInputChange(newInputValue);
                }}
                handleSearch={handleSearch}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) => option.description === value.description}
                defaultValue={{
                  description: projectData?.location?.name,
                  co_ordinates: projectData?.location?.coordinates,
                }}
              />
            );
          })
        : type &&
          type !== 'fixture' &&
          type !== 'file' &&
          skeleton &&
          skeleton.map((d, index) => {
            return <Skeletons index={index} />;
          })}

      {/* Dwelling - will be cleaned up later*/}
      {type && type !== 'fixture' && type !== 'file' && simplePIPs && type === 'dwelling' && (
        <>
          <Grid container xs={12} style={{ marginLeft: '2%' }}>
            <Typography variant="h5">Studio</Typography>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? studio && studio : villa_studio && villa_studio}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={1}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? studioCarpet && studioCarpet : villa_studioCarpet && villa_studioCarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ marginTop: '3%' }}>
              1 BHK
            </Typography>
            <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                {single?.description}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? single && single : villa_single && villa_single}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? singleCarpet && singleCarpet : villa_singleCarpet && villa_singleCarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ marginTop: '3%' }}>
              2 BHK
            </Typography>
            <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                {double?.description}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? double && double : villa_double && villa_double}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? doublecarpet && doublecarpet : villa_doublecarpet && villa_doublecarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ marginTop: '3%' }}>
              3 BHK
            </Typography>
            <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                {three?.description}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? three && three : villa_three && villa_three}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? threeecarpet && threeecarpet : villa_threeecarpet && villa_threeecarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" style={{ marginTop: '3%' }}>
              4 BHK
            </Typography>
            <Grid alignItems="center" item xs={12} style={{ marginBottom: '1%' }}>
              <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
                {four?.description}
              </Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? four && four : villa_four && villa_four}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDwellingPIP
                  index={0}
                  unit={unit && unit}
                  field={projectData?.subcategory != 'villa' ? fourcarpet && fourcarpet : villa_fourcarpet && villa_fourcarpet}
                  myArray={myArray && myArray}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                  type={type}
                  PIPs={simplePIPs && simplePIPs}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {type &&
        type !== 'fixture' &&
        type !== 'file' &&
        type === 'common' &&
        projectData?.subcategory !== 'villa' &&
        simplePIPs &&
        commonAreaDetailsPIPIds.map((id, index) => {
          const item = simplePIPs.find((item) => item.name === id);
          return (
            <SimplePIPS
              id={id}
              index={index}
              unit={unit && unit}
              field={item && item}
              myArray={myArray && myArray}
              projectPIP={projectPIP && projectPIP}
              appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
              type={type}
            />
          );
        })}

      {/*will be cleaned up later*/}
      {type && type !== 'fixture' && type !== 'file' && simplePIPs
        ? simplePIPs.map((field, index) => {
            if (
              field.name !== CAPEXPIPsForWWB.OrganicComposter &&
              field.name !== CAPEXPIPsForWWB.Composting &&
              field.name !== renewableenergy.spv &&
              field.name !== renewableenergy.swh &&
              field.name !== 'project__pi_oneBHK' &&
              field.name !== 'project__pi_twoBHK' &&
              field.name !== 'project__pi_threeBHK' &&
              field.name !== 'project__pi_fourBHK' &&
              field.name !== 'project__pi_studio' &&
              field.name !== 'singlecarpetarea' &&
              field.name !== 'doublecarpetarea' &&
              field.name !== 'threecarpetarea' &&
              field.name !== 'fourcarpetarea' &&
              field.name !== 'studiocarpetarea' &&
              field.name !== 'villa.onebhk' &&
              field.name !== 'villa.twobhk' &&
              field.name !== 'villa.threebhk' &&
              field.name !== 'villa.fourbhk' &&
              field.name !== 'villa.studio' &&
              field.name !== 'villa.studio.carpetarea' &&
              field.name !== 'villa.onebhk.carpetarea' &&
              field.name !== 'villa.twobhk.carpetarea' &&
              field.name !== 'villa.threebhk.carpetarea' &&
              field.name !== 'villa.fourbhk.carpetarea' &&
              field.name !== 'project__pi_availablePenthouseRooftopArea' &&
              field.name !== 'project__pi_penthouseOneBHK' &&
              field.name !== 'project__pi_penthouseTwoBHK' &&
              field.name !== 'project__pi_penthouseThreeBHK' &&
              field.name !== 'project__pi_penthouseFourBHK' &&
              field.name !== 'project__pi_penthouseStudio' &&
              field.name !== 'project__pi_availableClubhouseRooftopArea' &&
              field.name !== 'project__pi_availableVillaRooftopAreaa'
            ) {
              if (field?.options?.length === 0) {
                // return ''
                return (
                  <SimplePIPS
                    index={index}
                    unit={unit && unit}
                    field={field && field}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                  />
                );
              } else if (field?.options?.length != 0) {
                // return ''
                return (
                  <ComplexPIPS
                    index={index}
                    unit={unit && unit}
                    field={field && field}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                    subcategory={projectData?.subcategory && projectData?.subcategory}
                  />
                );
              }
            } else if (field.name === renewableenergy.spv) {
              return (
                <>
                  <Grid item alignItems="left" xs={12}>
                    <Typography variant="h5" style={{ marginLeft: '2%', marginBottom: '1%' }}>
                      <b>Renewable Energy</b>
                    </Typography>
                  </Grid>
                  <SimplePIPS
                    index={index}
                    unit={unit && unit}
                    field={field && field}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                  />
                </>
              );
            } else if (field.name === renewableenergy.swh) {
              return (
                <>
                  <SimplePIPS
                    index={index}
                    unit={unit && unit}
                    field={field && field}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                  />
                </>
              );
            } else {
              return '';
            }
          })
        : type &&
          type !== 'fixture' &&
          type !== 'file' &&
          skeleton &&
          skeleton.map((d, index) => {
            return <Skeletons index={index} />;
          })}

      {/* for penthouse pip -  will be cleaned up later*/}
      {myArray.find((s) => s.PIPScheme === '66064a2479e05b0db16613e1')?.value === true &&
        type &&
        type !== 'fixture' &&
        type !== 'file' &&
        type === 'dwelling' && (
          <>
            <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
              <Typography variant="h5">Available Penthouse Rooftop Area</Typography>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <CustomDwellingPIP
                    index={0}
                    unit={unit && unit}
                    field={penthouseAvailableRooftop && penthouseAvailableRooftop}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
              <Typography variant="h5">1 BHK</Typography>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <CustomDwellingPIP
                    index={0}
                    unit={unit && unit}
                    field={penthouse_single && penthouse_single}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
              <Typography variant="h5">2 BHK</Typography>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <CustomDwellingPIP
                    index={0}
                    unit={unit && unit}
                    field={penthouse_double && penthouse_double}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
              <Typography variant="h5">3 BHK</Typography>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <CustomDwellingPIP
                    index={0}
                    unit={unit && unit}
                    field={penthouse_three && penthouse_three}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ height: 'Auto', marginBottom: '3%', marginLeft: '2%' }}>
              <Typography variant="h5">4 BHK</Typography>
              <Grid container item xs={12}>
                <Grid item xs={6}>
                  <CustomDwellingPIP
                    index={0}
                    unit={unit && unit}
                    field={penthouse_four && penthouse_four}
                    myArray={myArray && myArray}
                    projectPIP={projectPIP && projectPIP}
                    appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
                    type={type}
                    PIPs={simplePIPs && simplePIPs}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

      {/* for fixture new component*/}
      {type && type === 'fixture' && (
        <PlumbingDetailsComponent
          myArray={myArray && myArray}
          fixturesData={data && data}
          projectPIP={projectPIP && projectPIP}
          appendChanged={(id, value) => appendChanged(id, value)}
          type={type && type}
          mannuals={mannuals && mannuals}
          builtup={builtup && builtup}
          dwelling={dwelling && dwelling}
        />
      )}

      {type && type === 'basic' ? (
        <Typography variant="h6" color="textSecondary" style={{ marginLeft: '2%' }}>
          *All fields are mandatory
        </Typography>
      ) : (
        ''
      )}
      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
export default CommonEditProject;
