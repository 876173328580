import React, { useState, useEffect, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import privateRouting from './PrivateRouting';
import publicRouting from './PublicRouting';
import axios from '../config/Axios';
import cookie from 'react-cookies';
import { Typography } from '@material-ui/core';
import { LastLocationProvider } from 'react-router-last-location';
import Error from '../components/common/notfound/Error';

export const Pages = {
  vendorDetails: 'vendorDetails',
  certification: 'certification',
  marketing: 'marketing',
  certificationDocumentUpload: 'certificationDocumentUpload',
  projects: 'projects',
  insights: 'insights',
  interventions: 'interventions',
  interventionDetails: 'interventionDetails',
  certificationCredit: 'certificationCredit',
  goals: 'goals',
};

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}

export const ProjectContext = React.createContext();
export const UserContext = React.createContext();
export const TimeframeContext = React.createContext();
export const PreviousPageContext = React.createContext();
export const ProductsPageContext = React.createContext();

const Routes = (props) => {
  const token = cookie.load('SDPLUS_TOKEN');
  const [loginToken, setLoginToken] = useState(token);
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState(false);
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  if (!userDetails && token) {
    axios
      .get('/authv2/whoami')
      .then((res) => setUserDetails(res.data.data.data))
      .catch((err) => {
        setError(true);
      });
  }

  const [previousPage, setPreviousPage] = useState('projects');
  const [projectID, setProjectID] = useState('');
  const [interventionMappingID, setIntervnetionMappingID] = useState('');
  const [interventionVendorMappingID, setInterventionVendorMappingID] = useState('');
  const [IGBCCreditID, setIGBCCreditID] = useState('');

  const [projectData, setProjectData] = useState(null);
  let routesIs = loginToken ? userDetails && privateRouting : publicRouting;

  const handleProjectData = (data) => setProjectData(data);
  const handleLoginToken = (data) => setLoginToken(data);

  function validateRole(route, index) {
    const requiredRoles = route.requiredRoles;
    if (!userDetails || (userDetails && requiredRoles.includes(userDetails.role))) {
      return <Route key={index} exact={route.exact} path={route.path} component={route.component} />;
    }
  }

  /*time frame implementation*/
  const [selectedYear, setSelectedYear] = useState(3);
  const [loader, setLoader] = useState(false);
  const setContextLoader = (data) => setLoader(data);

  const handleSelectedYear = (e) => {
    setSelectedYear(e.target.value);
    setLoader(true);
  };

  //for new context implementation for marketplace

  // states
  const [contactUs, setContactUs] = React.useState(false);
  const handleShowContactUs = (d) => setContactUs(d);

  const [filtersData, setFiltersData] = useState([]);
  const [appliedfilters, setAppliedFilters] = useState([]);
  const [categoryarr, setCategoryarr] = useState([]);
  const [filterarr, setFilterarr] = useState([]);
  const [isDefault, setIsDefault] = useState(false);
  // /For Marketplace context
  const [URLS, setURLs] = useState({
    category: '',
    subcategory: '',
    brands: '',
    supercategory: '',
    page: 1,
    pagesize: 20,
    source: '',
    filters: '',
    sort: '',
    searchquery: '',
    mappingid: '',
    productids: '',
    productstatus: '',
  });

  const handleURL = (
    category,
    subcategory,
    brands,
    supercategory,
    page,
    pagesize,
    source,
    filters,
    sort = 'default',
    searchquery,
    mappingid,
    productids,
    productstatus
  ) => {
    setURLs({
      category: category,
      subcategory: subcategory,
      brands: brands,
      supercategory: supercategory,
      page: page,
      pagesize: pagesize,
      source: source,
      filters: filters,
      sort: sort,
      searchquery: searchquery,
      mappingid: mappingid,
      productids: productids,
      productstatus: productstatus,
    });
  };

  const handleCategory = (name, subname, checked) => {
    const body = { name, subname, checked };
    let n = categoryarr && categoryarr.find((data) => data.name === name && data.subname === subname);
    if (n) {
      n.checked = checked;
      setCategoryarr((oldArray) => [...oldArray]);
    } else setCategoryarr((oldArray) => [...oldArray, body]);
  };

  const handleFilterArr = (name, subname, checked) => {
    const body = { name, subname, checked };
    let n = filterarr && filterarr.find((data) => data.name === name && data.subname === subname);
    if (n) {
      n.checked = checked;
      setFilterarr((oldArray) => [...oldArray]);
    } else setFilterarr((oldArray) => [...oldArray, body]);
  };

  const handleFilterData = (id, value, checked) => {
    const body = { id, value, checked };
    let n = filtersData && filtersData.find((data) => data.value === value);
    if (n) {
      n.value = value;
      n.checked = checked;
      if (n.checked === false) {
        const updatedFiltersArray = filtersData.filter((data) => data.checked !== false);
        setFiltersData(updatedFiltersArray);
      } else setFiltersData((oldArray) => [...oldArray]);
    } else setFiltersData((oldArray) => [...oldArray, body]);
  };

  const handleAppliedFilters = (id, value, checked) => {
    const body = { id, value, checked };
    let n = appliedfilters && appliedfilters.find((data) => data.value === value);
    if (n) {
      n.value = value;
      n.checked = checked;
      if (n.checked === false) {
        const updatedFiltersArray = appliedfilters.filter((data) => data.checked !== false);
        setAppliedFilters(updatedFiltersArray);
        // setAppliedFilters((oldArray) => [...oldArray]);
      }
    } else setAppliedFilters((oldArray) => [...oldArray, body]);
  };

  const handleReset = () => {
    setFiltersData([]);
    setAppliedFilters([]);
    setCategoryarr([]);
    setFilterarr([]);
  };

  // console.log({ filtersData, appliedfilters }, ' filters and applied filters');

  return (
    <div>
      <BrowserRouter>
        <LastLocationProvider>
          <ScrollToTop />
          <ProductsPageContext.Provider
            value={{
              URLS,
              handleURL,
              filtersData: filtersData,
              appliedfilters: appliedfilters,
              handleFilterData,
              handleAppliedFilters,
              setFiltersData,
              setAppliedFilters,
              handleReset,
              handleCategory,
              categoryarr,
              setCategoryarr,
              setFilterarr,
              handleFilterArr,
              filterarr,
              isDefault,
              setIsDefault,
              contactUs,
              handleShowContactUs,
            }}
          >
            <PreviousPageContext.Provider
              value={{
                previousPage,
                setPreviousPage,
                projectID,
                setProjectID,
                interventionMappingID,
                setIntervnetionMappingID,
                interventionVendorMappingID,
                setInterventionVendorMappingID,
                IGBCCreditID,
                setIGBCCreditID,
              }}
            >
              <TimeframeContext.Provider value={{ timeframeContext: selectedYear, loader: loader, handleSelectedYear, setContextLoader }}>
                <UserContext.Provider value={{ loginToken, handleLoginToken, userDetails, setUserDetails }}>
                  <ProjectContext.Provider value={{ projectData, handleProjectData }}>
                    <Suspense fallback={<Typography variant="body1">Loading...</Typography>}>
                      <Switch>{routesIs ? routesIs.map((route, index) => validateRole(route, index)) : error ? <Error /> : null}</Switch>
                    </Suspense>
                  </ProjectContext.Provider>
                </UserContext.Provider>
              </TimeframeContext.Provider>
            </PreviousPageContext.Provider>
          </ProductsPageContext.Provider>
        </LastLocationProvider>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
