import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgressComponent from './LinerProgress';
import numberWithCommas from '../../../../../utils/numberToComma';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    minHeight: 290,
    padding: 18,
    marginBottom: theme.spacing(3),
    background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), #BFF5E6',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.05)',
    borderRadius: '6px',
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '20px',
  },
}));

// check out for a common component
const SelectedInterv = ({ intervention }) => {
  const classes = useStyles();
  const { getValue, getUnit } = useAppMetafireUtilities();

  let energyCategory = intervention && intervention?.categorized.find((category) => category.name === 'Energy');
  let waterCategory = intervention && intervention?.categorized.find((category) => category.name === 'Water');
  let wasteCategory = intervention && intervention?.categorized.find((category) => category.name === 'Waste');
  let materialsCategory = intervention && intervention?.categorized.find((category) => category.name === 'Materials');

  const energySavings = energyCategory && getValue(energyCategory?.quantities?.resource?.category);
  const energyUnit = energyCategory && getUnit(energyCategory?.quantities?.resource?.category);
  const energySavingsPercentage = (energyCategory && getValue(energyCategory?.quantities?.resource?.categorySavingPercentage)) || 0;

  const waterSavings = waterCategory && getValue(waterCategory?.quantities?.resource?.category);
  const waterUnit = waterCategory && getUnit(waterCategory?.quantities?.resource?.category);
  const waterSavingsPercentage = (waterCategory && getValue(waterCategory?.quantities?.resource?.categorySavingPercentage)) || 0;

  const wasteSavings = wasteCategory && getValue(wasteCategory?.quantities?.resource?.category);
  const wasteUnit = wasteCategory && getUnit(wasteCategory?.quantities?.resource?.category);
  const wasteSavingsPercentage = (wasteCategory && getValue(wasteCategory?.quantities?.resource?.categorySavingPercentage)) || 0;

  const materialsSavings = materialsCategory && getValue(materialsCategory?.quantities?.resource?.category);
  const materialsUnit = materialsCategory && getUnit(materialsCategory?.quantities?.resource?.category);
  const materialsSavingsPercentage = (materialsCategory && getValue(materialsCategory?.quantities?.resource?.categorySavingPercentage)) || 0;

  return (
    <>
      <Container className={classes.gridContainer}>
        <Grid container xs={12} style={{ marginBottom: 30, marginTop: 10 }}>
          <Grid container item xs={6} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: '#F28D14', letterSpacing: '1px', paddingBottom: '4%' }}>
                <b>Energy</b>
              </Typography>
            </Grid>{' '}
            <LinearProgressComponent bgcolor="#F28D14" value={numberWithCommas(energySavingsPercentage)} />
            <Grid container item xs={10} style={{ marginTop: 15 }}>
              <Grid item xs={10}>
                {(energySavings && energySavings > 0) || (energySavings && energySavings === 0) ? (
                  <Grid item xs={12}>
                    <Typography variant="h6">{`${numberWithCommas(energySavings)}`}</Typography>
                  </Grid>
                ) : (
                  <Typography variant="h6">{`0.00`}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6">{energyUnit}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: '#3BC7EE', letterSpacing: '1px', paddingBottom: '4%' }}>
                <b>Water</b>
              </Typography>
            </Grid>{' '}
            <LinearProgressComponent bgcolor="#3BC7EE" value={numberWithCommas(waterSavingsPercentage)} />
            <Grid container item xs={10} style={{ marginTop: 15 }}>
              <Grid item xs={10}>
                {waterSavings > 0 || waterSavings === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h6">{`${numberWithCommas(waterSavings)}`}</Typography>
                  </Grid>
                ) : (
                  <Typography variant="h6">{`0.00`}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6">{waterUnit}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12} style={{ marginTop: 30 }}>
          <Grid container item xs={6} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ color: '#BC8A5F', letterSpacing: '1px', paddingBottom: '4%' }}>
                <b>Waste</b>
              </Typography>
            </Grid>
            <LinearProgressComponent bgcolor="#BC8A5F" value={numberWithCommas(wasteSavingsPercentage)} />
            <Grid container item xs={10} style={{ marginTop: 15 }}>
              <Grid item xs={10}>
                {wasteSavings > 0 || wasteSavings === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h6">{`${numberWithCommas(wasteSavings)}`}</Typography>
                  </Grid>
                ) : (
                  <Typography variant="h6">{`0.00`}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6">{wasteUnit}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{
                  color: '#916AAC',
                  letterSpacing: '1px',
                  paddingBottom: '4%',
                }}
              >
                <b>Material</b>
              </Typography>
            </Grid>{' '}
            {/* <LinearProgressComponent
              bgcolor="#916AAC"
              value={!(materialspercentage && materialspercentage) ? 0 : (materialspercentage && materialspercentage) > 100 ? 100 : materialspercentage}
            /> */}
            {/* TEMP: Due to incorrect calculation above for materials */}
            <LinearProgressComponent bgcolor="#916AAC" value={numberWithCommas(materialsSavingsPercentage)} />
            <Grid container item xs={10} style={{ marginTop: 15 }}>
              <Grid item xs={10}>
                {materialsSavings > 0 || materialsSavings === 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="h6">{`${numberWithCommas(materialsSavings)}`}</Typography>
                  </Grid>
                ) : (
                  <Typography variant="h6">{`0.00`}</Typography>
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography variant="h6">{materialsUnit}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SelectedInterv;
