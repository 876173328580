import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const NaturalStpPipParameters = (props) => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel, getQuantityDefaultValue },
    set: { updateQuantityValue },
    schema,
  } = useInterventionDetailsContext();
  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Studio</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.nstp_studio) || 0} {getQuantityUnit(schema.nstp_studio)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>1 BHK</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.nstp_oneBHK) || 0} {getQuantityUnit(schema.nstp_oneBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>2 BHK</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.nstp_twoBHK) || 0} {getQuantityUnit(schema.nstp_twoBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>3 BHK</Typography>
          <Typography className={classes.textHeader3}>
            {' '}
            {getQuantityValue(schema.nstp_threeBHK) || 0} {getQuantityUnit(schema.nstp_threeBHK)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>4 BHK</Typography>
          <Typography className={classes.textHeader3}>
            {' '}
            {getQuantityValue(schema.nstp_fourBHK) || 0} {getQuantityUnit(schema.nstp_fourBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>PH 1 BHK</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.nstp_penthouseOneBHK) || 0} {getQuantityUnit(schema.nstp_penthouseOneBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>PH 2 BHK</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.nstp_penthouseTwoBHK) || 0} {getQuantityUnit(schema.nstp_penthouseTwoBHK)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>PH 3 BHK</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.nstp_penthouseThreeBHK) || 0} {getQuantityUnit(schema.nstp_penthouseThreeBHK)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>PH 4 BHK</Typography>
          <Typography className={classes.textHeader3}>
            {getQuantityValue(schema.nstp_penthouseFourBHK) || 0} {getQuantityUnit(schema.nstp_penthouseFourBHK)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NaturalStpPipParameters;
