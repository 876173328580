import numberWithCommas from '../../../../../utils/numberToComma';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import EnergyFlowDiagram from './EnergyFlowDiagram';
import WasteFlowDiagram from './WasteFlowDiagram';
import WaterFlowDiagram from './WaterFlowDiagram';

const FlowDiagrams = ({ data }) => {
  const { getValue } = useAppMetafireUtilities();

  const name = data?.name;
  const flowDiagram = data?.quantities?.flowDiagram || null;
  const FlowDiagramMapper = flowDiagram
    ? {
        Energy: (
          <EnergyFlowDiagram
            mainGrid={`${numberWithCommas(getValue(flowDiagram?.left.mainGrid))}`}
            residentiallighting={`${numberWithCommas(getValue(flowDiagram?.right?.residentialLighting))}`}
            residentialhvac={`${numberWithCommas(getValue(flowDiagram?.right?.residentialHvac))}`}
            residentialfan={`${numberWithCommas(getValue(flowDiagram?.right?.residentialFan))}`}
            residentialhotwater={`${numberWithCommas(getValue(flowDiagram?.right?.residentialHotWater))}`}
            homeappliances={`${numberWithCommas(getValue(flowDiagram?.right?.homeAppliances))}`}
            commonarea={`${numberWithCommas(getValue(flowDiagram?.right?.commonArea))}`}
            dieselgenerator={`${numberWithCommas(getValue(flowDiagram?.left?.dieselGenerator))}`}
            solarweaterheater={`${numberWithCommas(getValue(flowDiagram?.left?.solarWaterHeater))}`}
            solarpv={`${numberWithCommas(getValue(flowDiagram?.left?.solarPv))}`}
            biogas={`${numberWithCommas(getValue(flowDiagram?.left?.biogas))}`}
            maingridtohotwater={`${numberWithCommas(getValue(flowDiagram?.connections[0]?.value))}`}
            maingridtolighting={`${numberWithCommas(getValue(flowDiagram?.connections[1]?.value))}`}
            dieslgeneratortolighting={`${numberWithCommas(getValue(flowDiagram?.connections[5]?.value))}`}
            dieselgeneratortoappliances={`${numberWithCommas(getValue(flowDiagram?.connections[7]?.value))}`}
            dieslgeneratortofan={`${numberWithCommas(getValue(flowDiagram?.connections[6]?.value))}`}
            maingridtoresidentialfan={`${numberWithCommas(getValue(flowDiagram?.connections[2]?.value))}`}
            maingridtohomeappliances={`${numberWithCommas(getValue(flowDiagram?.connections[3]?.value))}`}
            maingridtohvac={`${numberWithCommas(getValue(flowDiagram?.connections[4]?.value))}`}
            buiogastocommonarea={`${numberWithCommas(getValue(flowDiagram?.connections[10]?.value))}`}
            solarwaterheatertoresidentialhotwater={`${numberWithCommas(getValue(flowDiagram?.connections[8]?.value))}`}
            solarpvtocommonarea={`${numberWithCommas(getValue(flowDiagram?.connections[9]?.value))}`}
            maingridtocommonarea="NA"
            dieslgeneratortocommonarea="NA"
          />
        ),
        Waste: (
          <WasteFlowDiagram
            rejectwaste={numberWithCommas(getValue(flowDiagram?.left?.RejectWaste))}
            sanitarywaste={numberWithCommas(getValue(flowDiagram?.left.SanitaryWaste))}
            medicalwaste={numberWithCommas(getValue(flowDiagram?.left.MedicalWaste))}
            ewaste={numberWithCommas(getValue(flowDiagram?.left.E_Waste))}
            drywaste={numberWithCommas(getValue(flowDiagram?.left.DryWaste))}
            organicwaste={numberWithCommas(getValue(flowDiagram?.left.OrganicWaste))}
          />
        ),
        Water: (
          <WaterFlowDiagram
            TreatedGreyWater={numberWithCommas(getValue(flowDiagram?.left?.treatedGreyWater))}
            FreshWater={numberWithCommas(getValue(flowDiagram?.left?.freshWater))}
            CentralizedRo={numberWithCommas(getValue(flowDiagram?.left?.centralizedRO))}
            WTP={numberWithCommas(getValue(flowDiagram?.left?.wtp))}
            CommonAreaUse={numberWithCommas(getValue(flowDiagram?.right?.commonAreaUse))}
            DomesticWaterUse={numberWithCommas(getValue(flowDiagram?.right?.domesticWaterUse))}
            HealthFaucet={numberWithCommas(getValue(flowDiagram?.right?.healthFaucet))}
            Flushing={numberWithCommas(getValue(flowDiagram?.right?.flushing))}
            Drinking={numberWithCommas(getValue(flowDiagram?.right?.drinking))}
            GreyWaterSTP={numberWithCommas(getValue(flowDiagram?.right?.greyWaterStp))}
            BlackWaterSTP={numberWithCommas(getValue(flowDiagram?.right?.blackWaterStp))}
          />
        ),
      }
    : {};

  return flowDiagram && FlowDiagramMapper[name] ? FlowDiagramMapper[name] : 'Oops, Flow diagram not available!';
};
export default FlowDiagrams;
