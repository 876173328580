import { LegalQuantityTypes } from '../utils/types';

export const useAppMetafireUtilities = () => {
  const getRoundOffValue = (value) => {
    return isNaN(parseFloat(value)) || String(value).endsWith('.') ? value : parseFloat(parseFloat(value).toFixed(2));
  };

  const getValue = (data = {}) => {
    if (data && Object.keys(data).length !== 0) {
      switch (data?.type) {
        case LegalQuantityTypes.Number:
          // A temporary implementation of roundOffs
          return getRoundOffValue(data?.value);
        // return isNaN(parseFloat(data?.value)) ? data?.value : parseFloat(data?.value);
        case LegalQuantityTypes.UserActualDefault:
          const selectedValueForUse = data?.value?.user ?? data?.value?.default;
          // A temporary implementation of roundOffs
          return getRoundOffValue(selectedValueForUse);
        case LegalQuantityTypes.String:
          return data?.value ? String(data?.value) : data?.value;
        case LegalQuantityTypes.Boolean:
          return data?.value;
        case LegalQuantityTypes.Object:
          return data?.value;
        default:
          // return data?.value
          // A temporary implementation of roundOffs
          return getRoundOffValue(data?.value);
      }
    }
    return '-Nil-';
  };

  const getLabel = (data = {}) => {
    if (data && data?.label) {
      return data?.label;
    }
    return '-Nil-';
  };

  const getUnit = (data = {}) => {
    if (data && data?.unit) {
      return data?.unit;
    }
    return '-Nil-';
  };

  return {
    getValue,
    getLabel,
    getUnit,
    getRoundOffValue,
  };
};
