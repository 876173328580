import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const EfficientFixturesDualFlushProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue },
    schema,
  } = useInterventionDetailsContext();

  const {
    efficientFixturesDualFlush_oneBHK,
    efficientFixturesDualFlush_twoBHK,
    efficientFixturesDualFlush_threeBHK,
    efficientFixturesDualFlush_fourBHK,
    efficientFixturesDualFlush_studio,
    efficientFixturesDualFlush_rooftopArea,
  } = schema;

  const onebhk = getQuantityValue(efficientFixturesDualFlush_oneBHK);
  const twobhk = getQuantityValue(efficientFixturesDualFlush_twoBHK);
  const threebhk = getQuantityValue(efficientFixturesDualFlush_threeBHK);
  const fourbhk = getQuantityValue(efficientFixturesDualFlush_fourBHK);
  const studio = getQuantityValue(efficientFixturesDualFlush_studio);
  const rooftoparea = getQuantityValue(efficientFixturesDualFlush_rooftopArea);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Rooftop Area</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(rooftoparea)} sqm</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Studio</Typography>
          <Typography className={classes.textHeader3}>{studio} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>1 BHK</Typography>
          <Typography className={classes.textHeader3}>{onebhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>2 BHK</Typography>
          <Typography className={classes.textHeader3}>{twobhk} nos</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>3 BHK</Typography>
          <Typography className={classes.textHeader3}>{threebhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>4 BHK</Typography>
          <Typography className={classes.textHeader3}>{fourbhk} nos</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EfficientFixturesDualFlushProjectInputs;
