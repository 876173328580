import React, { useEffect } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import PageTitle from './components/PageTitle';
import ComponentTitle from './components/ComponentTitle';
import SustainabilityLevel from './components/sustanabilityLevel';
import FianancialInsights from './components/Financial-Insights';
import InterventionsAndSelectedInterventions from './components/InterventionsAndSelectedInterventions';
import LocationBasedInsights from './components/LocationBasedInsights';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(11),
  },
}));

const Insights = (props) => {
  const classes = useStyles();
  const { goals, intervention, sustainabilityLevel, insights, setNumberOfYears, numberOfYears, isFetching } = props;
  useEffect(() => {
    localStorage.setItem('previouspage', 'insights');
  }, []);
  return (
    <>
      <Container className={classes.container}>
        <PageTitle title="Insights" />
        <ComponentTitle title="Sustainability level" marginbottom={0} />
        {isFetching ? (
          <CasaSkeleton variant="rect" height="100px" width="100%" animation="wave" />
        ) : (
          <SustainabilityLevel sustainabilityLevel={sustainabilityLevel} />
        )}
        <ComponentTitle title="Financial Insights" marginbottom={0} />
        {isFetching ? (
          <CasaSkeleton variant="rect" height="100px" width="100%" animation="wave" />
        ) : (
          <FianancialInsights intervention={intervention} numberOfYears={numberOfYears} setNumberOfYears={setNumberOfYears} />
        )}
        {isFetching ? (
          <CasaSkeleton style={{ marginTop: 16 }} variant="rect" height="100px" width="100%" animation="wave" />
        ) : (
          <>
            <InterventionsAndSelectedInterventions intervention={intervention} goals={goals} />
            <LocationBasedInsights insights={insights} />
          </>
        )}
      </Container>
    </>
  );
};

export default Insights;
