import React, { useState, useEffect } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CustomizedTooltips from '../../../../../components/common/tooltip/TooltipV2';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2, 2, 2, 2),
  },
  paper: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
    paddingTop: '3%',
    paddingBottom: '3%',
    height: '30px',
    width: '100%',
    borderRadius: '0px',
  },
  text: {
    paddingTop: '35%',
    color: '#2E2E2E',

    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '10px',
    lineHeight: '120%',
    letterSpacing: '0.714286px',
    textAlign: 'center',
  },
  text2: {
    textAlign: 'center',
    color: '#FFFFFF',

    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '12px',
    lineHeight: '120%',
    letterSpacing: '0.714286px',
  },
  specialtext: {
    paddingTop: '33%',
    color: '#2E2E2E',

    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '10px',
    lineHeight: '120%',
    letterSpacing: '0.714286px',
    textAlign: 'center',
  },
  specialpaper: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
    paddingTop: '3%',
    paddingBottom: '3%',
    height: '33px',
    width: '100%',
    marginTop: '-4%',
    borderRadius: '2px',
  },
  text3: {
    color: '#666666',

    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '10px',
    lineHeight: '120%',
    letterSpacing: '0.714286px',
    textAlign: 'center',
  },
  text4: {
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: '18px',
    lineHeight: '120%',
    letterSpacing: '0.714286px',
    textAlign: 'center',
  },
  text5: {
    fontStyle: 'normal',
    fontWeight: 900,
    // fontSize: '13px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    textAlign: 'center',
    color: '#EE8D8E',
  },
  text6: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.714286px',
    textAlign: 'center',
    color: '#666666',
  },
}));

const AQI = (props) => {
  const aqi = props.aqi;
  const classes = useStyles();
  let input = aqi && aqi.value.aqi;
  const [number, setNumber] = useState('');
  useEffect(() => {
    if (input > 0 && input <= 50) {
      setNumber(1);
    } else if (input > 50 && input <= 100) {
      setNumber(2);
    } else if (input > 100 && input <= 150) {
      setNumber(3);
    } else if (input > 150 && input <= 200) {
      setNumber(4);
    } else if (input > 200 && input <= 300) {
      setNumber(5);
    } else if (input > 300 && input <= 500) {
      setNumber(6);
    }
  }, [input]);

  return (
    <>
      <Grid container item xs={12} className={classes.main}>
        <Grid item xs={8} sm={8} md={7} lg={7}>
          <Grid container item xs={12} style={{ marginBottom: '-4%' }}>
            <Grid item xs={number === 1 ? 3 : 2}>
              {number === 1 ? (
                <>
                  <Grid container>
                    <Typography variant="h6" style={{ marginLeft: '-7px' }}>
                      <b>Good</b>
                    </Typography>
                    {/* <CustomizedTooltips marginTop="0px" icon="info" title={<Typography> NA</Typography>} placement="right" /> */}
                  </Grid>
                  <Typography variant="h6" style={{ textAlign: 'center', color: '#666666' }}>
                    <ArrowDropDownIcon style={{ marginLeft: '-21px' }} />
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={number === 2 ? 3 : 2}>
              {number === 2 ? (
                <>
                  <Grid container>
                    <Typography variant="h6" style={{ marginLeft: '-7px' }}>
                      <b>Moderate</b>
                    </Typography>
                    {/* <CustomizedTooltips marginTop="0px" icon="info" title={<Typography> NA</Typography>} placement="right" /> */}
                  </Grid>
                  <Typography variant="h6" style={{ textAlign: 'center', color: '#666666' }}>
                    <ArrowDropDownIcon style={{ marginLeft: '-25px' }} />
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={number === 3 ? 6 : 2}>
              {number === 3 ? (
                <>
                  <Grid container>
                    <Typography variant="h6" style={{ marginLeft: '-7px' }}>
                      <b> Unhealthy for Sensitive Groups </b>
                    </Typography>
                    {/* <CustomizedTooltips marginTop="0px" icon="info" title={<Typography> NA</Typography>} placement="right" /> */}
                  </Grid>

                  <Typography variant="h6" style={{ textAlign: 'center', color: '#666666' }}>
                    <ArrowDropDownIcon style={{ marginLeft: '-100px' }} />
                  </Typography>
                </>
              ) : (
                ' '
              )}
            </Grid>
            <Grid item xs={number === 4 ? 3 : 2}>
              {number === 4 ? (
                <>
                  <Grid container>
                    <Typography variant="h6" style={{ marginLeft: '-7px' }}>
                      <b>Unhealthy </b>
                    </Typography>
                    {/* <CustomizedTooltips marginTop="0px" icon="info" title={<Typography> NA</Typography>} placement="right" /> */}
                  </Grid>
                  <Typography variant="h6" style={{ textAlign: 'center', color: '#666666' }}>
                    <ArrowDropDownIcon style={{ marginLeft: '-25px' }} />
                  </Typography>
                </>
              ) : (
                ' '
              )}
            </Grid>
            <Grid item xs={number === 5 ? 4 : 2}>
              {number === 5 ? (
                <>
                  <Grid container>
                    <Typography variant="h6" style={{ marginLeft: '-7px' }}>
                      <b> Very Unhealthy </b>
                    </Typography>
                    {/* <CustomizedTooltips marginTop="0px" icon="info" title={<Typography> NA</Typography>} placement="right" /> */}
                  </Grid>
                  <Typography variant="h6" style={{ textAlign: 'center', color: '#666666' }}>
                    <ArrowDropDownIcon style={{ marginLeft: '-50px' }} />
                  </Typography>
                </>
              ) : (
                ' '
              )}
            </Grid>
            <Grid item xs={2}>
              {number === 6 ? (
                <>
                  <Grid container>
                    <Typography variant="h6" style={{ marginLeft: '-7px' }}>
                      <b>Hazardous </b>
                    </Typography>
                    {/* <CustomizedTooltips marginTop="0px" icon="info" title={<Typography> NA</Typography>} placement="right" /> */}
                  </Grid>
                  <Typography variant="h6" style={{ textAlign: 'center', color: '#666666' }}>
                    <ArrowDropDownIcon />
                  </Typography>
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingTop: '4%' }}>
            <Grid item xs={2}>
              <Paper
                className={number === 1 ? classes.specialpaper : classes.paper}
                style={{ background: '#40EB40', borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px' }}
              >
                {number === 1 ? (
                  <Typography className={classes.text2} style={{ paddingTop: '8px' }}>
                    {input}
                  </Typography>
                ) : (
                  ''
                )}
              </Paper>
              <Typography variant="h6" className={number === 1 ? classes.specialtext : classes.text}>
                0-50
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Paper className={number === 2 ? classes.specialpaper : classes.paper} style={{ background: '#FFFF40' }}>
                {number === 2 ? (
                  <Typography className={classes.text2} style={{ paddingTop: '8px', color: '#666666' }}>
                    {input}
                  </Typography>
                ) : (
                  ''
                )}
              </Paper>
              <Typography variant="h6" className={number === 2 ? classes.specialtext : classes.text}>
                51-100
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Paper className={number === 3 ? classes.specialpaper : classes.paper} style={{ background: '#FF9E40' }}>
                {number === 3 ? (
                  <Typography className={classes.text2} style={{ paddingTop: '8px' }}>
                    {input}
                  </Typography>
                ) : (
                  ''
                )}
              </Paper>
              <Typography variant="h6" className={number === 3 ? classes.specialtext : classes.text}>
                101-150
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Paper className={number === 4 ? classes.specialpaper : classes.paper} style={{ background: '#FF4040' }}>
                {number === 4 ? (
                  <Typography className={classes.text2} style={{ paddingTop: '8px' }}>
                    {input}
                  </Typography>
                ) : (
                  ''
                )}
              </Paper>
              <Typography variant="h6" className={number === 4 ? classes.specialtext : classes.text}>
                151-200
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Paper className={number === 5 ? classes.specialpaper : classes.paper} style={{ background: '#AB6FB2' }}>
                {number === 5 ? (
                  <Typography className={classes.text2} style={{ paddingTop: '8px' }}>
                    {input}
                  </Typography>
                ) : (
                  ''
                )}
              </Paper>
              <Typography variant="h6" className={number === 5 ? classes.specialtext : classes.text}>
                201-300
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Paper
                className={number === 6 ? classes.specialpaper : classes.paper}
                style={{ background: '#9E405A', borderTopRightRadius: '15px', borderBottomRightRadius: '15px' }}
              >
                {number === 6 ? (
                  <Typography className={classes.text2} style={{ paddingTop: '8px' }}>
                    {input}
                  </Typography>
                ) : (
                  ''
                )}
              </Paper>
              <Typography variant="h6" className={number === 6 ? classes.specialtext : classes.text}>
                301-500
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={2} lg={2} style={{ textAlign: 'center', paddingTop: `${number === 3 || 5 ? '5%' : '4%'}` }}>
          <Typography className={classes.text4} style={{ color: '#40EB40' }}>
            49
          </Typography>
          <Typography className={classes.text3} style={{ paddingTop: '3%' }}>
            Avg AQI in
          </Typography>
          <Typography className={classes.text3}>Bangalore</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} style={{ textAlign: 'center', paddingTop: `${number === 3 || 5 ? '2%' : '1%'}`, marginLeft: '-1%' }}>
          <Grid container item xs alignItems="center" justifyContent="center" style={{ paddingBottom: '7%' }}>
            <Typography variant="h6">
              <b>Highest Parameter</b>
            </Typography>
            {/* <CustomizedTooltips marginTop="0px" icon="info" title={<Typography> NA</Typography>} placement="right" /> */}
          </Grid>
          <Typography className={classes.text5}>{aqi && aqi.value.biggestPollutant}</Typography>
          <Typography className={classes.text3} style={{ paddingTop: '3%' }}>
            Closest station
          </Typography>
          <Typography className={classes.text3} style={{ color: '#2E2E2E', paddingTop: '3%' }}>
            {aqi && aqi.value.stationName.split('-')[0]}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AQI;
