import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import numberWithCommas from '../../../../../utils/numberToComma';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const EfficientFixturesFaucetProjectInputs = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue },
    schema,
  } = useInterventionDetailsContext();

  const {
    efficientFixturesFaucet_oneBHK,
    efficientFixturesFaucet_twoBHK,
    efficientFixturesFaucet_threeBHK,
    efficientFixturesFaucet_fourBHK,
    efficientFixturesFaucet_studio,
    efficientFixturesFaucet_rooftopArea,
  } = schema;

  const onebhk = getQuantityValue(efficientFixturesFaucet_oneBHK);
  const twobhk = getQuantityValue(efficientFixturesFaucet_twoBHK);
  const threebhk = getQuantityValue(efficientFixturesFaucet_threeBHK);
  const fourbhk = getQuantityValue(efficientFixturesFaucet_fourBHK);
  const studio = getQuantityValue(efficientFixturesFaucet_studio);
  const rooftoparea = getQuantityValue(efficientFixturesFaucet_rooftopArea);

  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Rooftop Area</Typography>
          <Typography className={classes.textHeader3}>{numberWithCommas(rooftoparea)} sqm</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Studio</Typography>
          <Typography className={classes.textHeader3}>{studio} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>1 BHK</Typography>
          <Typography className={classes.textHeader3}>{onebhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>2 BHK</Typography>
          <Typography className={classes.textHeader3}>{twobhk} nos</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>3 BHK</Typography>
          <Typography className={classes.textHeader3}>{threebhk} nos</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>4 BHK</Typography>
          <Typography className={classes.textHeader3}>{fourbhk} nos</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EfficientFixturesFaucetProjectInputs;
