import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import useStyles from './styles';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetails';
import { getDefaultNormalizer } from '@testing-library/react';

const HWMVillaCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const demandbybathvilla = getQuantityValue(schema.hwmVilla__pi_demandByBathType);
  const demandassumptiontypevilla = getQuantityValue(schema.hwmVilla__pi_demandAssumptionType);

  const [disableDemandByBath, setDisableDemandByBath] = useState(false);

  const handleDemandByBath = (demandbath) => {
    updateQuantityValue(schema.hwmVilla__pi_demandByBathType, demandbath);
  };

  const handleDemandByAssumption = (assumption) => {
    updateQuantityValue(schema.hwmVilla__pi_demandAssumptionType, assumption);
    if (assumption === 'lpd per villa') {
      updateQuantityValue(schema.hwmVilla__pi_demandByBathType, null);
      setDisableDemandByBath(true);
    } else {
      setDisableDemandByBath(false);
    }
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Typography className={classes.textHeader1}>Demand by Bath</Typography>
      <Typography className={disableDemandByBath ? classes.textHeader3disabled : classes.textHeader3}>
        {demandbybathvilla === 'all baths' && !disableDemandByBath && (
          <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('all baths')} />
        )}
        {demandbybathvilla !== 'all baths' && !disableDemandByBath && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('all baths')} />
        )}
        {demandbybathvilla !== 'all baths' && disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
        All baths
      </Typography>
      <Typography className={disableDemandByBath ? classes.textHeader3disabled : classes.textHeader3}>
        {demandbybathvilla === 'single bath' && !disableDemandByBath && (
          <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('single bath')} />
        )}
        {demandbybathvilla !== 'single bath' && !disableDemandByBath && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('single bath')} />
        )}
        {demandbybathvilla !== 'single bath' && disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
        Master bathroom
      </Typography>
      <Typography className={disableDemandByBath ? classes.textHeader3disabled : classes.textHeader3}>
        {demandbybathvilla === 'remaining baths' && !disableDemandByBath && (
          <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByBath('remaining baths')} />
        )}
        {demandbybathvilla !== 'remaining baths' && !disableDemandByBath && (
          <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByBath('remaining baths')} />
        )}
        {demandbybathvilla !== 'remaining baths' && disableDemandByBath && <RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
        All baths except master bathroom
      </Typography>
      <Grid container item xs={12} direction="column" style={{ paddingTop: '3%' }}>
        <Typography className={classes.textHeader1}>Demand Assumption</Typography>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandassumptiontypevilla === 'lpcd per occupant' && (
                <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleDemandByAssumption(null)} />
              )}
              {demandassumptiontypevilla !== 'lpcd per occupant' && (
                <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleDemandByAssumption('lpcd per occupant')} />
              )}
              {getQuantityLabel(schema.hwmVilla__pi_userLpcdPerOccupant)}
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                className={demandassumptiontypevilla !== 'lpd per occupant' ? classes.textDisable : undefined}
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmVilla__pi_userLpcdPerOccupant)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userLpcdPerOccupant, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userLpcdPerOccupant, getQuantityDefaultValue(schema.hwmVilla__pi_userLpcdPerOccupant));
                }}
                disabled={demandassumptiontypevilla !== 'lpcd per occupant' ? true : false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6" className={demandassumptiontypevilla !== 'lpcd per occupant' ? classes.textDisable : undefined}>
                {getQuantityUnit(schema.hwmVilla__pi_userLpcdPerOccupant)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} container alignItems="flex-start" direction="column" style={{}}>
          <Grid xs>
            <Typography className={classes.textHeader3}>
              {demandassumptiontypevilla === 'lpd per villa' && (
                <RadioButtonCheckedIcon
                  className={classes.selectedRadiobox}
                  onClick={() => {
                    handleDemandByAssumption(null);
                  }}
                />
              )}
              {demandassumptiontypevilla !== 'lpd per villa' && (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedRadiobox}
                  onClick={() => {
                    handleDemandByAssumption('lpd per villa');
                  }}
                />
              )}
              {getQuantityLabel(schema.hwmVilla__pi_userLpdPerVilla)}
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmVilla__pi_userLpdPerVilla)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userLpdPerVilla, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userLpdPerVilla, getQuantityDefaultValue(schema.hwmVilla__pi_userLpdPerVilla));
                }}
                disabled={demandassumptiontypevilla !== 'lpd per villa' ? true : false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6" className={demandassumptiontypevilla !== 'lpd per villa' ? classes.textDisable : undefined}>
                {getQuantityUnit(schema.hwmVilla__pi_userLpdPerVilla)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={classes.textHeader3}>
          {demandassumptiontypevilla === 'link to installed efficient fixtures' && (
            <RadioButtonCheckedIcon
              className={classes.selectedRadiobox}
              onClick={() => {
                handleDemandByAssumption('link to installed efficient fixtures');
              }}
            />
          )}
          {demandassumptiontypevilla !== 'link to installed efficient fixtures' && (
            <RadioButtonUncheckedIcon
              className={classes.nonselectedRadiobox}
              onClick={() => {
                handleDemandByAssumption('link to installed efficient fixtures');
              }}
            />
          )}
          As per selected Efficient Fixtures
        </Typography>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>CAPEX(per unit installed)</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.hwmVilla__pi_capexSourceType) === 'vendor' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.hwmVilla__pi_capexSourceType, 'vendor');
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textHeader3}>{getQuantityValue(schema.hwmVilla_hwmVillaVendorCapex)} RS</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.hwmVilla__pi_capexSourceType) === 'custom' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.hwmVilla__pi_capexSourceTyp, 'custom');
                  }}
                />
              )}
              Custom
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmVilla__pi_customCapex)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_customCapex, e.target.value);
                }}
                fullWidth
                required
                disabled={!getQuantityValue(schema.hwmVilla__pi_capexSourceType) === 'custom' ? true : false}
                removeResetIcon={true}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6" className={!getQuantityValue(schema.hwmVilla__pi_capexSourceType) === 'custom' ? classes.textDisable : undefined}>
                RS
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Project Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes may affect other project or intervention values</Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>{getQuantityLabel(schema.hwmVilla__pi_userCityResidentialUtilityTariff)}</Typography>
      <Grid item xs={4} direction="column" style={{ paddingTop: '10px' }}>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.hwmVilla__pi_userCityResidentialUtilityTariff)}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.hwmVilla__pi_userCityResidentialUtilityTariff, e.target.value);
              }}
              fullWidth
              required
              onClick={(e) => {
                e.preventDefault();
                updateQuantityValue(
                  schema.hwmVilla__pi_userCityResidentialUtilityTariff,
                  getQuantityDefaultValue(schema.hwmVilla__pi_userCityResidentialUtilityTariff)
                );
              }}
              disabled={false}
            />
          </Grid>
          <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
            <Typography variant="h6">{getQuantityUnit(schema.hwmVilla__pi_userCityResidentialUtilityTariff)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Constants
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmVilla__pi_userRooftopAreaPerSwh)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmVilla__pi_userRooftopAreaPerSwh)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userRooftopAreaPerSwh, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userRooftopAreaPerSwh, getQuantityDefaultValue(schema.hwmVilla__pi_userRooftopAreaPerSwh));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmVilla__pi_userRooftopAreaPerSwh)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmVilla__pi_userRooftopAreaPerHp)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmVilla__pi_userRooftopAreaPerHp)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userRooftopAreaPerHp, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userRooftopAreaPerHp, getQuantityDefaultValue(schema.hwmVilla__pi_userRooftopAreaPerHp));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmVilla__pi_userRooftopAreaPerHp)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmVilla__pi_userHotWaterHours)}</Typography>
            <CustomizedTooltips
              icon="info"
              title={<Typography>Total hot water peak hours- lower the number, higher the HP capacity. </Typography>}
              placement="right"
            />
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmVilla__pi_userHotWaterHours)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userHotWaterHours, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userHotWaterHours, getDefaultNormalizer(schema.hwmVilla__pi_userHotWaterHours));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmVilla__pi_userHotWaterHours)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '10px' }}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px', display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmVilla__pi_userNumberOfShafts)}</Typography>
            <CustomizedTooltips icon="info" title={<Typography>Used for calculating heat loss from standby water</Typography>} placement="right" />
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.hwmVilla__pi_userNumberOfShafts)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userNumberOfShafts, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.hwmVilla__pi_userNumberOfShafts, getQuantityDefaultValue(schema.hwmVilla__pi_userNumberOfShafts));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.hwmVilla__pi_userNumberOfShafts)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.hwmVilla_cityEmissionFactor)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="column">
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.hwmVilla_cityEmissionFactor)} {getQuantityUnit(schema.hwmVilla_cityEmissionFactor)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HWMVillaCustomAdvancedParameters;
