import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from '../../../../../common/textfield/Fields';

function ManualPIP({ displayName, myArray, projectPIP, _id, textbox, appendChanged }) {
  return (
    <>
      <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '8%', marginTop: '3%' }}>
        <Typography variant="h6">{displayName}</Typography>
      </Grid>
      <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '8%', marginTop: '4%' }}>
        <Fields
          placeholder={`Enter ${displayName}`}
          required
          value={
            myArray && myArray.find((d) => d.PIPScheme === _id)
              ? myArray && myArray.find((d) => d.PIPScheme === _id).value
              : projectPIP && projectPIP.find((d) => d.PIPScheme.name === textbox) && projectPIP.find((d) => d.PIPScheme.name === textbox).value
          }
          onChange={(e) => {
            e.preventDefault();
            appendChanged(_id, e.target.value);
          }}
          fullWidth
        />
      </Grid>
    </>
  );
}

export default ManualPIP;
