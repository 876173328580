import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetails';

const HWMSwimmingPoolProjectInputs = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue },
  } = useInterventionDetailsContext();

  const swimmingPoolsurfaceArea = getQuantityValue(schema.hwmSwimmingPool_swimmingPoolSurfaceArea);
  return (
    <Grid container item xs={12} direction="column">
      <Typography className={classes.textHeader1}>Project Inputs</Typography>
      <Grid container item xs={12} style={{ paddingTop: '1%' }}>
        <Grid item xs={3}>
          <Typography className={classes.textHeader5}>Swimming Pool Surface Area</Typography>
          <Typography className={classes.textHeader3}>{swimmingPoolsurfaceArea} Sqm</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HWMSwimmingPoolProjectInputs;
